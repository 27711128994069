/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

@define-mixin icon {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
    vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon::before {
	@mixin icon;
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}


@define-mixin icon-all { content: "\EA01"; }
.icon-all::before { content: "\EA01"; }

@define-mixin icon-arrow-simple { content: "\EA02"; }
.icon-arrow-simple::before { content: "\EA02"; }

@define-mixin icon-arrow { content: "\EA03"; }
.icon-arrow::before { content: "\EA03"; }

@define-mixin icon-art { content: "\EA04"; }
.icon-art::before { content: "\EA04"; }

@define-mixin icon-asc { content: "\EA05"; }
.icon-asc::before { content: "\EA05"; }

@define-mixin icon-calender { content: "\EA06"; }
.icon-calender::before { content: "\EA06"; }

@define-mixin icon-checkbox-checked-fill { content: "\EA07"; }
.icon-checkbox-checked-fill::before { content: "\EA07"; }

@define-mixin icon-checkbox-checked { content: "\EA08"; }
.icon-checkbox-checked::before { content: "\EA08"; }

@define-mixin icon-checkbox-round-checked-02-fill { content: "\EA09"; }
.icon-checkbox-round-checked-02-fill::before { content: "\EA09"; }

@define-mixin icon-checkbox-round-checked-02 { content: "\EA0A"; }
.icon-checkbox-round-checked-02::before { content: "\EA0A"; }

@define-mixin icon-checkbox-round-checked-fill { content: "\EA0B"; }
.icon-checkbox-round-checked-fill::before { content: "\EA0B"; }

@define-mixin icon-checkbox-round-checked { content: "\EA0C"; }
.icon-checkbox-round-checked::before { content: "\EA0C"; }

@define-mixin icon-checkbox-round { content: "\EA0D"; }
.icon-checkbox-round::before { content: "\EA0D"; }

@define-mixin icon-checkbox { content: "\EA0E"; }
.icon-checkbox::before { content: "\EA0E"; }

@define-mixin icon-close { content: "\EA0F"; }
.icon-close::before { content: "\EA0F"; }

@define-mixin icon-desc { content: "\EA10"; }
.icon-desc::before { content: "\EA10"; }

@define-mixin icon-email { content: "\EA11"; }
.icon-email::before { content: "\EA11"; }

@define-mixin icon-event { content: "\EA12"; }
.icon-event::before { content: "\EA12"; }

@define-mixin icon-lecture-course { content: "\EA13"; }
.icon-lecture-course::before { content: "\EA13"; }

@define-mixin icon-list { content: "\EA14"; }
.icon-list::before { content: "\EA14"; }

@define-mixin icon-map { content: "\EA15"; }
.icon-map::before { content: "\EA15"; }

@define-mixin icon-media { content: "\EA16"; }
.icon-media::before { content: "\EA16"; }

@define-mixin icon-more { content: "\EA17"; }
.icon-more::before { content: "\EA17"; }

@define-mixin icon-movie { content: "\EA18"; }
.icon-movie::before { content: "\EA18"; }

@define-mixin icon-musics { content: "\EA19"; }
.icon-musics::before { content: "\EA19"; }

@define-mixin icon-outlink-02 { content: "\EA1A"; }
.icon-outlink-02::before { content: "\EA1A"; }

@define-mixin icon-outlink { content: "\EA1B"; }
.icon-outlink::before { content: "\EA1B"; }

@define-mixin icon-radio-checked-fill { content: "\EA1C"; }
.icon-radio-checked-fill::before { content: "\EA1C"; }

@define-mixin icon-radio-checked { content: "\EA1D"; }
.icon-radio-checked::before { content: "\EA1D"; }

@define-mixin icon-radio { content: "\EA1E"; }
.icon-radio::before { content: "\EA1E"; }

@define-mixin icon-search { content: "\EA1F"; }
.icon-search::before { content: "\EA1F"; }

@define-mixin icon-sport { content: "\EA20"; }
.icon-sport::before { content: "\EA20"; }

@define-mixin icon-tea-flower { content: "\EA21"; }
.icon-tea-flower::before { content: "\EA21"; }

@define-mixin icon-tel { content: "\EA22"; }
.icon-tel::before { content: "\EA22"; }

@define-mixin icon-theater { content: "\EA23"; }
.icon-theater::before { content: "\EA23"; }

@define-mixin icon-time { content: "\EA24"; }
.icon-time::before { content: "\EA24"; }

@define-mixin icon-top { content: "\EA25"; }
.icon-top::before { content: "\EA25"; }

@define-mixin icon-traditional-culture { content: "\EA26"; }
.icon-traditional-culture::before { content: "\EA26"; }

@define-mixin logo-sp { content: "\EA27"; }
.logo-sp::before { content: "\EA27"; }
