/* マップ */
/*----------------------------------------------------------------------------*/
.map-view-area {
    margin-bottom: 30px;

    .bg-white-02 {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        box-sizing: border-box;

        @media only screen and (min-width: 660px) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .map {
        width:100%;
        height: 600px;
    }
}

/* アーティスト一覧、会場一覧リスト以外の記述 */
/*----------------------------------------------------------------------------*/
.artist-area-content,
.place-area-content {
    div {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

/* 404ページ*/
/*----------------------------------------------------------------------------*/
.error404  {
    .main-content-area {
        margin-right: 0;
    }
}