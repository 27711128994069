/*
アイコンのsvgファイルを以下のディレクトリに保存するとアイコンフォントとして使用可能。
1. /src/font/svg/ に.svgファイルを保存
2. gulpを実行
3. 保存したファイル名が「icon-arrow.svg」の場合、
　 「<i class="icon icon-arrow"></i>」でアイコンが表示される

上記、処理が「03-mixin-pre-consolidate.css」内に記述。
*/

/*
.icon-arrow::before {
    @mixin icon;
}
*/

/* パンくず homeアイコン */
/*----------------------------------------------------------------------------*/
.icon_home::before {
    content: url(../img/common/icon_home.svg);
    display: inline-block;
    width: 1em;
    height: auto;
    padding-right: 3px;
}
.icon_home:hover {
    opacity: 0.7;
  }

/* カテゴリーアイコン */
/*----------------------------------------------------------------------------*/
.icon-all::before {
    @mixin icon;
    @mixin icon-all;
    font-size: 34px;
}
.icon-art::before {
    @mixin icon;
    @mixin icon-art;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-event::before {
    @mixin icon;
    @mixin icon-event;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-lecture-course::before {
    @mixin icon;
    @mixin icon-lecture-course;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-media::before {
    @mixin icon;
    @mixin icon-media;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-movie::before {
    @mixin icon;
    @mixin icon-movie;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-musics::before {
    @mixin icon;
    @mixin icon-musics;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-sport::before {
    @mixin icon;
    @mixin icon-sport;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-tea-flower::before {
    @mixin icon;
    @mixin icon-tea-flower;
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-theater::before {
    @mixin icon
    @mixin icon-theater;
    font-size: 34px;
    display: flex;
    justify-content: center;
}

.icon-traditional-culture::before {
    @mixin icon;
    @mixin icon-traditional-culture;
    font-size: 34px;
    display: flex;
    justify-content: center;
}

/* 表示切り替えアイコン */
/*----------------------------------------------------------------------------*/
.icon-list::before {
    @mixin icon;
    @mixin icon-list;
    margin-right: 7px;
}
.icon-map::before {
    @mixin icon;
    @mixin icon-map;
    margin-right: 7px;
}

/* もっとみるアイコン */
/*----------------------------------------------------------------------------*/

.icon-more::after {
    @mixin icon;
    @mixin icon-more;
    font-size: 34px;
}

/*検索ボックス虫めがねアイコン */
/*----------------------------------------------------------------------------*/
.icon-search::before {
    @mixin icon;
    @mixin icon-search;
    font-size: 18px;
}

/* 以下、フォント以外のアイコン */
/*----------------------------------------------------------------------------*/
.icon-arrow-animation {
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    display: block;
    position: relative;

	span {
	    margin: auto;
	    width: 24px;
	    height: 2px;
	    background: #000000;
	    display: block;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    transition: all 0.15s ease-in-out 0s;
	}
}
.icon-arrow-animation {
	span:nth-child(1),
	span:nth-child(2) {
	    margin-right: 3px;
	    width: 12px;
	    transform-origin: 100% 50%;
	}
	span:nth-child(1) {
	    transform: rotate(45deg);
	}
	span:nth-child(2) {
	    transform: rotate(-45deg);
	}
}
.icon-arrow-animation:hover {
	span:nth-child(1),
	span:nth-child(2) {
	    margin-right: -7px;
	    width: 12px;
	    transform-origin: 100% 50%;
	}
	span:nth-child(3) {
	    box-shadow: 10px 0 0 0 #111111;
	}
}

html .loading {
	position: relative;
	color: transparent !important;
    pointer-events: none;

    & *,
    & *::before,
    & *::after {
        color: transparent !important;
        pointer-events: none;
    }
    & > * {
        opacity: 0.5 !important;
    }

    &.field {
        input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
        textarea,
        select {
            background: $color-gray !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    &.field-radio,
    &.field-checkbox {
        label {
            .icon {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
                &::before,
                &::after {
                    display: none;
                }
            }
        }
    }
    &.field-range {
        input[type="range"] {
            background: $color-gray !important;
            border: none !important;
            box-shadow: none !important;

            //横線
            /*
            &::-webkit-slider-runnable-track {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            */
            &::-moz-range-track {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            &::-ms-track {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }

            // つまみ
            &::-webkit-slider-thumb {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            &::-moz-range-thumb {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            &::-ms-thumb {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
        }
    }

	&::before,
	&::after {
		content: "";
		margin: auto;
		width: 1rem;
		height: 1rem;
		border-radius: 0.5rem;
		display: block;
		// background: #f00;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		box-sizing: border-box;
	}

	&::before {
		border: 2px solid rgba(0, 0, 0, 0.5);
		z-index: 900;
	}
	&::after {
		border: 2px solid rgba(255, 255, 255, 1.0);
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
		z-index: 1000;

		animation: icon-load-animation 1.0s linear 0s infinite;
	}

    ::-webkit-input-placeholder {
        color:$color-placeholder;
    }
    ::-moz-placeholder {
        color:$color-placeholder;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color:$color-placeholder;
    }
    :input-placeholder {
        color:$color-placeholder;
    }
}

@keyframes icon-load-animation {
    0% { transform: rotate(0deg); }
    40% { transform: rotate(360deg); }
    100% { transform: rotate(720deg); }
}
