// 背景色
body {
    background-color: #fbf8a0;
    background-image: url(../img/common/bg-sp-top.png),
                      url(../img/common/bg-pattern.png);
    background-position: left top,
                         top;
    background-repeat: no-repeat,
                       repeat;
    background-size: 50% auto,
                     300px;

    @media only screen and (min-width: 770px) {

        background-image: url(../img/common/bg-top.png),
                          url(../img/common/bg-pattern.png);
        background-position: top;
        background-repeat: no-repeat,
                           repeat;
        background-size: 100% auto,
                         300px;
    }
}
.main-content-area {
    background-image: url(../img/common/bg-sp-bottom.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;

    @media only screen and (min-width: 1000px) {
        padding-bottom: 100px;
        background-image: url(../img/common/bg-bottom.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size:100% auto;
    }
}
// 白背景パターン
.bg-white {
    background-color: $color-base;
}

// 白背景パターン1
.bg-white-01 {
    background-color: $color-base;
    padding: 30px 20px 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width: 1000px) {
        padding: 50px 40px 40px;
    }
}

// 白背景パターン2
.bg-white-02 {
    background-color: $color-base;
    padding: 30px 20px 20px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width: 770px) {
        padding-top: 40px;
    }
    @media only screen and (min-width: 1000px) {
        padding: 40px;
    }
}

// 白背景パターン3 (カード型のときに使用する背景)
.bg-white-03 {
    background-color: $color-base;
    padding: 30px 10px 10px;
    position: relative;

    @media only screen and (min-width: 770px) {
        padding-top: 40px;
    }
    @media only screen and (min-width: 1000px) {
        padding: 40px;
    }

}

.bg-white-yellow {
    background-color: #faf6e9;
}
.bg-white-yellow-01 {
    background-color: #faf6e9;
    padding: 40px !important;
}


.bg-gray {
    background-color: #f4f4f4;
}

.bg-black {
    background-color: $color-bg-black;
    color: #fff;

    .module-group > a,
    .border-group > a,
    .card-group > a {
        color: #fff;
    }
    a.module,
    a.border,
    a.card {
        color: #fff;
    }

    .border-group > *,
    .border {
        border-color: #fff;
    }
}
