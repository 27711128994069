/* フォームUIを囲う要素 */
/*----------------------------------------------------------------------------*/
// タイトルとフォームUIを囲う要素
fieldset,
.fieldset {
    margin: 2em 0;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    .title {
        margin-bottom: 0.5em;
        // font-weight: bold;
        font-size: 0.8em;
    }

    & > .field {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {
        margin: 3em 0;

        &:not(.fieldset-block) {
            display: flex;
            align-items: baseline;

            & > .title {
                margin-right: 1em;
                margin-bottom: 0;
                width: 200px;
            }
            & > .field,
            & > .field-group {
                flex: 1 1 0%;
            }
        }
    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}

// フォームUIを囲う要素の親要素
.field-group {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    .field {
        // display: block;
        flex-wrap: wrap;

        label.title {
            margin-right: 0;
            margin-bottom: 0.25em;
            width: 100%;
            font-size: 0.85em;

            display: block;
        }
        // & > * {
        //     margin-left: 0;
        //     margin-right: 0;
        // }
        & > *:not(:first-child) {
            margin-left: 0;
        }
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

// フォームUIを囲う要素
.field {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;

    &.field-xs {
        width: 162px;
    }

    & > * {
        flex: 1 1 0%;
    }
    & > label,
    & > .unit,
    & > .btn  {
        flex: initial;
    }

    & > * {
        // margin-left: 0.5em;
        // margin-right: 0.5em;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    & > .unit {
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    // .field内の先頭に[type="hidden"]の次の要素は余白をキャンセル
    // .field内で[type="hidden"]の次の要素は余白をキャンセル
    & > [type="hidden"]:first-child + *,
    & > [type="hidden"] + * {
        margin-left: 0;
    }

    .field {
        margin-top: 0;
        margin-bottom: 0;

        /* 病歴モーダル内のradio、checkboxで問題が出るためコメントアウト */
        /*
        & > *:not(:last-child) {
            margin-right: 0.25em;
        }
        */
    }

    /*
    select,
    textarea {
        margin: 0;
    }
    */
}

#periodField {
    margin-top: 0;
    margin-bottom: 0;
}


/* 共通 */
/*----------------------------------------------------------------------------*/
input,
select,
option,
textarea {
    font-size:16px;

    // firefox対策、.field内のUIの最小値を修正
    min-width: 1em;
}
label {
    white-space: nowrap;

    /*
    &,
    & > * {
        vertical-align: middle;
        line-height: 1;
    }
    */
}


/* テキスト、セレクト */
/*----------------------------------------------------------------------------*/
// 自動入力時の見た目をリセット
:-webkit-autofill {
    /*
    box-shadow: 0 0 0 1000px #eee inset !important;
    // background-color: transparent !important;
    // background-color: rgba(255, 255, 0, 1.0) !important;
    */
    box-shadow: inherit !important;
    background-color: inherit !important;

    &:active,
    &:focus {
        /*
        box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
        */
        box-shadow: inherit !important;
        background-color: inherit !important;
    }
}

// placeholder
::-webkit-input-placeholder {
    color: $color-placeholder;
}
::-moz-placeholder {
    color: $color-placeholder;
    opacity: 1;
}
:-ms-input-placeholder {
    color: $color-placeholder;
}
:input-placeholder {
    color: $color-placeholder;
}
:focus::-webkit-input-placeholder {
    color: $color-placeholder;
}
:focus::-moz-placeholder {
    color: $color-placeholder;
    opacity: 1;
}
:focus:-ms-input-placeholder {
    color: $color-placeholder;
}
:focus:input-placeholder {
    color: $color-placeholder;
}


// テキスト系のinput、textarea、select 共通
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
    // サイズ
    padding: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;

    // 線と背景
    //border-radius: 0;
    border: none;
    background-color: $color-base;
    &:hover {

    }
    &:active,
    &:focus {
        outline: none;
        // -webkit-
        // box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05) inset;
        //border-color: ;
        //box-shadow: $shadow-size-focus $color-link;
        background-color: #fff;
    }
    &:disabled,
    &[readonly] {
        color: rgba(0, 0, 0, 0.5) !important;
        background-color: #ddd !important;
        border-color: $color-border !important;
        box-shadow: none !important;
        cursor: default !important;
    }
}

// テキスト系のinput

input[type="number"] {
    width: 6em;
    min-width: 6em; // IE対策
    flex: 0 0 6em;
}
input[type="file"] {
    // padding: calc(0.75em - 1.5px) 0.5em;
    cursor: pointer;
    background : none;
}


// textarea
textarea {
    width: 100%;
    height: 8em;
    min-height: 3em;
    resize: vertical;
    display: block;
    line-height: 1.5;
    font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic ProN','メイリオ',Meiryo,Verdana,Droid Sans,sans-serif;

    @media only screen and (min-width: 480px) {
        height: 12em;
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}


// select (1行)
select {
    // padding-right: 32px;
    padding-right: 24px;

    background-repeat: no-repeat;
    background-image: url(../img/common/icon-select-margin.svg);
    background-position: right center;
    background-size: 32px 16px;
    appearance: none;
    line-height: 1.5;

    position: relative;

    // IEで矢印を非表示
    &::-ms-expand {
        display: none;
    }

    option {
        outline: none;
    }
}
select[value=""] {
    color: #f00;
}

// select (複数行)
select[size]:not([size="1"]),
select[multiple] {
    padding: 0;
    background-image: none;
    overflow: auto;

    option {
        // padding: 0.75em 1em;
        border: none;
    }
    optgroup {
        padding: 0.5em 0;
        color: #ccc;
        font-size: 0.85em;
        font-weight: normal;
        text-indent: 5px;

        & > option {
            padding-left: 0;
            padding-right: 0;
            color: $color-text;
            font-size: 1rem;
            text-indent: 0;
        }
        & > option::before {
            content: "";
            width: 1em;
            display: inline-block;
        }

        & > option:first-child {
            margin-top: 5px;
            border-top: 1px dotted #ccc;
        }
    }
    optgroup:not(:first-child) {
        border-top: 1px dotted #ccc;
    }
    optgroup:last-child {
        padding-bottom: 0;
    }
}


// バリデーション
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select {
    // 失敗、エラー
    &.is-invalid {
        color: $color-danger;
        border-color: $color-danger;
        background-color: $color-danger-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-danger;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-danger, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-danger, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-danger, 0.65);
        }
    }

    // 成功
    &.is-valid {
        color: $color-success;
        border-color: $color-success;
        background-color: $color-success-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-success;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-success, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-success, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-success, 0.65);
        }
    }
}



/* ラジオボタン、チェックボックス */
/*----------------------------------------------------------------------------*/

// ラジオボタン、チェックボックスを囲んでいるlabel
.field-radio,
.field-checkbox {
    flex-wrap: wrap;
    margin: 0;

    // 「.field > *」のmargin、paddingをキャンセル
    /*
    & > label,
    & > label:not(:first-child),
    & > label:not(:last-child) {
        margin: 0;
        padding: 0.5em;
    }
    */
    & > *,
    & > *:not(:first-child),
    & > *:not(:last-child) {
        margin: 0;
        flex: 0 0 auto;
    }

    // WordPress MW WP Form
    & > .mwform-radio-field,
    & > .mwform-checkbox-field {
        margin: 0 !important;

        &.vertical-item {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 0.5em - 0.5em);
        }
    }

    // & > label {
    label {
        // MW WP Formではみ出るのでコメントアウト
        // max-width: calc(100% - 0.5em - 0.5em);
        color: $color-text;
        display: flex;
        align-items: center;
        // IEでラジオボタンが横方向に潰れる
        // flex: 0 1 7em;
        // flex: 0 0 auto;
        cursor: pointer;
        white-space: nowrap;

        .icon {
            // flex: 0 0 1.5rem;
        }
        span {
            flex: 1 1 calc(100% - 1.75rem);
        }
    }

    // & > label:hover {
    label:hover {

    }
    // 将来的にはjQueryを使用せず
    // :focus-withinで対応可能。
    // & > label:active,
    // & > label:focus-within,
    // & > label.focus {
    label:active,
    label:focus-within,
    label.focus {

    }
    // & > label.disabled {
    label.disabled {
        &,
        & *,
        input[type="radio"],
        input[type="checkbox"] {
            color: #ddd;
            cursor: default;
        }
    }
}

// CSSで実装
// radio、checkbox共通
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;

        & + .icon {
            margin: 0 0.5rem 0 -1rem;
            padding: 0;
            flex: 0 0 1.5rem;
            font-size: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & + .icon::before {
            transform: none;
            display: block;
        }
    }
}

// radio
input[type="radio"].before-icon {
    & + .icon {
        border-radius: 50%;

        box-shadow: 0 0 0 2px $color-border inset;
        background-color: $color-field;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
            transform: scale(0.5);
        }
    }
    &:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px $color-text inset;

        &::before {
            background-color: rgba($color-text, 0.75);
        }
    }

    // focus
    &:focus {
        outline: none;
    }
    &:active,
    &:focus {
        & + .icon {
            box-shadow: 0 0 0 2px $color-link inset;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                background-color: rgba($color-link, 0.75);
            }
        }
    }

    // disabled
    &:disabled,
    &[readonly] {
        & + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px $color-border inset;
            color: #ddd;
            cursor: default;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                background-color: $color-border;
            }
        }
    }
}

// checkbox
input[type="checkbox"].before-icon {
    & + .icon {
        border-radius: 10%;

        box-shadow: 0 0 0 2px $color-border inset;
        background-color: $color-field;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            width: 90%;
            height: 40%;
            // background: #f00;
            border-left: 4px solid transparent;
            border-bottom: 4px solid transparent;
            display: block;
            box-sizing: border-box;
            transform: translate(10%, -40%) rotate(-45deg);
        }
    }
    &:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px $color-text inset;

        &::before {
            border-color: rgba($color-text, 0.75);
        }
    }

    // focus
    &:focus {
        outline: none;
    }
    &:active,
    &:focus {
        & + .icon {
            box-shadow: 0 0 0 2px $color-link inset;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                border-color: rgba($color-link, 0.75);
            }
        }
    }

    // disabled
    &:disabled,
    &[readonly] {
        & + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px $color-border inset;
            color: #ddd;
            cursor: default;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                border-color: $color-border;
            }
        }
    }
}


// バリデーション
input[type="radio"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                background-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                background-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}
input[type="checkbox"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                border-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
            background-color: $color-success-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                border-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}


/* range */
/*----------------------------------------------------------------------------*/
.field-range {
    padding: 0.75em 0;
}

// 横線
@define-mixin range-track {
    appearance: none;
    outline: none;
    margin: auto 0;
    width: 100%;
    height: 2px;
    background-color: $color-border;
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
}

// つまみ
@define-mixin range-thumb {
    appearance: none;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    border: 2px solid $color-border;
    background-color: $color-field;
    border-radius: 50%;
}

input[type="range"] {
    appearance: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 2px;
    background-color: $color-border;
    // border-radius: 6px;
    cursor: pointer;
    position: relative;

    // 横線
    /*
    &::-webkit-slider-runnable-track {
        @mixin range-track;
    }
    */
    &::-moz-range-track {
        @mixin range-track;

    }
    &::-ms-track {
        @mixin range-track;
    }

    // つまみ
    &::-webkit-slider-thumb {
        @mixin range-thumb;

        &:hover,
        &:active {
            transform: scale(1.25);
        }
    }
    &::-moz-range-thumb {
        @mixin range-thumb;

        &:hover,
        &:active {
            transform: scale(1.25);
        }
    }
    &::-ms-thumb {
        @mixin range-thumb;

        &:hover,
        &:active {
            transform: scale(1.25);
        }
    }

    &:focus {
        outline: none;
        background-color: rgba($color-link, 0.75);
        /*
        &::-webkit-slider-runnable-track {
            outline: none;
            background-color: rgba($color-link, 0.75);
        }
        */
        &::-moz-range-track {
            outline: none;
            background-color: rgba($color-link, 0.75);
        }
        &::-ms-track {
            outline: none;
            background-color: rgba($color-link, 0.75);
        }

        &::-webkit-slider-thumb {
            outline: none;
            border-color: $color-link;
            transform: scale(1.25);
        }
        &::-moz-range-thumb {
            outline: none;
            border-color: $color-link;
            transform: scale(1.25);
        }
        &::-ms-thumb {
            outline: none;
            border-color: $color-link;
            transform: scale(1.25);
        }
    }

    // disabled
    &:disabled,
    &[readonly] {
        cursor: default !important;

        // 横線
        /*
        &::-webkit-slider-runnable-track {

        }
        */
        &::-moz-range-track {

        }
        &::-ms-track {

        }

        // つまみ
        &::-webkit-slider-thumb {
            transform: none !important;
            cursor: default !important;
        }
        &::-moz-range-thumb {
            transform: none !important;
            cursor: default !important;
        }
        &::-ms-thumb {
            transform: none !important;
            cursor: default !important;
        }
    }
}

input[type="range"] {
    // 失敗、エラー
    &.is-invalid {
        background-color: $color-danger;

        // 横線
        /*
        &::-webkit-slider-runnable-track {
            background-color: $color-danger;
        }
        */
        &::-moz-range-track {
            background-color: $color-danger;

        }
        &::-ms-track {
            background-color: $color-danger;
        }

        // つまみ
        &::-webkit-slider-thumb {
            border-color: $color-danger;
            background-color: $color-danger-light;
        }
        &::-moz-range-thumb {
            border-color: $color-danger;
            background-color: $color-danger-light;
        }
        &::-ms-thumb {
            border-color: $color-danger;
            background-color: $color-danger-light;
        }
    }

    // 成功
    &.is-valid {
        background-color: $color-success;

        // 横線
        /*
        &::-webkit-slider-runnable-track {
            background-color: $color-success;
        }
        */
        &::-moz-range-track {
            background-color: $color-success;
        }
        &::-ms-track {
            background-color: $color-success;
        }

        // つまみ
        &::-webkit-slider-thumb {
            border-color: $color-success;
            background-color: $color-success-light;
        }
        &::-moz-range-thumb {
            border-color: $color-success;
            background-color: $color-success-light;
        }
        &::-ms-thumb {
            border-color: $color-success;
            background-color: $color-success-light;
        }
    }
}


/* ファイル */
/*----------------------------------------------------------------------------*/
.field.field-file {
    // MW WP Formだと右側に余白ができる
    input[type="file"] {
        margin-right: 0;
        display: block;
    }
    // MW WP Formだと「×」ボタンが表示されるので消す
    .mwform-file-delete {
        margin-left: -0.5em;
        flex: 0 0 0%;
        display: none;
    }
}


/* 検索 */
/*----------------------------------------------------------------------------*/
.field.field-search {
    position: relative;

    @media only screen and (min-width: 770px) {
        width: 100%;

    }

    input {
        border-radius: 6px;
        width: 45px;
    }

    .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $color-main;
        cursor: pointer;
        width: 18px;
        height: 18px;
        @media all and (-ms-high-contrast: none) {
            top: 12px;
        }
    }
}


/* 年月日 */
/*----------------------------------------------------------------------------*/
.field-date {

    > span {
        display: inline-block;
        vertical-align: middle;

        &.unit {
            margin: 0 10px;
        }

        input {
            width: 125px;
        }
    }

    + p {
        margin-top: 0 !important;
    }
}


/* 同意チェックボックス */
/*----------------------------------------------------------------------------*/
.field.field-agree {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        margin: 0;
        flex: 0 0 auto;
    }

    & > label {
        margin-right: 0.5em;
        display: flex;
        align-items: center;

        input[type="checkbox"].before-icon {
            position: absolute;
        }
        input[type="checkbox"].before-icon + .icon {
            margin: 0;
        }
    }
    & > span {
        margin-left: 0;
    }
}


/* 画像radio */
/*----------------------------------------------------------------------------*/
.field-img-radio {
    position: relative;

    & > label {
        flex: 1 1 auto;

        img {
            width: 100%;
        }
    }

    input,
    .icon {
        opacity: 0;
        position: absolute;
    }

    input ~ img {
        opacity: 0.5;
    }
    input:checked ~ img {
        opacity: 1.0;
        box-shadow: 0 0 0 4px $color-base inset;
    }
}


/* バリデーション */
/*----------------------------------------------------------------------------*/
.field + .invalid-feedback,
.field + .valid-feedback {
    margin-top: 0;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

/* oお問合わせフォーム
地図取得表示を隠す為の表示 */
/*----------------------------------------------------------------------------*/
.form-hidden {
    position: fixed;
    top: -5000px;
}

/* oお問合わせフォーム */
/*----------------------------------------------------------------------------*/

section.form-area {
    padding: 40px 20px 40px;
    h3.h3-02{
        margin-bottom: 20px;
    }
    p {
        font-size: 12px;
    }
    p.title {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .text {
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 30px;
        margin-bottom: 30px;
        p {
            letter-spacing: normal;
            margin-top: 0;
        }
    }
    table,
    table tbody,
    table td,
    table tfoot,
    table th,
    table thead,
    table tr {
        border: 2px solid $color-base;
    }
    table tr {
        padding: 10px 0;
        &:last-child {
            padding-bottom: 20px;
        }
    }
    table tbody td > * + * {
        margin-top: 5px;
    }
    .confirm & .text-note {
        display: none;
    }
    @media only screen and (min-width: 0px) and (max-width: 660px) {
        table,
        table tbody,
        table td,
        table tfoot,
        table th,
        table thead,
        table tr {
            border-left-style: none;
            border-right-style: none;
            border-bottom-style: none !important;
        }
    }
    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {
    }
    @media only screen and (min-width: 770px) {
    }
    @media only screen and (min-width: 1000px) {
        padding: 50px 40px 50px;
    }
}

/* フォーム用カレンダー*/
/*----------------------------------------------------------------------------*/
table.ui-datepicker-calendar {
    tr {
        th,
        td {
            padding: 0 !important;
        }
    }
}

/* ※印 CSS */
.require-mark {
    color: $color-main;
}
th span.require-mark  {
    margin-left: 10px;
}

/* 関連画像_選択されていません表示 */
.field span.mwform-file-delete {
    flex: 0 0 20px;
}

/* 利用規約同意ボタン CSS */
.request-agree {
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    div {
        display: flex;
        justify-content: center;
    }
}
.mwform-checkbox-field label {
    display: flex;
}


/* 送信・確認・戻るボタン CSS */
/* コンテンツ背景 */
.published-provider table {
    background-color: #faf6e9;
    th {
        border-right: 2px solid #fff;
    }
}


/* 確認ボタン */
.contact-footer {
    display: flex;

    .btn-arrow-left {
        background-image: url(../img/common/icon-arrow-simple-left.svg);
        background-repeat : no-repeat;
        background-position : left 1.5em center;
        background-size: 0.7em;
    }
    .btn-arrow-right {
        background-image: url(../img/common/icon-arrow-simple.svg);
        background-repeat : no-repeat;
        background-position : right 1.5em center;
        background-size: 0.7em;
    }
    .btn-fixed {
        background-color: #777777;
        color: #fff;
        box-shadow: none;
    }
    .btn.btn-article {
        margin-bottom: 0;

        .confirm &,
        .complete & {
            min-width: 0;
            width: 130px;
        }

        @media only screen and (min-width: 660px) {
            min-width: 200px;
            width: 245px !important;
        }
    }
    input[type="submit"] {
        cursor: pointer;
    }
}



/* 送信完了ページ
--------------------------------------*/
.back-home {
    margin-top: 50px;
    text-align: center;

    a {
        margin-bottom: 0 !important;
    }
}



/* IE対応
--------------------------------------*/
input::-ms-clear {
    visibility:hidden
}