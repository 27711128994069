/* 記事リスト・ランキング用カテゴリータグのサイズ設定
---------------------------------------------------- */
.category {
    color: $color-base;
    vertical-align: middle;
    text-align: center;
    line-height: 1;

    &.category-tag-sm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 20px;
    }

    &.category-tag-md {
        position: relative;
        display: inline-block;
        width: 83px;
        flex: 0 0 83px;
        padding: 5px 0;
        margin-right: 8px;
        font-size: 12px;

        @media only screen and (min-width: 1000px) {
            width: 120px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-right: 15px;
            font-size: 14px;
            flex: 0 0 120px;
        }
    }

    &.badge {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        padding: 2px;
        span {
            font-size: 10px;
            font-weight: bold;
            &.icon::before {
                margin-bottom: 5px !important;
            }
        }

        @media only screen and (min-width: 1000px) {
            font-size: 12px;
            width: 78px;
            height: 78px;
            &.icon::before {
                margin-bottom: 5px !important;
            }
        }
    }
}

/* カテゴリーの色分け
---------------------------------------------------- */
.tea-flower {
    background-color: #cddc39;
}
.event {
    background-color: #ed3223;
}
.musics {
    background-color: #00bcd4;
}
.traditional-culture {
    background-color: #673ab7;
}
.theater {
    background-color: #ffc107;
}
.art {
    background-color: #9c27b0;
}
.sport {
    background-color: #4caf50;
}
.lecture-course {
    background-color: #795548;
}
.movie {
    background-color: #ff5722;
}
.media {
    background-color: #3f51b5;
}