/* footer */
/*----------------------------------------------------------------------------*/
footer {
    
    .footer-nav-area {
        padding: 30px 30px 60px;
        text-align: center;

        @media only screen and (min-width: 1000px) {
            .container {
                ul {
                    display: inline-table;
                    &:not(:last-child) {
                        margin-right:  80px;
                    }
                }
            }
        }

        .footer-nav {
            display: none;

            @media only screen and (min-width: 1000px) {
                display: block;
                margin-bottom: 40px;
                ul {
                    text-align: left;
                    li{
                        &:not(:last-child) {
                            margin-bottom: 8px;
                        }
                        span.icon::before {
                            margin-right: 10px;
                            font-weight: bold;
                            font-size: 7px;
                        }
                    }
                }
            }
        }

        .footer-nav-banner {
            padding: 30px;
            display: inline-block;
            a {
                display: block;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            @media only screen and (min-width: 660px) {
                padding: 30px 40px;
                    display: flex;
                    a {
                        flex: 1 1 100%;
                        display: block;
                        &:not(:last-child) {
                            margin-right: 20px;
                            margin-bottom: 0;
                        }
                    }
            }
        }
    }

    .footer-nav-copyright-area {
        padding: 25px 0;
        text-align: center;
        font-size: 10px;
        color: $color-base;
        background-color: $color-main;
    }
}



/* footer_TOPボタン */
/*----------------------------------------------------------------------------*/

#PageTopBtn {
    position: fixed; /*ボタンの配置場所を固定*/
    bottom: 70px !important; /*下からのボタンの配置場所を指定*/
    right: 0; /*右からのボタンの配置場所を指定*/
    font-size: 13px; /*文字のサイズ*/
    display: none;
}

#PageTopBtn a {
    display: flex; /*配置の調整*/
    text-decoration: none; /*文字の下線を消す*/
    color: #fff; /*文字の色*/
    background: $color-main; /*ボタンの背景色*/
    outline: none; /*クリックしたときの黒い枠を消す*/
    width: 60px; /*ボタンの幅*/
    height: 60px; /*ボタンの高さ*/
    align-items: center;
    justify-content: center;
    &:hover {
        opacity: 0.7;
    }
}