body.home {

        @media only screen and (min-width: 480px) {
            max-height: 450px;
        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            max-height: 550px;
        }
        @media only screen and (min-width: 1000px) {
            max-height: 100vh;
        }

        // IEのための記述
        & > .mbYTP_wrapper {
            left: 0;
            right: 0;

            .playerBox {
                // initialは、IE11で使用不可
                // max-width: initial;
                max-width: none;
            }
        }

        .mainvisual-content {
            text-align: center;
            position: relative;
            z-index: 1000;

            .title {
                margin-top: 0;
                font-size: 18px;
                color: #fff;

                span {
                    display: block;

                }
            }
            .subtitle {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid #fff;
                font-size: 14px;
                color: #fff;
                display: inline-block;
            }
        }

        .btn-scroll-down {
            color: #fff;
            display: none;
            position: absolute;
            bottom: 50px;
            z-index: 1000;

            .text {
                margin-bottom: 14px;
                display: block;

                /* IE対応のための記述 */
                text-align: center;
            }
            .arrow {
                margin: 0 auto 18px auto;
                width: 2px;
                height: 18px;
                background: #fff;
                display: block;
                position: relative;

                &::before {
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: #fff;
                    transform: rotate(-45deg) translate(0, 1px);
                    transform-origin: 50% 100%;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
                &::after {
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: #fff;
                    transform: rotate(45deg) translate(0, 1px);
                    transform-origin: 50% 100%;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }
            }
            .logo {
                margin: 0 auto;
                width: 45px;
                height: 60px;
                background-image: url(../img/common/logo-mark-hiragana-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                display: block;
            }


        }

        @media only screen and (min-width: 480px) {
            .mainvisual-content {
                .title {
                    font-size: 24px;
                    span {
                        span {
                            display: inline-block;
                        }
                    }
                }
                .subtitle {
                    font-size: 16px;
                }
            }
        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            .btn-scroll-down {
                display: block;
            }
        }
        @media only screen and (min-width: 1000px) {
            .mainvisual-content {
                .title {
                    font-size: 44px;
                }
                .subtitle {
                    font-size: 24px;
                }
            }
        }
    }

    // アニメーション (スマホ以外のとき)
    .mainvisual:not(.mainvisual-sp) {
        .subtitle {
            transition: opacity 0.35s ease-out 3.0s,
                        transform 0.35s ease-out 3.0s;
        }
        .title {
            & > span:nth-child(1) {
                transition: opacity 0.35s ease-out 3.3s,
                            transform 0.35s ease-out 3.3s;
            }
            & > span:nth-child(2) {
                transition: opacity 0.35s ease-out 3.6s,
                            transform 0.35s ease-out 3.6s;
            }
        }
        .btn-scroll-down {
            transition: opacity 0.35s ease-out 4.0s,
                        transform 0.35s ease-out 4.0s;

            .text,
            .arrow {
                transition: opacity 0.5s ease-out 5.5s;
            }
        }

        html.load-active & {
            .subtitle {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }
            .title {
                & > span:nth-child(1),
                & > span:nth-child(2) {
                    opacity: 0;
                    transform: translate3d(0, 30px, 0);
                }
            }
            .btn-scroll-down {
                opacity: 0;
                transform: translate3d(0, 30px, 0);

                .text,
                .arrow {
                    opacity: 0;
                }
            }
        }

        html:not(.load-active) & {

        }

    /* アニメーション (スマホのとき) */
    .mainvisual.mainvisual-sp {
        background-image: url(../img/home/home-mainvisual-bg-sp.jpg);

        &::before {
            background-color: rgba(0, 34, 62, 0.2);
            opacity: 0.5;
        }
        &::after {
            // opacity: 0.35;
        }

        .subtitle {
            transition: opacity 0.35s ease-out 0.4s,
                        transform 0.35s ease-out 0.4s;
        }
        .title {
            & > span:nth-child(1) {
                transition: opacity 0.35s ease-out 0.6s,
                            transform 0.35s ease-out 0.6s;
            }
            & > span:nth-child(2) > span:nth-child(1) {
                transition: opacity 0.35s ease-out 0.8s,
                            transform 0.35s ease-out 0.8s;
            }
            & > span:nth-child(2) > span:nth-child(2) {
                transition: opacity 0.35s ease-out 1.0s,
                            transform 0.35s ease-out 1.0s;
            }
        }

        html.load-active & {
            .subtitle {
                opacity: 0;
                transform: translate3d(0, 30px, 0);
            }
            .title {
                & > span:nth-child(1),
                & > span:nth-child(2) > span:nth-child(1),
                & > span:nth-child(2) > span:nth-child(2) {
                    opacity: 0;
                    transform: translate3d(0, 30px, 0);
                }
            }
            .btn-scroll-down {
                opacity: 0;
                transform: translate3d(0, 30px, 0);

                .text,
                .arrow {
                    opacity: 0;
                }
            }
        }

        html:not(.load-active) & {

        }
    }

    .mainvisual:not(.mainvisual-sp) {

    }


    .home-service-area {
        .img {
            position: relative;
            z-index: 1000;
        }
        .content {
            padding: 30px;
            // max-width: 60%;
            box-sizing: border-box;
            position: relative;

            .logo {
                margin-left: -15px;
                width: 200px;
                display: block;
            }

            .btn {
                margin-top: 40px;
                display: inline-block;
            }
        }

        @media only screen and (min-width: 480px) {
            .layout:not(.layout-reverse) {
                display: block;

                & > :not(:last-child) {
                    margin-bottom: 30px;
                    margin-right: 0;
                }
            }
            .img {
                // margin-right: -20px;
            }
        }
        @media only screen and (min-width: 660px) {
            .layout:not(.layout-reverse) {
                display: flex;

                & > :not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 30px;
                }
            }
        }
        @media only screen and (min-width: 770px) {
            .layout:not(.layout-reverse) {
                .img {
                    margin-right: -20px;
                    flex: 1 1 50%;
                }
                .content {
                    margin-top: 50px;
                    margin-left: -20px;
                    padding: 40px 60px;
                    flex: 1 1 50%;

                    .btn {
                        margin-top: 40px;
                        display: inline-block;
                    }
                }
            }

        }
        @media only screen and (min-width: 1000px) {
            .layout:not(.layout-reverse) {
                .img {
                    margin-right: -40px;
                }

                .content {
                    margin-left: -40px;
                    padding: 50px 100px;

                    .logo {
                        margin-left: -25px;
                        width: 300px;
                    }

                    .btn {
                        margin-top: 40px;
                        display: inline-block;
                    }
                }
            }
        }
        @media only screen and (min-width: 1300px) {
            .content {
                padding: 70px 130px;

                .btn {
                    margin-top: 80px;
                    display: inline-block;
                }
            }
        }
    }
}

.mainvisual {
    .btn-scroll-down {
        .arrow {
            animation: mainvisual-btn-scroll-arrow 2s linear 0.0s infinite;
        }
    }
}

@keyframes mainvisual-btn-scroll-arrow {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(0, 10px);
    }
    30% {
        transform: translate(0, 10px);
    }
    90% {
        transform: translate(0, 0);
    }
}
