.module-group,
.module,
.border-group,
.border,
.card-group,
.card,
.banner-group,
.banner {

    .content {

        & > *,
        & > ul > li {
            display: block;
        }
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .text {
        line-height: $line-height-title;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }
}


.module-group,
.border-group,
.card-group {
    margin: -10px;

    & > *,
    & > ul > li {
        margin: 10px;
        box-sizing: border-box;
    }

    @media only screen and (min-width: 480px) {
        display: flex;
        flex-wrap: wrap;

        & > *,
        & > ul > li {
            flex: 1 0 40%;
        }
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        & > *,
        & > ul > li{
            flex: 1 0 20%;
        }
    }
    @media only screen and (min-width: 1000px) {

    }
}

.module-group > *,
.module {
    & > *,
    & > ul > li {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
}

.border-group > *,
.border {
    padding: 15px;
    border: 2px solid $color-border;

    & > *,
    & > ul > li {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        // padding: 30px;
    }
    @media only screen and (min-width: 1000px) {

    }
}


/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.card-group > *,
.card {
    padding: 15px;
    background: #fff;
    color: $color-text;

    & > *,
    & > ul > li
     {
        display: block;
    }
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }

    .thumbnail:first-child {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .thumbnail:last-child {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {
        // padding: 30px;
    }
    @media only screen and (min-width: 1000px) {

    }
}


/* 枠線のエリア */
/*----------------------------------------------------------------------------*/
.banner-group {
    & > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.banner-group > *,
.banner {
    display: flex;

    & > *,
    & > ul > li {
        flex: 1 1 0%;
    }

    & > .content {
        padding: 15px;
        background: #fff;

        & > *,
        & > ul > li {
            display: block;
        }
        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}


/* 横並びのナビゲーション */
/*----------------------------------------------------------------------------*/
.nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ul {
        padding-left: 0;
        display: block;
        li {
            list-style-type: none;
            display: block;
        }
    }
}

/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    display: flex;
    padding: 0 10px;
    max-width: 650px;
    margin-bottom: 20px;
    align-items: center;
    box-sizing: border-box;

    .single-post & {
        margin-bottom: 30px;
    }
    .page-template & {
        margin-bottom: 20px;
    }

    ol {
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 0;
            display: block;
        }
        li + li::before {
            content: ">";
            padding: 0 0.5em;
            display: inline-block;
        }
    }

    @media only screen and (min-width: 480px) {
    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
    @media only screen and (min-width: 1300px) {

    }
}



/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    // border-radius: 4px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .progress-bar {
        background: $color-base;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/
.step-group {
    display: flex;

    position: relative;

    .step {
        flex: 1 1 0%;
        text-align: center;
        position: relative;
        cursor: default;

        .badge {
            margin: 0 auto 1em auto;
            padding: 0;
            width: 40px;
            height: 40px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: rgba(0, 0, 0, 0.2);
            font-size: 1.0em;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            z-index: 1000;
        }
        .content {
            color: $color-text;
            font-size: 12px;
        }

        &.active {
            .badge {
                background-color: $color-base;
                box-shadow: 0 0 0 1px $color-base inset;
                color: #fff;
            }
            .content {
                color: $color-base;
            }
        }
        &:hover {
            opacity: 1.0;
        }
    }

    .step:not(:first-child) {
        &::before,
        &::after {
            content: "";
            height: 2px;
            display: block;
            position: absolute;
            top: calc(40px / 2 - 2px / 2);
            left: -50%;
            z-index: 0;
        }

        &::before {
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
        }
        &::after {
            width: 0%;
            background: $color-base;
            transition: width 0.2s ease-in-out 0.0s;
        }
    }
    .step.active:not(:first-child) {
        /*
        &::before {
            background: $color-base;
        }
        */
        &::after {
            width: 100%;
        }
    }
}

/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;

    section& {
        display: block;
    }
}

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: $color-base;
    color: #fff;

    table {
        &,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            border-color: #fff;
        }
    }
}
.box.box-white {
    background-color: #fff;
    color: $color-base;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;
    }
    .box-md {
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

.box-text {
    min-height: 200px;
    height: auto;

    .title {
        margin-bottom: 15px;
        line-height: 1;
    }

    @media only screen and (min-width: 660px) {
        max-width: 400px;
    }
    @media only screen and (min-width: 770px) {
        .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }
}


.box {
    .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }
}


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px $color-base inset;
    box-sizing: border-box;

    @media only screen and (min-width: 770px) {
        width: 160px;
        height: 160px;
    }

    /* aタグの装飾をキャンセル */
    html body & {
        border: none !important;
    }

    /* ボタンのサイズ */
    &.circle-lg {

    }
    &.circle-sm {
        width: 70px;
        height: 70px;
    }

    &,
    &.circle-fill {
        background-color: $color-btn;
        color: #fff;

        a&:hover {
            background-color: transparent;
            color: $color-btn;
        }
    }
    &.circle-outline {
        background-color: transparent;
        color: $color-btn;

        a&:hover {
            background-color: $color-btn;
            color: #fff;
        }
    }
    &.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        a&:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;
        }
    }

    & > span {
        display: block;
        text-align: center;
        line-height: 1.2;

        @media only screen and (min-width: 770px) {
            line-height: 1.5;
        }

        span {
            display: inline-block;
        }
    }
}

/* お知らせ */
/*----------------------------------------------------------------------------*/
.home-header {
    max-width: 980px;
    margin: 0 auto;
    .home-header-info {
        display: flex;
        margin-top: 40px;
        background-color: $color-base;
        .title{
            background-color: $color-main;
            color: $color-base;
            padding: 10px 0;
            min-width: 130px;
            text-align: center;
        }
        dl {
            background-color: $color-base;
            display: flex;
            align-items: center;
            dt {
                min-width: 160px;
                padding: 0 20px;
                font-weight: bold;
            }
            dd {
                a {
                    color :$color-text-other-link;
                }
                a:hover {
                    color: $color-link-hover;
                }
            }
        }
    }
}

main {
    margin-top: 0;
    padding-bottom: 165px;
}


/* 記事一覧 < 前日 翌日 >表示
---------------------------------------------------------- */
.view-header,
.view-footer {
    .mode-nav{
        font-size: 12px;
        font-weight: bold;
        height: 58px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (min-width: 770px) {
            font-size: 16px;
        }
        a {
            font-weight: bold;
            color: $color-text-other-link;

            &:hover {
                color: $color-link-hover;
            }
        }
        a.yesterday.icon {

            &::before {
                position: relative;
                top: 2px;
                transform: rotate(180deg);
                margin-right: 10px;
            }
        }
        a.tomorrow.icon {

            &::before {
                content: "";
            }
            &::after {
                @mixin icon;
                @mixin icon-arrow-simple;
                position: relative;
                top: 2px;
                margin-left: 10px;
            }
        }
    }
}

.view-header {
    margin-bottom: 40px;

    @media only screen and (min-width: 1000px) {
        margin-bottom: 20px;
    }

    .mode-nav {
        margin-bottom: 15px;
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;

        div {
            display: flex;
            justify-content: center;
            align-items: center;

            .weekly-event {
                position: relative;
                left: 20px;
                right: 20px;
            }
            .sp-weekly {
                font-size: 12px;
            }

            @media only screen and (min-width: 660px) {
                font-size: 16px;
                .weekly-event {
                    font-size: 20px;
                    .sp-weekly {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.view-footer {
    margin: 30px 0 0;
    div.mode-nav {
        padding: 0 20px 0;
    }
}

/* マップ・開催日時切り替え / リスト(一覧)
---------------------------------------------------- */
.switch-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* マップ・開催日時切り替え
    ---------------------------------------------------- */
    .switch-nav-left {
        display: flex;

        label {
            position: relative;
            cursor: pointer;

            input {
                position: absolute;
                opacity: 0;
            }

            span.switch-nav-list{
                display: block;
                background-color: $color-base;
                color: $color-main;
                font-weight: bold;
                width: 100px;
                height: 37px;
                border-radius: 18.5px 0 0 18.5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span.switch-nav-map{
                display: block;
                background-color: $color-base;
                color: $color-main;
                font-weight: bold;
                width: 100px;
                height: 37px;
                border-radius: 0 18.5px 18.5px 0 ;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        label input:checked + span {
            background-color: $color-main;
            color: $color-base;
        }
    }
    /* 開催日時部分
    ---------------------------------------------------- */
    .switch-nav-right {
        font-size: 16px;

        a.icon.icon-asc {
            color: #222;
            &::after {
                @mixin icon;
                @mixin icon-asc;
                font-size: 18px;
                color: $color-main;
                position: relative;
                top: 3px;
                margin-left: 5px;
            }
            &::before {
                content: "";
            }
        }

        a.icon.icon-desc {
            &::after {
                @mixin icon;
                @mixin icon-desc;
                font-size: 18px;
                color: $color-main;
                position: relative;
                top: 3px;
                margin-left: 5px;
            }
            &::before {
                content: "";
            }
        }
    }
}

/* イベント表示件数表示用の吹き出しデザイン
---------------------------------------------------- */
.balloon {
    z-index: 3;
    letter-spacing: normal;
    position: relative;
    top: -30px;
    left: 0;
    display: inline-block;
    padding: 8px 6px;
    min-width: 40px;
    height: 40px;
    line-height: 15px;
    vertical-align: middle;
    text-align: center;
    color: #fcf38a;
    font-size: 14px;
    font-weight: bold;
    background: #e91e63;
    border-radius: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 20px;

    .unit {
        font-size: 10px;
        color: $color-base;
    }

    span {
        display: block;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: -12px;
        margin-top: -35px;
        border: 10px solid transparent;
        border-left: 20px solid #e91e63;
        z-index: -1;
        -ms-transform: rotate(140deg);
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
    }

    @media only screen and (min-width: 660px) {
        & {
            top: -20px;
            left: 10px;
            font-size: 14px;
            margin-left: 15px;
            .unit {
                font-size: 12px;
            }
        }
    }
}

/* 記事一覧 - リスト用 中止・延期・変更のタグ */
/*--------------------------------------------------*/
.update-information {
    position: absolute;
    top: -15px;
    left: 10px;
    right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #d1effc;
    border-radius: 6px 6px 0 0;
    line-height: 1;

    @media only screen and (min-width: 1000px) {
        font-size: 14px !important;
        top: 10px;
        bottom: 10px;
        left: -10px;
        width: 25px;
        height: auto;
        border-radius: 6px 0 0 6px;
        line-height: 1.2;
    }

    &.change {
        background-color: #fcf38a;
        color: #ff5722;
        font-weight: bold;
    }
    &.postponement {
        background-color: #c4f7c6;
        color: #4caf50;
        font-weight: bold;
    }
    &.stop {
        background-color: #f8c2c2;
        color: #d32f2f;
        font-weight: bold;
    }
}

ul.event-area {
    margin-bottom: 30px;

    @media only screen and (min-width: 1000px) {

        margin-left: 0;
        margin-right: 0;
        padding: 0;
        padding-left: 10px;
    }

    li {
        margin-bottom: 35px;
        list-style: none;
        box-sizing: border-box;

        @media only screen and (min-width: 1000px) {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        a {
            padding: 30px 10px 15px 10px;
            position: relative;
            display: flex;
            font-size: 12px;
            flex: 1 1 auto;
            color: $color-text;

            @media only screen and (min-width: 1000px) {
                padding: 30px 20px 30px 35px;
            }

            .thumbnail {
                background-color: #eee;
                width: 85px;
                height: 85px;
                margin-right: 10px;
                overflow: hidden;

                @media only screen and (min-width: 1000px) {
                    width: 120px;
                    height: 120px;
                    margin-right: 20px;
                }
            }

            .detail {
                flex-basis: calc(100% - 95px);

                @media only screen and (min-width: 1000px) {
                    flex-basis: calc(100% - 140px);
                }
            }

            .event-area-header {
                margin-bottom: 15px;
                line-height: 1;

                .event-term {
                    font-size: 12px;
                    padding: 10px 0 6px;
                    display: block;
                }

                .event-dayoff {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.3;
                    display: block;
                }

                @media only screen and (min-width: 1000px) {
                    & {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 15px;

                        .event-term {
                            margin-bottom: 5px;
                            padding: 0;
                            font-size: 14px;
                        }

                        .event-dayoff {
                        }
                    }
                }
            }

            .event-area-title {
                padding-bottom: 15px;
                margin-bottom: 10px;
                border-bottom: solid 1px #7f7f7f;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: normal;
                line-height: 1.3;

                @media only screen and (min-width: 1000px) {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 1.3;
                }
            }

            .event-area-sbtitle {
                font-size: 12px;
                line-height: 1.3;
                margin-bottom: 10px;

                @media only screen and (min-width: 1000px) {
                    font-size: 14px;
                }
            }

            .event-area-footer {
                line-height: 1.2;

                @media only screen and (min-width: 770px) {
                    display: flex;
                }

                dl {
                    font-size: 10px;
                    display: flex;

                    @media only screen and (min-width: 770px) {
                        font-size: 12px;
                        flex-basis: calc(100% / 2);
                    }

                    &:not(last-child) {
                        margin-bottom: 5px;
                        @media only screen and (min-width: 770px) {
                            margin-bottom: 0;
                            margin-right: 15px;
                        }
                    }

                    dt {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 33px;
                        height: 18px;
                        font-size: 10px;
                        background-color: #777777;
                        color: $color-base;
                        margin-right: 5px;

                        @media only screen and (min-width: 770px) {
                            width: 40px;
                            font-size: 12px;
                        }
                    }

                    dd {
                        width: 100%;
                        margin-top: 3px;
                        line-height: 1.2;
                        word-wrap: break-word;

                        @media only screen and (min-width: 770px) {
                            margin-top: 2px;
                        }
                    }

                    .time {
                        margin-bottom: 5px;
                    }
                }

            }

            &:hover {
                color: $color-main;
                span.thumbnail {
                    opacity: 0.5;
                    transition: 0.5s;
                    -webkit-transform: scale(2);
                    transform: scale(2);
                }
            }
        }
    }
}



/* お知らせ 表示 */
/*----------------------------------------------------------------------------*/

.information-area {
    margin-top: 20px;
     margin-bottom: 15px;

     @media only screen and (min-width: 1000px) {
        & {
            margin-bottom: 30px;
        }
    }
    .information-content {
        display: flex;
        background-color: $color-base;

        .title{
            background-color: $color-main;
            color: $color-base;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
        }

        @media only screen and (min-width: 1000px) {
            .information-content {
                .title{
                    font-size: 16px;;
                    min-width: 130px;
                }

            }
        }

        dl {
            background-color: $color-base;
            align-items: center;
            padding: 15px 10px;
            width: 100%;

            @media only screen and (min-width: 1000px) {
                display: flex;
                align-items: center;
                padding: 15px 20px;
            }

            dt {
                font-size: 12px;
                margin-bottom: 10px;
                min-width: 160px;
                font-weight: bold;
                @media only screen and (min-width: 1000px) {
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }

            dd {
                a {
                    line-height: 1.2;
                    color :$color-text-other-link;
                }
                a:hover {
                    color: $color-link-hover;
                }
            }
        }
    }
}



/* 下層ページ_記事一覧(サイズ小) */
/*----------------------------------------------------------------------------*/
.related-article {
    @media only screen and (min-width: 660px) {
        margin-top: 40px;
    }
    .view-footer {
        .mode-nav {
            padding: 0;
        }
    }

    .wrap-event-area {
        background-color: #faf6e9;

        ul.event-area {
            padding: 30px 10px 20px;

            @media only screen and (min-width: 660px) {
                padding: 40px 30px 40px;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0 !important;

                .event-term,
                .event-dayoff,
                dl dt,
                dl dd {
                    font-size: 10px !important;
                }
            }
            @media only screen and (min-width: 1000px) {
                padding: 20px 30px 20px;
            }
        }
    }
}


/* マップ バルーン */
/*----------------------------------------------------------------------------*/
.m-map-balloon {

    .e-cate {
        display: inline-block;
        line-height: 1;
        padding: 3px 10px;
        font-size: 10px;
        color: $color-base;
    }

    .e-status {
        display: inline-block;
        margin-left: 5px;
        color: #d32f2f;
        font-weight: 700;
    }

    .e-title {
        line-height: 1.2;
        font-size: 12px;
        margin-top: 5px;
    }

    .e-place {
        font-size: 10px;
        margin-top: 2px;
    }
}

/* no-event(情報なしの場合の表示) */
/*----------------------------------------------------------------------------*/
.no-event {
    text-align: center;
}