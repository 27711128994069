/*アーティスト詳細・会場詳細共通
----------------------------------------------------*/
.article-content {

    @media only screen and (min-width: 660px) {
        table {
            margin-bottom: 0;
            td {
                .postal-code {
                    line-height: 1;
                }
                .address {
                    line-height: 1.5;
                }
            }
        }
    }
    .article-header {
        padding-bottom: 15px;
        background-image: url(../img/article/dot-line-blue.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 10px 4px;
        border: none !important;
        font-size: 12px;

        @media only screen and (min-width: 1000px) {
            display: flex;

            .text {
                display: flex;
                align-items: flex-end;
                line-height: 2;
                margin-left: 20px;
            }
        }
    }

    input.blog-parts {
        border: 1px solid #c4c4c4;
        padding: 6px;
        font-size: 12px;
    }

    /*アーティスト詳細
    ----------------------------------------------------*/
    .artist-commentary {
        img {
            width: 190px;
            margin: 0 auto 20px;

            @media only screen and (min-width: 1000px) {
                width: 200px;
                height: 200px;
                float: left;
                margin-right: 20px;
                margin-bottom: 60px;
            }
        }

        @media only screen and (min-width: 1000px) {
            margin-top: 30px;

            p {
                line-height: 1.7;
            }
            p + p {
                margin-top: 20px;
            }
        }

    }

}

