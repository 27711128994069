/* テーブル全般 */
/*----------------------------------------------------------------------------*/
.page-template {
    table {
        & tbody th:first-child {
            width: 190px;
        }
    }
}
table {
    // margin-top: 3em;
    // margin-bottom: 3em;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    line-height: $line-height-title;

    th,
    td {
        padding: 10px;
        font-size: 12px;
        img,
        iframe {
            vertical-align: top;
        }
    }
    th {
        text-align: left;
        vertical-align: middle;
        font-weight: bold;
        white-space: nowrap;
        padding-bottom: 0 !important;
    }
    td {
        padding-top: 0 !important;
        //padding-bottom: 0 !important;
    }
    &,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        border: 1px none $color-border;
        box-sizing: border-box;
    }
    caption {
        text-align: left;
        font-weight: bold;
        margin-bottom: 0.75em;
    }

    @media only screen and (min-width: 480px) {
        tbody {
            th:first-child {
                width: 90px;
            }
        }
    }
    @media only screen and (min-width: 660px) {
        th,
        td {
            padding: 15px !important;
        }
    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {
        th,
        td {
            padding: 15px 10px;
            img,
            iframe {
                vertical-align: top;
            }
        }

    }
}

.table-sm {
    font-size: 0.85em;

    th,
    td {
        padding: 0.5em;
    }
}
.table-lg {
    font-size: 1.2em;

    th,
    td {
        padding: 10px 15px;
    }
}
.table-fixed {
    table-layout: fixed;
}


/* テーブル (横線) */
/*----------------------------------------------------------------------------*/
table,
table.table-border-horizontal {
    &,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        border-top-style: solid;
        border-bottom-style: solid;
        word-break : break-all;
    }

    @media only screen and (min-width: 0px) and (max-width: 769px) {

    }
    @media only screen and (min-width: 770px) {
        &:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) {
            th,
            td {
                &:first-child {
                    padding-left: 10px;
                }
                &:last-child {
                    // padding-right: 4px;
                }
            }
        }
    }
}


/* テーブル (枠線) */
/*----------------------------------------------------------------------------*/
table.table-bordered {
    th {
        background: $color-bg;
    }
    &,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        border-style: solid;
    }
}

/* テーブル (枠線なし) */
/*----------------------------------------------------------------------------*/
table.table-border-none {
    &,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        background: none;
        border-width: 0;
        border-style: none;
        border-color: transparent;
    }
}


/* テーブル (オリジナル_ドット) */
/*----------------------------------------------------------------------------*/
table.table-dot {
    & {
        background-image: url(../img/article/dot-line.png);
        background-position: top;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;
    }
    tr {
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;

    }
}

// テンプレート標準の記述のキャンセル
table:not(.not-responsive):not(.ui-datepicker-calendar).table-dot  {
    tr {
        border-style: none;
    }
    tr:last-child {
        border-bottom-style: none;
    }
}
table:not(.not-responsive):not(.ui-datepicker-calendar):not(.table-border-horizontal).table-dot {
    tbody {
        border-right-style: none;
        border-left-style: none;
    }
}

/* テーブル (オリジナル_背景色あり) */
/*----------------------------------------------------------------------------*/
table.table-bg {
    tr:nth-child(odd){
    background: #faf6e9;
    }
    a {
        color: $color-main;
    }
    a:hover {
        color: $color-link-hover;
    }

    @media only screen and (min-width: 1000px) {
        th {
            padding-left: 25px !important;
        }
        td {
            padding-right: 20px !important;
            word-break: break-all;
        }
    }
}

// レスポンシブ
/*----------------------------------------------------------------------------*/
.table-reverse {}
.table-scroll {}
.table-smart {}
.not-responsive {}

table:not(.not-responsive):not(.ui-datepicker-calendar) {
    @media only screen and (min-width: 0px) and (max-width: 479px) {
        &,
        caption,
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            display: block;
            border-style: none;
        }

        &:not(.table-border-horizontal) {
            thead,
            tbody,
            tfoot {
            }
        }

        thead,
        tbody,
        tfoot {
            &:not(:last-child) {
                margin-bottom: 0.5em;
            }
        }
        tr {
            margin-bottom: 0.5em;
            border-top-style: solid;
        }
        tr:last-child {
            margin-bottom: 0;
            border-bottom-style: solid;
        }
        th,
        td {
            border-bottom-style: dotted;
        }
        td:last-child,
        th:last-child {
            border-bottom-style: none;
        }

        th:empty {
            display: none;

            &::before {
                content: "-";
            }
        }
        th {
            // padding-top: 0.5em;
            // padding-bottom: 0.5em;
        }
    }
}


.fc table,
.not-responsive {
    display: table;

    thead {
        display: table-header-group;
    }
    tbody {
        display: table-row-group;
    }
    tfoot {
        display: table-footer-group;
    }
    tr {
        display: table-row;
    }
    td, th {
        display: table-cell;
    }
}



/* スマホとPCとで縦横が入れ替わるテーブル。折り返しが発生すると崩れるのでいまいち */
table.not-responsive.table-reverse {

    @media only screen and (min-width: 1300px) {
        display: block;

        tbody {
            display: flex;
        }

        tr {
            display: block;
        }
        td,
        th {
            display: block;
        }

        tr:first-child {
            td {
                white-space: nowrap;
            }
        }

        tr:not(:last-child) {
            th,
            td {
                border-right: none;
            }
        }
        tr {
            th:not(:last-child),
            td:not(:last-child) {
                border-bottom: none;
            }
        }
    }
}


/* レスポンシブ対応のテーブル */
@media only screen and (min-width: 0px) and (max-width: 479px) {
    table:not(.not-responsive).table-scroll {
        display: flex;

        thead {
            display: flex;
        }
        tbody,
        tfoot {
            display: flex;
            overflow-x: auto;
        }
        tr {
            height: 100%;
            display: flex;

            flex-direction: column;
            justify-content: center;
        }
        th {
            height: 100%;
            font-size: 0.85em;
        }
        thead th:not(:first-child),
        thead td:not(:first-child) {
            height: 100%;
        }
        thead {
            flex-grow: 1;
        }
        tbody td {
            height: 100%;
            display: block;
            white-space: nowrap;
        }
    }
    table:not(.not-responsive):not(.no-border).table-scroll tr + tr {
        margin-top: 0;
    }
    table:not(.not-responsive):not(.no-border).table-scroll thead {
        margin-bottom: 0;
    }











    table.table-smart {
        border: 0;

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tr {
            display: block;
            margin-bottom: 0.625em;
        }
        td {
            border-bottom: 1px solid #ddd;
            display: block;
            text-align: right;
        }
        td:before {
            margin-bottom: 0.5em;
            content: attr(data-label);
            float: left;
            font-size: 0.85em;
            font-weight: bold;
        }
        td:last-child {
            border-bottom: 0;
        }

        ul,
        ol {
            margin-top: 10px;
            clear: both;
            text-align: left;
        }
    }
}
