/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    padding: .75em 1em;
    line-height: 1.5;
    border-radius: 4px;
    background-color: #aaa;
    color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;

    .btn {
        margin-left: auto;
        padding: 0;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        flex: 0 0 2em;
        font-size: .5em;
        line-height: 1;
        border: none;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.alert-danger {
        background-color: $color-danger;
    }
    &.alert-warning {
        background-color: $color-warning;
    }
    &.alert-success {
        background-color: $color-success;
    }
}

.alert-group {
    position: fixed;
    top: calc($header-height-sp + 5px);
    left: 5px;
    right: 5px;
    z-index: 10000;

    .alert:not(:last-child) {
        margin-bottom: 5px;
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {
        top: calc($header-height-pc + 5px);
    }
    
    &.alert-group-static {
        position: static;

        .alert {
            position: static;
        }
    }
}
