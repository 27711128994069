/* 便利なCSSを記述 */
/*----------------------------------------------------------------------------*/
:first-child {
    margin-top: 0;
}
:last-child {
    margin-bottom: 0;
}

/* clearfix、回り込み */
/*---------------------------------------------------*/
/*
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float-left { float: left; }
.float-right { float: right; }
.clear { clear: both; }
*/

/* ブロック */
/*---------------------------------------------------*/
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-table { display: table; }
.d-table-cell { display: table-cell; }
.d-flex { display: flex; }

/* 余白 */
/*---------------------------------------------------*/
.mt-auto { margin-top: auto !important; }
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-200 { margin-top: 200px !important; }
.mt-250 { margin-top: 250px !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-25 { margin-bottom: 25px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.mb-250 { margin-bottom: 250px !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-30 { margin-left: 30px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-50 { margin-left: 50px !important; }
.ml-60 { margin-left: 60px !important; }
.ml-70 { margin-left: 70px !important; }
.ml-80 { margin-left: 80px !important; }
.ml-90 { margin-left: 90px !important; }
.ml-100 { margin-left: 100px !important; }
.ml-110 { margin-left: 110px !important; }
.ml-120 { margin-left: 120px !important; }
.ml-130 { margin-left: 130px !important; }
.ml-140 { margin-left: 140px !important; }
.ml-150 { margin-left: 150px !important; }
.ml-200 { margin-left: 200px !important; }
.ml-250 { margin-left: 250px !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-50 { margin-right: 50px !important; }
.mr-60 { margin-right: 60px !important; }
.mr-70 { margin-right: 70px !important; }
.mr-80 { margin-right: 80px !important; }
.mr-90 { margin-right: 90px !important; }
.mr-100 { margin-right: 100px !important; }
.mr-110 { margin-right: 110px !important; }
.mr-120 { margin-right: 120px !important; }
.mr-130 { margin-right: 130px !important; }
.mr-140 { margin-right: 140px !important; }
.mr-150 { margin-right: 150px !important; }
.mr-200 { margin-right: 200px !important; }
.mr-250 { margin-right: 250px !important; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-110 { padding-top: 110px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-130 { padding-top: 130px !important; }
.pt-140 { padding-top: 140px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pt-250 { padding-top: 250px !important; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-110 { padding-bottom: 110px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-130 { padding-bottom: 130px !important; }
.pb-140 { padding-bottom: 140px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.pb-250 { padding-bottom: 250px !important; }

.pl-0 { padding-left: 0px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-40 { padding-left: 40px !important; }
.pl-50 { padding-left: 50px !important; }
.pl-60 { padding-left: 60px !important; }
.pl-70 { padding-left: 70px !important; }
.pl-80 { padding-left: 80px !important; }
.pl-90 { padding-left: 90px !important; }
.pl-100 { padding-left: 100px !important; }
.pl-110 { padding-left: 110px !important; }
.pl-120 { padding-left: 120px !important; }
.pl-130 { padding-left: 130px !important; }
.pl-140 { padding-left: 140px !important; }
.pl-150 { padding-left: 150px !important; }
.pl-200 { padding-left: 200px !important; }
.pl-250 { padding-left: 250px !important; }

.pr-0 { padding-right: 0px !important; }
.pr-5 { padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-20 { padding-right: 20px !important; }
.pr-30 { padding-right: 30px !important; }
.pr-40 { padding-right: 40px !important; }
.pr-50 { padding-right: 50px !important; }
.pr-60 { padding-right: 60px !important; }
.pr-70 { padding-right: 70px !important; }
.pr-80 { padding-right: 80px !important; }
.pr-90 { padding-right: 90px !important; }
.pr-100 { padding-right: 100px !important; }
.pr-110 { padding-right: 110px !important; }
.pr-120 { padding-right: 120px !important; }
.pr-130 { padding-right: 130px !important; }
.pr-140 { padding-right: 140px !important; }
.pr-150 { padding-right: 150px !important; }
.pr-200 { padding-right: 200px !important; }
.pr-250 { padding-right: 250px !important; }

/* 幅 */
/*---------------------------------------------------*/
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }


/* HTMLで改行した場合に生じる左右の余白を消す */
/*---------------------------------------------------*/
.parent-inline-block {
    letter-spacing: -1em;
    word-spacing: -1em;
}
:root .parent-inline-block {
    font-size: 0 !important;
    letter-spacing: -1px;
}
.parent-inline-block > * {
    font-size: 1rem;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}


/* 動画 */
/*---------------------------------------------------*/
.parent-youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.parent-youtube iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/* その他 */
/*---------------------------------------------------*/
/* いいねボタンの横幅が途切れてしまう現象への対策 */
.fb-like iframe {
    max-width: none !important;
}


/* 画面幅によって非表示 */
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
    .only-pc { display: none !important; }
    .only-tab { display: none !important; }
    .only-sp {  }

    .over-pc { display: none !important; }
    .over-tab { display: none !important; }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp {  }
}
@media only screen and (min-width: 480px) and (max-width: 769px) {
    .only-pc { display: none !important; }
    .only-tab {  }
    .only-sp { display: none !important; }

    .over-pc { display: none !important; }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp { display: none !important; }
}
@media only screen and (min-width: 770px) {
    .only-pc {  }
    .only-tab { display: none !important; }
    .only-sp { display: none !important; }

    .over-pc {  }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab { display: none !important; }
    .under-sp { display: none !important; }
}
