@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

body.contact,
body.confirm,
body.complete {

    .form-footer {
        margin-top: 40px;

        @media only screen and (min-width: 1000px) {
            margin-top: 80px;
        }

        .btn-group {
            justify-content: center;
        }
    }
}
