/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group {

    & > *:not(:last-child) {
        margin-bottom: 0.5em;
    }

    @media only screen and (min-width: 480px) {
        display: flex;
        align-items: center;

        & > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 0.5em;
        }
    }
}

input.btn,
button.btn {
    outline: none;
}

.btn {
    min-width: 200px;
    border: none;
    font-size: 1em;
    text-align: center;
    line-height: 1;
    box-shadow: 0 0 0 2px $color-btn inset;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    box-sizing: border-box;

    /* ボタンのサイズ */
    &.btn-lg {
        font-size: 1.1em;

        @media only screen and (min-width: 480px) {
            font-size: 1.2em;
            width: 320px;
        }
    }
    &.btn-sm {
        min-width: auto;
        font-size: 0.85em;
    }
    &.btn-block {
        display: block;
        max-width: 320px;
    }
    &.btn-fluid {
        display: block;
        width: 100%;
        max-width: 100%;
        text-align: left;

        @media only screen and (min-width: 480px) {
            text-align: center;
        }
    }

    /* ボタンの形 */
    &.btn-article {
        width: 245px;
        height: 50px;
        border-radius: 27px;
        display: flex;
        margin: 0 auto 40px auto;
        a {
            color: $color-base;
        }
    }
    &.btn-rect {
        padding: 1.25em 2em;
        border-radius: 4px;

        &.btn-lg {
            padding: 1.25em 2em;
        }
        &.btn-sm {
            padding: 0.75em 1.5em;
        }
    }
    &,
    &.btn-pill {
        padding: 12px;
        border-radius: 6px;

        &.btn-lg {
            padding: 1.25em 3em;
            box-sizing: border-box;
        }
        &.btn-sm {
            padding: 0.75em 1.5em;
        }

    }
    &.btn-circle {
        padding: 0;
        width: 2.5em;
        min-width: auto;
        height: 2.5em;
        min-height: auto;
        border-radius: 2.5em;

        line-height: 1;
        letter-spacing: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            width: 1em;
            height: 1em;
            line-height: 1;
            letter-spacing: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                line-height: 1;
                letter-spacing: 0;
                display: block;
            }
        }
    }

    /* 矢印 */
    & {
        position: relative;
    }

    &::after {
        @mixin icon-arrow-simple;
        @mixin icon;

        font-size: 0.75em;
        text-align: right;
        margin: auto 0 auto auto;
        width: 1em;
        height: 1em;
        display: block;
        position: absolute;
        top: 0;
        right: 1.0em;
        bottom: 0;

        font-size: 1.25em;

        transition: transform 0.2s ease 0.0s;
        transform: translate(0, 0) scale(1, 1);
    }
    &.btn-search {
        width: calc(100% - 20px);
        font-size: 16px;
        font-weight: bold;

        span {
            display: inline-block;
            margin-left: 40px;
        }
    }
    &.btn-search::after {
        @mixin icon-calender;
        @mixin icon;

        margin: auto 0 auto auto;
        width: 1em;
        height: 1em;
        display: block;
        position: absolute;
        top: 0;
        left: 2.0em;
        right: auto;
        bottom: 0;

        font-size: 26px;
    }
    &:hover::after {
        // transform: translate(0.5em, 0) scale(1, 1);
    }
    &.btn-prev::after {
        @mixin icon-arrow;

        margin: auto auto auto 0;
        right: initial;
        left: 1.0em;
        transform: translate(0, 0) scale(-1, 1);
    }
    &.btn-prev:hover::after {
        transform: translate(-0.5em, 0) scale(-1, 1);
    }

    .alert &,
    &.btn-sm,
    &.btn-noicon,
    &.btn-circle {
        &::after {
            display: none;
        }
    }

    /* ボタンの文字色、背景色、枠線の色(box-shadow) */
    &,
    &.btn-fill {
        background-color: $color-btn;
        color: #fff;

        &:hover {
            // background-color: transparent;
            // color: $color-btn;
            color: #fff;
            opacity: 0.7;
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: $color-btn;

        &:hover {
            background-color: $color-btn;
            color: #fff;
        }
    }
    &.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;
        }
    }

    @media only screen and (min-width: 480px) {

    }
    @media only screen and (min-width: 660px) {

    }
    @media only screen and (min-width: 770px) {

    }
    @media only screen and (min-width: 1000px) {

    }

    &.disabled,
    &:disabled,
    &[readonly] {
        &,
        &:hover {
            background-color: #777;
            box-shadow: 0 0 0 2px #777 inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;

            &::after {
                transform: none;
            }
        }
    }
}

.btn-white {
    box-shadow: 0 0 0 2px #fff inset;

    /* ボタンの文字色、背景色、枠線の色(box-shadow) */
    &,
    &.btn-fill {
        background-color: #fff;
        color: $color-btn;

        &:hover {
            // background-color: transparent;
            // color: #fff;
            opacity: 0.7;
        }
    }
    &.btn-outline {
        background-color: transparent;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $color-btn;
        }
    }
    &.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #fff;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    /*
    box-shadow: 0 0 0 2px #777 inset;
    border-radius: 4px;
    */

    & > * {
        padding: 0.75em;
        min-width: calc(1em + 0.75em + 0.75em);
        min-height: calc(1em + 0.75em + 0.75em);

        // .btn { } からコピペ
        border: none;
        font-size: 1em;
        text-align: center;
        line-height: 1;
        box-shadow: 0 0 0 2px #777 inset;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        box-sizing: border-box;

        // &.btn-skeleton { } からコピペ
        background-color: transparent;
        box-shadow: none;
        color: $color-text;

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $color-text;

            position: relative;
            z-index: 1000;
        }
    }

    & > *:not(:first-child) {
        margin-left: -2px;
    }

    & > *.current,
    & > *.current:hover {
        box-shadow: 0 0 0 2px $color-btn inset;
        background-color: $color-btn;
        position: relative;
        z-index: 1000;
    }

    & > span,
    & > *.disabled,
    & > *:disabled,
    & > *[readonly] {
        &,
        &:hover {
            background-color: #eee;
            box-shadow: 0 0 0 2px #eee inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;

            &::after {
                transform: none;
            }
        }
    }

    & > *:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    & > *:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

