html {
    font-size: 12px;
    font-family: $font-gothic;
    color: $color-text;

    @media only screen and (min-width: 770px) {
        font-size: 14px;
    }
}

p {
    line-height: $line-height-text;
}

.text {
    line-height: $line-height-text;
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    color: $color-link;
    text-decoration: none;
}
a:hover,
a:active {
    color: $color-link-hover;
    text-decoration: none;
}

hr {
    margin-bottom: 30px;
    height: 1px;
    border: none;
    border-bottom: 1px solid $color-text-gray;

    &.hr-dotted {
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none!important;
        height: 2px;
        margin: 0 0 40px 0;
    }
}


/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }

// 文字サイズ
.text-md {
    font-size: 12px;
    font-weight: normal;
}
.text-sm {
    font-size: 10px;
    font-weight: normal;
}

// 注釈
.text-note {
    display: block;
    line-height: 1.5;
    font-weight: normal;
}

// バリデーション
.invalid-feedback {
    font-size: 0.8em;
    color: $color-danger;
    display: block;
}
.valid-feedback {
    font-size: 0.8em;
    color: $color-success;
    display: block;
}

.text-base {
    color: $color-text;
}
strong {
    font-weight: bold;
}
