/* カテゴリーボタンPC・SP共通 */

/* カテゴリーナビゲーションの設定
----------------------------------------------- */
.category-nav {

    display: none;

    .nav-all {
        height: 55px;
        box-sizing: border-box;
        background-color:  $color-base;

        a {
            width: 100%;
            height: 100%;
            color: $color-main;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: $color-main;
        }
    }

    .nav-tea-flower {

        a {
            color: #cddc39;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #cddc39;
        }
    }

    .nav-event {

        a {
            color: #ed3223;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #ed3223;
        }
    }

    .nav-musics {

        a {
            color: #00bcd4;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #00bcd4;
        }
    }

    .nav-traditional-culture {

        a {
            color: #673ab7;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #673ab7;
        }
    }

    .nav-theater {

        a {
            color: #ffc107;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #ffc107;
        }
    }

    .nav-art {

        a {
            color: #9c27b0;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #9c27b0;
        }
    }

    .nav-sport {

        a {
            color: #4caf50;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #4caf50;
        }
    }

    .nav-lecture-course {

        a {
            color: #795548;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #795548;
        }
    }

    .nav-movie {

        a {
            color: #ff5722;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #ff5722;
        }
    }

    .nav-media {

        a {
            color: #3f51b5;
        }

        & a:hover,
        &.active a {
            color: $color-base;
            background-color: #3f51b5;
        }
    }

    /* スマホメニュー(カテゴリーボタン)
    ---------------------------------------------------- */
    .header-sp & {
        display: block;
        margin-bottom: 30px;
        border-radius: 6px;
        border: 1px solid #efefef;
        overflow: hidden;

        a {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;

            &.icon::before {
                margin-right: 10px;
            }
        }

        .nav-all {

            a {
                border-bottom: 1px solid #efefef;
                border-radius: 6px 6px 0 0;
            }
        }

        ul {
            border-radius: 0 0 6px 6px;
            display: flex;
            flex-wrap: wrap;

            li {
                /* スマホ横幅カテゴリー2分割 */
                flex-basis: calc(100% / 2);
                background-color: $color-base;

                a {
                    border-top: 1px solid #efefef;
                    border-left: 1px solid #efefef;
                    margin-left: -1px;
                    padding-left: 20px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                }
            }
        }
    }

    /* カテゴリーボタンのPC
    ---------------------------------------------*/
    @media only screen and (min-width: 1000px) {
        & {
            border-radius: 6px;
            overflow: hidden;
            height: 147px;
            display: flex;
            margin-bottom: 15px;

            .nav-all {
                text-align: center;
                width: 110px;
                height: 147px;
                z-index: 1;

                a {
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #efefef;
                }

                & a:hover,
                &.active a {
                    border-right: 1px solid $color-main;
                }

                span {
                    display: flex;
                    justify-content: center;
                    padding-top: 5px;
                }
            }

            ul {
                background-color:$color-base;
                width: 540px;
                border-radius: 0 6px 6px 0;
                margin-top: -1px;
                margin-left: -1px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 0;

                li {
                    margin: 0;
                    list-style-type: none;
                    display: block;
                    font-weight: bold;
                    letter-spacing: normal;
                    box-sizing: border-box;
                    width: 108px;
                    height: 50%;
                    border-top: 1px solid #efefef;
                    border-left: 1px solid #efefef;

                    a {
                        padding: 10px 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        span {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            line-height: 1;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }
}


html {
    .drawer--right .drawer-nav {
        right: -100vw;
    }

    body {
        padding-top: 60px;

        @media only screen and (min-width: 1000px) {
            padding-top: 20px;
            .drawer-hamburger,
            .drawer-nav {
                display: none;
            }
        }
        /* ハンバーガーメニューの設定
        ---------------------------------------------------------- */
        .drawer-hamburger-icon,
        .drawer-hamburger-icon:before,
        .drawer-hamburger-icon:after {
            width: 20px;
            height: 4px;
            border-radius: 20px;
            background-color: $color-base;
        }

        &.drawer--right .drawer-hamburger,
        &.drawer--right.drawer-open .drawer-hamburger {
            right: 10px;
        }

        &.drawer--right.drawer-open{

            .drawer-hamburger {
                top: 10px;
                background-color: $color-main;
                padding-bottom: 30px;
                padding-left: 21px;
                padding-right: 18px;
                border-radius: 50px;
            }

            .drawer-hamburger-icon {
                background-color: transparent;
            }
        }
    }
}


/* ヘッダーSP・PC切り替え
---------------------------------------------------- */
header {
    left: 10px;
    right: 10px;
    background: $color-main;
    color: #f5f5f5;
    position: fixed;
    top: 10px;
    z-index: 50000;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius : 60px;
    font-size: 12px;
    line-height: 1.2;

    .header-pc {
        display: none;
    }

    @media only screen and (min-width: 1000px) {
        position: static;
        margin: 0 20px;
        border-radius: 50px;
        box-sizing: border-box;
        padding: 0 50px;

        .header-sp {
            display: none;
        }

        .header-pc {
            display: flex;
            align-items: center;
            height: $header-height-pc;
        }

    }

    @media only screen and (min-width: 1300px) {
        padding-left: 90px;
        padding-right: 90px;

    }

    @media only screen and (min-width: 1500px) {
        width: 1460px;
        margin: 0 auto;
    }
}


/* スマホメニュー
---------------------------------------------------- */
.header-sp {
    padding: 0 25px;

    .drawer-nav {
        background-color: $color-main;

            .drawer-menu {
                margin-left: 20px;
                margin-right: 20px;

                .logo-nav {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }

    }

    h2.h2-04 {
        background-color: #81d4fa;
    }

    .logo-nav {
        height: 60px;
        display: table;

        > div {
            display: table-cell;
            vertical-align: middle;
        }

        .logo {
            width: 120px;
        }

        span {
            width: 120px;
            display: block;
        }
    }

    /* スマホメニュー(本日の日付)
    ---------------------------------------------------- */
    .side-today {

        .side-today-header {
            font-size: 20px;
        }

        .side-today-content {

            .today {
                font-size: 29px;

                .month,
                .day {
                    font-size: 40px;
                }
            }

            .week {
                height: 100%;
            }
        }

        .side-today-footer {
            font-size: 14px;

            .left {

                span + span {
                    font-weight: normal;
                }
            }
        }
    }

    /* スマホメニュー(カレンダー)
    ---------------------------------------------------- */
    .ui-datepicker {

        .ui-state-default {
            font-weight: bold;
            margin: 0 auto !important;
        }
    }

    /* スマホメニュー(検索ボックス)
    ---------------------------------------------------- */
    .search-box {
        margin-bottom: 30px;
    }

    /* スマホメニュー(ボタン)
    ---------------------------------------------------- */
    .side-list {
        @media only screen and (min-width: 660px) {
            width: calc(100% / 2);
            float: left;
            box-sizing: border-box;
            margin-right: 20px;

        }

        ul {
            li {
                a {
                    &.btn.btn-pill {
                        padding: 14px;
                    }
                }
            }

            li:not(:last-child) {
                margin-bottom: 10px;
            }
        }

    }

    /* スマホメニュー(イベント掲載申し込みバナー)
    ---------------------------------------------------- */
    .side-request-banner {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid $color-base;

        @media only screen and (min-width: 660px) {
            border-bottom-style: none;
            width: calc(50% - 25px);
            float: right;
            box-sizing: border-box;
            padding-bottom: 0;
        }

        a {
            display: block;
        }
    }


    /* スマホメニュー(ナビゲーション)
    ---------------------------------------------------- */
    .top-nav {
        margin-bottom: 30px;

        @media only screen and (min-width: 660px) {
            clear: both;
            padding-top: 30px;
            border-top: 1px solid $color-base;
        }

        ul {

            li {

                + li {
                    margin-top: 10px;
                }

                a {

                    font-size: 16px;
                    color: $color-base;
                    position: relative;

                    .icon-arrow-simple::before {
                        font-size: 13px;
                        font-weight: normal;
                        margin-right: 10px;
                    }

                    &:not(:first-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

/* PCメニュー(ナビゲーション)
---------------------------------------------------- */
.header-pc {

    .logo-nav {
        display: flex;
        align-items: center;
    }
    /* PC用ロゴ
    ---------------------------------------------------- */
    .logo-nav {
        display: flex;
        align-items: center;
        width: 430px;
        @media only screen and (min-width: 1300px) {
            width: auto;
        }
        .logo {
            margin-right: 15px;

            a {
                margin-left: auto;
                margin-right: auto;
                height: auto;

                img {
                    width: 236px;
                    display: block;
                }
            }
        }

        span {
            display: flex;
            align-items: center;
        }
    }

    /* PC用-検索ボックス・ナビゲーション
    ---------------------------------------------------- */
    .global-nav-btn {
        display: none;
    }

    .global-nav {
        margin-left: auto;

        .search-box {

            span.field {
                margin-top: 0;
                margin-bottom: 0;
                margin-left: auto;
                width: 340px;
            }
        }

        nav {
            display: block;
            margin-top: 15px;
            font-size: 14px;

            ul {
                margin: 0;
                padding-left: 0;
                width: 100%;
                display: flex;
                align-items: center;

                & > li {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    line-height: 1;

                    a {
                        color: $color-base;
                        line-height: 1;
                        opacity: 1.0;

                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        position: relative;

                        .icon::before {
                            position: relative;
                            top: 2px;
                            padding-right: 5px;
                        }
                    }

                    a:hover {
                        opacity: 0.7;
                    }
                }

                & > li:not(:last-child) {
                    margin-right: 25px;
                }

                & > li.current {

                    a {

                        &::before {
                            content: "";
                            width: 100%;
                            height: 2px;
                            background: $color-base;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
}


/* ヘッダー下文章
---------------------------------------------------------------*/
.home-header-message {
    margin-top: 15px;
    margin: 20px 10px;

    p {
        line-height: 1.4;

        a {
            color: $color-text-other-link;
            line-height: 1;
        }

        a:hover {
            color: $color-link-hover;
        }
    }
    @media only screen and (min-width: 770px) {
        margin: 20px 40px;
    }

    @media only screen and (min-width: 1000px) {
        margin: 20px auto;

        p {
            text-align: center;
            font-size: 14px;
        }
    }
}