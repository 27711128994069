/* PC サイドバー全体の調整
----------------------------------- */
.main-content-area {
    .container{

        @media only screen and (min-width: 1000px) {
            display: flex;

            main {
                flex: 1 1 auto;
                margin-right: 30px;
            }
            .side {
                flex: 0 0 300px;
                width: 300px;
            }
        }
    }
}


.side {
    display: none;

    @media only screen and (min-width: 1000px) {
        display: block;
        flex: 0 0 300px;
        box-sizing: border-box;
        margin-left: 0px;
        margin-right: 0px;
    }
}

/* PC サイドバー：今日の日付
----------------------------------- */
.side-today {
    padding: 20px;
    margin-bottom: 30px;
    background-color: #fff;
    .side-today-header{
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }

    .side-today-content {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 26px;
        font-weight: bold;

        .month,
        .day {
            color: #03a9f4;
            font-size: 36px;
            vertical-align: -1px;
        }

        .week {
            font-size: 20px;
            border: solid 2px;
            border-radius: 20px;
            align-items: center;
            line-height: 1;
            padding: 6px 12px;
            &.weekday {
                color: $color-text;
            }
            &.dayoff {
                color: #e91e63;
            }
            &.saturday {
                color: $color-main;
            }
        }
    }

    .side-today-footer {
        margin-top: 20px;
        background-color: #faf6e9;
        font-size: 12px;
        padding: 6px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
            letter-spacing: normal;
            flex: 1 1 auto;
            ul {
                line-height: 1;
                li {
                    height: 15px;
                    span {
                        font-weight: bold;
                        display: inline-block;
                        height: 15px;
                        line-height: 1;
                    }
                    span:not(:last-child) {
                        width: 50px;
                        border-right: solid 1px #222222;
                        margin-right: 5px;
                    }
                }
                li:not(:first-child) {
                    margin-top: 5px;
                }
            }
        }
        .right {
            flex: 0 0 120px;
            .btn {
                padding: 8px 5px;
                font-size: 12px;
                min-width: auto;
                line-height: 1.2;
                width: 100%;
            }
            .btn::after {
                font-size: 6px;
                right: 5px;
            }
        }
    }
}

/* PC サイドバー：カレンダー
----------------------------------- */
.side-calendar {
    margin-bottom: 30px;
}

/* PC サイドバー：イベント掲載申し込みバナー
----------------------------------- */
.request-banner {
    margin-bottom: 30px;
    background-color: #ffffff;

    img {
        vertical-align: bottom;
    }
}

/* PC サイドバー：ナビゲーション
----------------------------------- */
.side-list {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 20px;
    ul {
        li {
            margin-top: 5px;
        }
    }
}
a.btn.btn-pill {
display: block !important;
text-align: left;
}

/* PC サイドバー：ランキング
----------------------------------- */
.wpp-list {
    counter-reset: posts-rank;
}
.wpp-list li .badge:before {
    content: counter(posts-rank);
    counter-increment: posts-rank;
}
.wpp-list li:nth-child(n + 6) {
    display: none;
}

.side-item {
    ul {
        padding-left: 0;
        display: block;
        li {
            list-style-type: none;
            display: block;
            margin: 0;

            a {
                display: flex;
                .img {
                    flex: 0 0 90px;
                }
                .content {
                    flex: 1 1 auto;
                }
            }
        }
    }
}

.side-ranking {
    margin-top: 30px;
    background-color: #fff;
    ul {
        padding: 20px;
        li {
            position: relative;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            .badge {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 36px;
                font-weight: bold;
                height: 36px;
                background-color: #03a9f4;
                color: #fcf38a;
                font-size: 20px;
                box-shadow: none;
            }
            a {
                margin-top: 15px;
                padding-bottom: 25px;
                border-bottom: 1px solid #7f7f7f;
                color: $color-text;
                .thumbnail {
                    background-color: #eee;
                    box-sizing: border-box;
                    width: 90px;
                    height: 90px;
                    margin-left: 10px;
                    overflow: hidden;
                }
                .content {
                    padding-left: 15px;
                    font-size: 12px;
                    .event-name {
                        display: block;
                        margin-bottom: 10px;
                        letter-spacing: 0;
                        overflow-wrap: break-word;
                        line-height: 1.3;
                        font-weight: bold;
                    }
                    .event-term {
                        display: block;
                        font-size: 10px;
                        letter-spacing: 0;
                        padding-left: 10px;
                    }
                    .category {
                        display: block;
                        width: 100px;
                        text-align: center;
                        margin-bottom: 10px;
                        padding: 5px 0;
                        color: $color-base;
                    }
                }
                &:hover {
                    color: $color-link-hover;
                    span.thumbnail {
                        opacity: 0.5;
                        -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

