/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.5;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    background-repeat: no-repeat;
    border-radius: 0;
}
/*
body {
    color: #333333;
    letter-spacing: 0.075em;
}
*/
main {
    display: block;
}
/*
input,
textarea,
select:not([size]),
option {
    padding: 0.75em 1.0em;
}
td,
th {
    padding: 0.25em 1.0em;
}
ul,
ol {
    padding-left: 1.5em;
    list-style-position: outside;
}
ul li { list-style-type: disc; }
ol li { list-style-type: decimal }
input,
textarea,
select,
td,
th {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}
select,
option,
textarea,
input,
button {
    font-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0;
}
select,
option,
input,
button {
    line-height: 1;
}
*/
select,
option,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="date"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
/*
a {
    text-decoration: none;
}
td,
optgroup,
address {
    font-weight: normal;
}
a,
select,
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    cursor: pointer;
}
textarea {
    vertical-align: top;
    overflow: auto;
    resize: vertical;
}
*/
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input[type="search"] {
    box-sizing: content-box;
}
input[type="search"]:focus {
    outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

/* 色 */
/*----------------------------------------------------------------------------*/
/* 共通の色 */

/* 文字の色 */

/* 背景の色 */

/* 線の色 */

/* アラート色 */

/* フォーム */

/* モジュールの色 */

/* 文字サイズ */
/*----------------------------------------------------------------------------*/



/* px数 (変数化しすぎると逆に大変なので極力増やさない) */
/*----------------------------------------------------------------------------*/
/* ヘッダーの高さ */

/* 影 */

/* 行間 */
/* $line-height-text: 1.75; */
/* $line-height-title: 1.5; */

/* フォント */

/* 「03-mixin.css」を変更したい場合は、「03-mixin-pre-consolidate.css」を変更 */
/*----------------------------------------------------------------------------*/

@font-face {
    font-family: "original-icon-font";
    src: url('../font/original-icon-font.eot');
    src: url('../font/original-icon-font.eot?#iefix') format('eot'),
        url('../font/original-icon-font.woff2') format('woff2'),
        url('../font/original-icon-font.woff') format('woff'),
        url('../font/original-icon-font.ttf') format('truetype'),
        url('../font/original-icon-font.svg#original-icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon::before {
	display: inline-block;
	font-family: "original-icon-font";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: baseline;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* ズレ補正 */
.icon {
    /*
    vertical-align: middle;
    font-size: 110%;
    letter-spacing: 0;
    text-indent: 0;
    */
}
.icon::before {
    /*
    margin: -0.25rem 0 0 0;
    display: inline-block;
    */
}
.icon-all::before { content: "\EA01"; }
.icon-arrow-simple::before { content: "\EA02"; }
.icon-arrow::before { content: "\EA03"; }
.icon-art::before { content: "\EA04"; }
.icon-asc::before { content: "\EA05"; }
.icon-calender::before { content: "\EA06"; }
.icon-checkbox-checked-fill::before { content: "\EA07"; }
.icon-checkbox-checked::before { content: "\EA08"; }
.icon-checkbox-round-checked-02-fill::before { content: "\EA09"; }
.icon-checkbox-round-checked-02::before { content: "\EA0A"; }
.icon-checkbox-round-checked-fill::before { content: "\EA0B"; }
.icon-checkbox-round-checked::before { content: "\EA0C"; }
.icon-checkbox-round::before { content: "\EA0D"; }
.icon-checkbox::before { content: "\EA0E"; }
.icon-close::before { content: "\EA0F"; }
.icon-desc::before { content: "\EA10"; }
.icon-email::before { content: "\EA11"; }
.icon-event::before { content: "\EA12"; }
.icon-lecture-course::before { content: "\EA13"; }
.icon-list::before { content: "\EA14"; }
.icon-map::before { content: "\EA15"; }
.icon-media::before { content: "\EA16"; }
.icon-more::before { content: "\EA17"; }
.icon-movie::before { content: "\EA18"; }
.icon-musics::before { content: "\EA19"; }
.icon-outlink-02::before { content: "\EA1A"; }
.icon-outlink::before { content: "\EA1B"; }
.icon-radio-checked-fill::before { content: "\EA1C"; }
.icon-radio-checked::before { content: "\EA1D"; }
.icon-radio::before { content: "\EA1E"; }
.icon-search::before { content: "\EA1F"; }
.icon-sport::before { content: "\EA20"; }
.icon-tea-flower::before { content: "\EA21"; }
.icon-tel::before { content: "\EA22"; }
.icon-theater::before { content: "\EA23"; }
.icon-time::before { content: "\EA24"; }
.icon-top::before { content: "\EA25"; }
.icon-traditional-culture::before { content: "\EA26"; }
.logo-sp::before { content: "\EA27"; }

@charset "UTF-8";

/* ページャー */
/*---------------------------------------------------*/
/*
.pager li {
    padding: 0 0.5em;
}
.pager a,
.pager a:hover,
.pager span {
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #111111;
}
.pager .current {
    background: #dddddd;
}
.pager a:hover {
    background: #111111;
    color: #ffffff;
}
*/



/* toggle / tab / popup */
/*---------------------------------------------------*/
/*
.toggle-button,
.parent-tab-button a,
.popup-button a {
    // padding: 15px 20px;
    // border: 1px solid #333333;
    font-weight: normal;
}
.toggle-button,
.popup-button a {
    background-color: transparent;
    color: #333333;
}
.toggle-button.active,
.toggle-button:hover,
.parent-tab-button li a,
.popup-button a:hover {
    // background-color: #333333;
    // color: #ffffff;
    color: #333333;
}
.parent-tab-button li a:hover {
    opacity: 0.7;
}
.toggle-button.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.toggle-content,
.parent-tab-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.parent-tab-button li a {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.parent-tab-button li:first-child a {
    border-top-left-radius: 3px;
    border-left-width: 1px;
}
.parent-tab-button li:last-child a {
    border-top-right-radius: 3px;
    border-right-width: 1px;
}
.parent-tab-content {
    border: 1px solid #333333;
    border-top: none;
}
.parent-tab-button li.current a {
    color: #333333;
    background-color: transparent;
}
.parent-popup-content .container {
    // width: 770px;
}
.popup-content > .panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}
.popup-content {
    opacity: 0.0;
}
.popup-content.active {
    opacity: 1.0;
}
.popup-content {
    transition: opacity 0.25s ease-in-out 0.4s;
}
.popup-button-close {
    width: 30px;
    height: 30px;
    background: url(../img/icon-close-big.png) no-repeat center center;
    background-size: 30px 30px;
    top: 10px;
    right: 10px;
}
.popup-button-close:hover {
    opacity: 0.35;
}
*/




/* thumbnailボタン */
/*---------------------------------------------------*/
a .thumbnail img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
a:hover .thumbnail img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
/*
@media only screen and (min-width: 480px) {
    // input[type="submit"],
    // input[type="button"],
    // input[type="reset"],
    // button,
    // .button a {
    //     width: auto
    // }
}

@media only screen and (min-width: 770px) {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="number"],
    input[type="file"],
    input[type="search"],
    select,
    body select[size="1"],
    select[multiple],
    option,
    textarea {
        padding: 20px;
        // border: 2px solid #eeeeee;
    }

    select[size],
    select[multiple],
    select:not([size]) {
        padding: 20px;
    }

    // input[type="button"],
    // input[type="reset"],
    // input[type="submit"],
    // button,
    // .button > a,
    // .button > span:not(.icon),
    // a.button {
    //     padding: 0.5em 1em;
    //     font-size: 16px;
    // }
}
*/


/* pagerボタン */
/*---------------------------------------------------*/
.pager ul,
.pager ol,
ul.pager ,
ol.pager {
    margin: 0 auto;
    padding: 0;
    display: table;
    border-collapse: separate;
    border-spacing: 1px 0;
}
.pager li,
.pager li {
    display: table-cell;
}
.pager li a,
.pager li span {
/*
    display: table-cell;
    text-align: center;
    vertical-align: middle;
*/
}
.pager li a,
.pager li span {
    min-width: 2em;
    min-height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pager .first a,
.pager .prev a,
.pager .next a,
.pager .last a {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
}

/* WordPressの管理者用ヘッダー */
body.logged-in #wpadminbar {
        position: fixed;
        top: 0;
    }
body.logged-in header {
        /* top: 46px; */
        top: calc(46px + 10px);
    }
body.logged-in header .global-nav nav {
            top: calc(46px + 50px);
        }
body.logged-in header .drawer-nav {
            padding-top: 46px;
        }
body.logged-in .alert-group {
        top: calc(46px + 50px + 5px);
    }

@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 783px) {
        body.logged-in header {
            /* top: 32px; */
            top: calc(32px + 10px);
        }
            body.logged-in header .global-nav nav {
                top: calc(32px + 50px);
            }
            body.logged-in header .drawer-nav {
                padding-top: 32px;
            }
        body.logged-in .alert-group {
            top: calc(32px + 50px + 5px);
        }

}
@media only screen and (min-width: 1000px) {
    /* body.customize-support header, */
        body.logged-in header .global-nav nav {
            top: auto;
        }
        body.logged-in .alert-group {
            top: calc(32px + 100px + 5px);
        }
}

/* WordPress管理画面対応 */
/*
html.global-nav-active {
    body.customize-support .toggle-content {
        max-height: calc(100vh - 46px - 70px);

        @media only screen and (min-width: 783px) {
            max-height: calc(100vh - 32px - 70px);
        }
    }
}
*/

/* WordPress */
.mw_wp_form form .error {
        padding: 5px 10px;
        background-color: #ee9999;
        color: #ffffff;
        font-size: 11.16px;
        line-height: 24px;
        margin-top: 0;
    }

/** VARIABLES
===================================*/
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  *zoom: 1;
  touch-action: pan-y;
}
.bx-wrapper img {
  max-width: 100%;
  display: block;
}
.bxslider {
  margin: 0;
  padding: 0;
}
ul.bxslider {
  list-style: none;
}
.bx-viewport {
  /* fix other elements on the page moving (in Chrome) */
  -webkit-transform: translateZ(0);
}
/** THEME
===================================*/
.bx-wrapper {
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  background: #fff;
}
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url('images/bx_loader.gif') center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}
.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  border-radius: 5px;
}
.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: #000;
}
.bx-wrapper .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  vertical-align: bottom;
  *zoom: 1;
  *display: inline;
}
.bx-wrapper .bx-pager-item {
  font-size: 0;
  line-height: 0;
}
/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url('images/controls.png') no-repeat 0 -32px;
}
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus {
  background-position: 0 0;
}
.bx-wrapper .bx-next {
  right: 10px;
  background: url('images/controls.png') no-repeat -43px -32px;
}
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
  background-position: -43px 0;
}
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}
.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}
/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}
/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}
.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/
.bx-wrapper .bx-loading {
    background: url(../img/common/loading.png) center center no-repeat;
}
.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
    background: none;
    display: block;
    position: relative;
}
.bx-wrapper .bx-prev::before,
.bx-wrapper .bx-next::before {
    content: "\e200";
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 12px;
    font-size: 1rem;
    font-family: 'icon','icon-sns' !important;
    color: #fff;
    text-indent: 0;
    background: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.bx-wrapper .bx-prev::before {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.bx-wrapper .bx-next::before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
/*
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
*/

/* .bxsliderを読み込むまでは表示しない */
/*----------------------------------------------------------------------------------*/
.bxslider {
    list-style: none;
    display: block;
}
.bxslider > li {
        list-style: none;
        display: block;
    }
.bx-wrapper .bxslider {
    display: block;
}

/* 余白や装飾の初期化 */
/*----------------------------------------------------------------------------------*/
.bxslider li, .bx-wrapper .bx-viewport li {
        margin: 0;
    }
.bx-wrapper {
    /* initialはIEでは使用不可 */
    /* border: none; */
    /* box-shadow: none; */
    /* background: none; */
}
.bxslider *:not(.thumbnail) > img {
    min-width: 100%;
    min-height: 100%;
}

/* PCで画面を触ると都合が悪いので、マスクを表示 */
/*----------------------------------------------------------------------------------*/

/* マスク */
.bx-wrapper::before {
    z-index: 2000;
}
.bx-viewport {
    z-index: 1000;
}
.bx-controls {
    z-index: 8000;
    position: relative;
}

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {
    /* マスク */
    .bx-wrapper::before {
        content: "";
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
@media only screen and (min-width: 1000px) {

}
@media only screen and (min-width: 1300px) {

}

.xdsoft_datetimepicker {
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #fff;
	border-bottom: 1px solid #bbb;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-top: 1px solid #ccc;
	color: #333;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	padding: 8px;
	padding-left: 0;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	box-sizing: border-box;
	display: none;
}
.xdsoft_datetimepicker.xdsoft_rtl {
	padding: 8px 0 8px 8px;
}

.xdsoft_datetimepicker iframe {
	position: absolute;
	left: 0;
	top: 0;
	width: 75px;
	height: 210px;
	background: transparent;
	border: none;
}

/*For IE8 or lower*/
.xdsoft_datetimepicker button {
	border: none !important;
}

.xdsoft_noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_noselect::selection { background: transparent }
.xdsoft_noselect::-moz-selection { background: transparent }

.xdsoft_datetimepicker.xdsoft_inline {
	display: inline-block;
	position: static;
	box-shadow: none;
}

.xdsoft_datetimepicker * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
	display: none;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
	display: block;
}

.xdsoft_datetimepicker .xdsoft_datepicker {
	width: 224px;
	float: left;
	margin-left: 8px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
	width: 256px;
}

.xdsoft_datetimepicker .xdsoft_timepicker {
	width: 58px;
	float: left;
	text-align: center;
	margin-left: 8px;
	margin-top: 0;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
	float: right;
	margin-right: 8px;
	margin-left: 0;
}

.xdsoft_datetimepicker .xdsoft_datepicker.active+.xdsoft_timepicker {
	margin-top: 8px;
	margin-bottom: 3px
}

.xdsoft_datetimepicker .xdsoft_monthpicker {
	position: relative;
	text-align: center;
}

.xdsoft_datetimepicker .xdsoft_label i,
.xdsoft_datetimepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC);
}

.xdsoft_datetimepicker .xdsoft_label i {
	opacity: 0.5;
	background-position: -92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker .xdsoft_prev {
	float: left;
	background-position: -20px 0;
}
.xdsoft_datetimepicker .xdsoft_today_button {
	float: left;
	background-position: -70px 0;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_next {
	float: right;
	background-position: 0 0;
}

.xdsoft_datetimepicker .xdsoft_next,
.xdsoft_datetimepicker .xdsoft_prev ,
.xdsoft_datetimepicker .xdsoft_today_button {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0 none;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	outline: medium none;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
	min-width: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
	float: none;
	background-position: -40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left: 14px;
	margin-top: 7px;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
	float: none;
	margin-left: 0;
	margin-right: 14px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
	background-position: -40px 0;
	margin-bottom: 7px;
	margin-top: 0;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
	height: 151px;
	overflow: hidden;
	border-bottom: 1px solid #ddd;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #f5f5f5;
	border-top: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: center;
	border-collapse: collapse;
	cursor: pointer;
	border-bottom-width: 0;
	height: 25px;
	line-height: 25px;
}

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div > div:first-child {
	border-top-width: 0;
}

.xdsoft_datetimepicker .xdsoft_today_button:hover,
.xdsoft_datetimepicker .xdsoft_next:hover,
.xdsoft_datetimepicker .xdsoft_prev:hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.xdsoft_datetimepicker .xdsoft_label {
	display: inline;
	position: relative;
	z-index: 9999;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff;
	float: left;
	width: 182px;
	text-align: center;
	cursor: pointer;
}

.xdsoft_datetimepicker .xdsoft_label:hover>span {
	text-decoration: underline;
}

.xdsoft_datetimepicker .xdsoft_label:hover i {
	opacity: 1.0;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
	border: 1px solid #ccc;
	position: absolute;
	right: 0;
	top: 30px;
	z-index: 101;
	display: none;
	background: #fff;
	max-height: 160px;
	overflow-y: hidden;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect{ right: -7px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect{ right: 2px }
.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #fff;
	background: #ff8000;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
	padding: 2px 10px 2px 5px;
	text-decoration: none !important;
}

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_month {
	width: 100px;
	text-align: right;
}

.xdsoft_datetimepicker .xdsoft_calendar {
	clear: both;
}

.xdsoft_datetimepicker .xdsoft_year{
	width: 48px;
	margin-left: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
	border-collapse: collapse;
	width: 100%;

}

.xdsoft_datetimepicker .xdsoft_calendar td > div {
	padding-right: 5px;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	height: 25px;
}

.xdsoft_datetimepicker .xdsoft_calendar td,.xdsoft_datetimepicker .xdsoft_calendar th {
	width: 14.2857142%;
	background: #f5f5f5;
	border: 1px solid #ddd;
	color: #666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding: 0;
	border-collapse: collapse;
	cursor: pointer;
	height: 25px;
}
.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td,.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
	width: 12.5%;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	background: #f1f1f1;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
	color: #33aaff;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color: #000;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #33aaff;
	box-shadow: #178fe5 0 1px 3px 0 inset;
	color: #fff;
	font-weight: 700;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker .xdsoft_time_box >div >div.xdsoft_disabled {
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
	opacity: 0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #fff !important;
	background: #ff8000 !important;
	box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current.xdsoft_disabled:hover {
	background: #33aaff !important;
	box-shadow: #178fe5 0 1px 3px 0 inset !important;
	color: #fff !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_disabled:hover {
	color: inherit	!important;
	background: inherit !important;
	box-shadow: inherit !important;
}

.xdsoft_datetimepicker .xdsoft_calendar th {
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor: default;
}

.xdsoft_datetimepicker .xdsoft_copyright {
	color: #ccc !important;
	font-size: 10px;
	clear: both;
	float: none;
	margin-left: 8px;
}

.xdsoft_datetimepicker .xdsoft_copyright a { color: #eee !important }
.xdsoft_datetimepicker .xdsoft_copyright a:hover { color: #aaa !important }

.xdsoft_time_box {
	position: relative;
	border: 1px solid #ccc;
}
.xdsoft_scrollbar >.xdsoft_scroller {
	background: #ccc !important;
	height: 20px;
	border-radius: 3px;
}
.xdsoft_scrollbar {
	position: absolute;
	width: 7px;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}
.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
	left: 0;
	right: auto;
}
.xdsoft_scroller_box {
	position: relative;
}

.xdsoft_datetimepicker.xdsoft_dark {
	box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
	background: #000;
	border-bottom: 1px solid #444;
	border-left: 1px solid #333;
	border-right: 1px solid #333;
	border-top: 1px solid #333;
	color: #ccc;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
	border-bottom: 1px solid #222;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: #0a0a0a;
	border-top: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
	background-color: #000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
	border: 1px solid #333;
	background: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
	color: #000;
	background: #007fff;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_next,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==);
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0a0a0a;
	border: 1px solid #222;
	color: #999;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	background: #0e0e0e;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
	color: #cc5500;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
	background: #ffe9d2;
	box-shadow: #ffb871 0 1px 4px 0 inset;
	color:#000;
}
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
	background: #c1ffc9;
	box-shadow: #00dd1c 0 1px 4px 0 inset;
	color:#000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: #cc5500;
	box-shadow: #b03e00 0 1px 3px 0 inset;
	color: #000;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover,
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
	color: #000 !important;
	background: #007fff !important;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
	color: #666;
}

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright { color: #333 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a { color: #111 !important }
.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover { color: #555 !important }

.xdsoft_dark .xdsoft_time_box {
	border: 1px solid #333;
}

.xdsoft_dark .xdsoft_scrollbar >.xdsoft_scroller {
	background: #333 !important;
}
.xdsoft_datetimepicker .xdsoft_save_selected {
    display: block;
    border: 1px solid #dddddd !important;
    margin-top: 5px;
    width: 100%;
    color: #454551;
    font-size: 13px;
}
.xdsoft_datetimepicker .blue-gradient-button {
	font-family: "museo-sans", "Book Antiqua", sans-serif;
	font-size: 12px;
	font-weight: 300;
	color: #82878c;
	height: 28px;
	position: relative;
	padding: 4px 17px 4px 33px;
	border: 1px solid #d7d8da;
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: linear-gradient(to bottom, #fff 0%, #f4f8fa 73%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f4f8fa',GradientType=0 );
/* IE6-9 */
}
.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #454551;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #f4f8fa 0%, #FFF 73%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f8fa', endColorstr='#FFF',GradientType=0 );
  /* IE6-9 */
}

/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/



/*
body .xdsoft_datetimepicker .xdsoft_next {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(90deg);
    }
}

body .xdsoft_datetimepicker .xdsoft_prev {
    background: none;
    display: block;

    &::before {
        content: "\e200";
        width: 100px;
        height: 100px;
        color: $color-text;
        line-height: 1;
        font-size: 1rem;
        font-family: 'icon','icon-sns' !important;
        transform: translate(0, 0.05em) rotate(-90deg);
    }
}
*/
body .xdsoft_datetimepicker .xdsoft_today_button {
    display: none;
}

.datetimepicker-date {
    padding-right: 32px;
    background-image: url(../img/common/icon-calender-margin.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 32px 16px;
    cursor: pointer;
}
body .xdsoft_calendar table {
            display: table;
        }
body .xdsoft_calendar table thead {
                display: table-header-group;
            }
body .xdsoft_calendar table tbody {
                display: table-row-group;
            }
body .xdsoft_calendar table tfoot {
                display: table-footer-group;
            }
body .xdsoft_calendar table tr {
                display: table-row;
            }
body .xdsoft_calendar table th,
            body .xdsoft_calendar table td {
                display: table-cell;
            }
/*
    .xdsoft_datetimepicker,
    .xdsoft_noselect {
        display: block !important;
    }
    */
body .xdsoft_datetimepicker {
        padding: 10px;
    }
body .xdsoft_datetimepicker .xdsoft_datepicker {
    	width: 260px;
    	float: none;
    	/*\/ margin-left: 8px; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
    	float: none;
    	/* margin-right: 8px; */
    	/* margin-left: 0; */
        margin: 0;
    }
body .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
    	width: 256px;
    }
body .xdsoft_datetimepicker {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
body .xdsoft_datetimepicker .xdsoft_year {
        width: 60px;
    }
/* マス */
body .xdsoft_datetimepicker .xdsoft_calendar table,
    body .xdsoft_datetimepicker .xdsoft_calendar thead,
    body .xdsoft_datetimepicker .xdsoft_calendar tbody,
    body .xdsoft_datetimepicker .xdsoft_calendar tfoot {
        border-left: none;
        border-right: none;
    }
/*
    .xdsoft_datetimepicker .xdsoft_label i,
    .xdsoft_datetimepicker .xdsoft_next,
    .xdsoft_datetimepicker .xdsoft_prev,
    .xdsoft_datetimepicker .xdsoft_today_button {
        background: none;

        &::before {
            content: url(../img/common/icon-arrow-simple.svg);
            display: block;
        }
    }
    */
body .xdsoft_datetimepicker .xdsoft_calendar td,
    body .xdsoft_datetimepicker .xdsoft_calendar th {
        color: #222;
        border: 1px solid #000000;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left: none;
        border-right: none;
    }
/* 見出し */
body .xdsoft_datetimepicker .xdsoft_calendar th {
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        background: none;
        height: auto;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td {
        height: 38px;
        text-align: center;
    }
body .xdsoft_datetimepicker .xdsoft_calendar td > div {
        padding: 0;
    }
/* 今日 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
        background-color: #fff !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 0.25;
    }
/* 選択中 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
        background-color: #03a9f4 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* ホバー */
body .xdsoft_datetimepicker .xdsoft_calendar td:hover,
    body .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
        background-color: #76cef9 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/* 前月、翌月 */
body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled,
    body .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month,
    body .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
        opacity: 0.25;
    }
/* select */
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
        background-color: #03a9f4 !important;
        color: #fff !important;
        box-shadow: none !important;
    }
body .xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
        background-color: #76cef9 !important;
        color: #fff !important;
        box-shadow: none !important;
        opacity: 1.0;
    }
/*
select,
body select[size="1"] {

    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1;

    position: relative;
}
*/

/* 便利なCSSを記述 */
/*----------------------------------------------------------------------------*/
:first-child {
    margin-top: 0;
}
:last-child {
    margin-bottom: 0;
}

/* clearfix、回り込み */
/*---------------------------------------------------*/
/*
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float-left { float: left; }
.float-right { float: right; }
.clear { clear: both; }
*/

/* ブロック */
/*---------------------------------------------------*/
.d-block { display: block; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-table { display: table; }
.d-table-cell { display: table-cell; }
.d-flex { display: flex; }

/* 余白 */
/*---------------------------------------------------*/
.mt-auto { margin-top: auto !important; }
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-200 { margin-top: 200px !important; }
.mt-250 { margin-top: 250px !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-25 { margin-bottom: 25px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.mb-250 { margin-bottom: 250px !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-30 { margin-left: 30px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-50 { margin-left: 50px !important; }
.ml-60 { margin-left: 60px !important; }
.ml-70 { margin-left: 70px !important; }
.ml-80 { margin-left: 80px !important; }
.ml-90 { margin-left: 90px !important; }
.ml-100 { margin-left: 100px !important; }
.ml-110 { margin-left: 110px !important; }
.ml-120 { margin-left: 120px !important; }
.ml-130 { margin-left: 130px !important; }
.ml-140 { margin-left: 140px !important; }
.ml-150 { margin-left: 150px !important; }
.ml-200 { margin-left: 200px !important; }
.ml-250 { margin-left: 250px !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-50 { margin-right: 50px !important; }
.mr-60 { margin-right: 60px !important; }
.mr-70 { margin-right: 70px !important; }
.mr-80 { margin-right: 80px !important; }
.mr-90 { margin-right: 90px !important; }
.mr-100 { margin-right: 100px !important; }
.mr-110 { margin-right: 110px !important; }
.mr-120 { margin-right: 120px !important; }
.mr-130 { margin-right: 130px !important; }
.mr-140 { margin-right: 140px !important; }
.mr-150 { margin-right: 150px !important; }
.mr-200 { margin-right: 200px !important; }
.mr-250 { margin-right: 250px !important; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-110 { padding-top: 110px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-130 { padding-top: 130px !important; }
.pt-140 { padding-top: 140px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pt-250 { padding-top: 250px !important; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-110 { padding-bottom: 110px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-130 { padding-bottom: 130px !important; }
.pb-140 { padding-bottom: 140px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.pb-250 { padding-bottom: 250px !important; }

.pl-0 { padding-left: 0px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-40 { padding-left: 40px !important; }
.pl-50 { padding-left: 50px !important; }
.pl-60 { padding-left: 60px !important; }
.pl-70 { padding-left: 70px !important; }
.pl-80 { padding-left: 80px !important; }
.pl-90 { padding-left: 90px !important; }
.pl-100 { padding-left: 100px !important; }
.pl-110 { padding-left: 110px !important; }
.pl-120 { padding-left: 120px !important; }
.pl-130 { padding-left: 130px !important; }
.pl-140 { padding-left: 140px !important; }
.pl-150 { padding-left: 150px !important; }
.pl-200 { padding-left: 200px !important; }
.pl-250 { padding-left: 250px !important; }

.pr-0 { padding-right: 0px !important; }
.pr-5 { padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-20 { padding-right: 20px !important; }
.pr-30 { padding-right: 30px !important; }
.pr-40 { padding-right: 40px !important; }
.pr-50 { padding-right: 50px !important; }
.pr-60 { padding-right: 60px !important; }
.pr-70 { padding-right: 70px !important; }
.pr-80 { padding-right: 80px !important; }
.pr-90 { padding-right: 90px !important; }
.pr-100 { padding-right: 100px !important; }
.pr-110 { padding-right: 110px !important; }
.pr-120 { padding-right: 120px !important; }
.pr-130 { padding-right: 130px !important; }
.pr-140 { padding-right: 140px !important; }
.pr-150 { padding-right: 150px !important; }
.pr-200 { padding-right: 200px !important; }
.pr-250 { padding-right: 250px !important; }

/* 幅 */
/*---------------------------------------------------*/
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-65 { width: 65%; }
.w-70 { width: 70%; }
.w-75 { width: 75%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }


/* HTMLで改行した場合に生じる左右の余白を消す */
/*---------------------------------------------------*/
.parent-inline-block {
    letter-spacing: -1em;
    word-spacing: -1em;
}
:root .parent-inline-block {
    font-size: 0 !important;
    letter-spacing: -1px;
}
.parent-inline-block > * {
    font-size: 12px;
    font-size: 1rem;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}


/* 動画 */
/*---------------------------------------------------*/
.parent-youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.parent-youtube iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/* その他 */
/*---------------------------------------------------*/
/* いいねボタンの横幅が途切れてしまう現象への対策 */
.fb-like iframe {
    max-width: none !important;
}


/* 画面幅によって非表示 */
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 0px) and (max-width: 479px) {
    .only-pc { display: none !important; }
    .only-tab { display: none !important; }
    .only-sp {  }

    .over-pc { display: none !important; }
    .over-tab { display: none !important; }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp {  }
}
@media only screen and (min-width: 480px) and (max-width: 769px) {
    .only-pc { display: none !important; }
    .only-tab {  }
    .only-sp { display: none !important; }

    .over-pc { display: none !important; }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab {  }
    .under-sp { display: none !important; }
}
@media only screen and (min-width: 770px) {
    .only-pc {  }
    .only-tab { display: none !important; }
    .only-sp { display: none !important; }

    .over-pc {  }
    .over-tab {  }
    .over-sp {  }

    .under-pc {  }
    .under-tab { display: none !important; }
    .under-sp { display: none !important; }
}

html {
    font-size: 12px;
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
    color: #222;
}

@media only screen and (min-width: 770px) {

html {
        font-size: 14px
}
    }

p {
    line-height: 1.5;
}

.text {
    line-height: 1.5;
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    color: #03a9f4;
    text-decoration: none;
}
a:hover,
a:active {
    color: #76cef9;
    text-decoration: none;
}

hr {
    margin-bottom: 30px;
    height: 1px;
    border: none;
    border-bottom: 1px solid #8d8d8d;
}

hr.hr-dotted {
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none!important;
        height: 2px;
        margin: 0 0 40px 0;
    }


/*----------------------------------------------------------------------------*/
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.vertical-top { vertical-align: top !important; }
.vertical-middle { vertical-align: middle !important; }
.vertical-bottom { vertical-align: bottom !important; }
.vertical-baseline { vertical-align: baseline !important; }
.text-notmal { font-weight: normal; }
.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }
.text-oblique { font-style: oblique; }
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-nowrap { white-space: nowrap; }

/* 文字サイズ */
.text-md {
    font-size: 12px;
    font-weight: normal;
}
.text-sm {
    font-size: 10px;
    font-weight: normal;
}

/* 注釈 */
.text-note {
    display: block;
    line-height: 1.5;
    font-weight: normal;
}

/* バリデーション */
.invalid-feedback {
    font-size: 0.8em;
    color: #ec5858;
    display: block;
}
.valid-feedback {
    font-size: 0.8em;
    color: #6bc55d;
    display: block;
}

.text-base {
    color: #222;
}
strong {
    font-weight: bold;
}

h2, h3, h4, h5, h6 {
    font-weight: bold;
    line-height: calc(1em + 10px);
    margin-top: 2em;
}

h2 {
    font-size: 16px;
}

@media only screen and (min-width: 1000px) {

h2 {
        font-size: 24px
}
    }

h2.h2-01 {
        margin-bottom: 20px;
        color: #03a9f4;
        text-align: center;
    }

@media only screen and (min-width: 1000px) {

h2.h2-01 {
            margin-bottom: 40px;
            color: #03a9f4;
            text-align: center
    }
        }

h2.h2-02 {

    }

h2.h2-03 {
        margin: 0;
        line-height: 1.3;
        margin-bottom: 10px;
    }

h2.h2-04 {
        background-color: #03a9f4;
        font-size: 20px;
        margin-bottom: 0;
        padding: 10px 0;
        color: #fff;
        text-align: center;
    }

h2.h2-04:before {
            display: none;
        }


@media only screen and (min-width: 1000px) {


h3 {
        font-size: 20px
}
    }


h3.h3-01 {
        font-size: 16px;
        margin-bottom:  20px;
        padding: 8px 0 8px 14px;
        position: relative;
        line-height: 1;
        vertical-align: middle;
    }


@media only screen and (min-width: 1000px) {


h3.h3-01 {
            font-size: 20px
    }
        }


h3.h3-01:before {
            position: absolute;
            content: "";
            background: #81d4fa;
            left: 0;
            top: 0;
            width: 6px;
            height: 30px;
            border-radius: 2px;
        }


@media only screen and (min-width: 1000px) {


h3.h3-01:before {
                height: 36px
        }
            }


h4 {
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.4;
}


@media only screen and (min-width: 1000px) {


h4 {
        font-size: 12px;
        margin-top: 10px
}
    }


h5 { font-size: 16px; }

h6 { font-size: 16px; }

.title {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.title span {
        display: inline-block;
    }
ul,
ol {
    line-height: 1.5;
    list-style-position: outside;
}
ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }
.title + ul, .title + ol {
        margin-top: 0;
    }

li:first-child {
        margin-top: 0;
    }

li:last-child {
        margin-bottom: 0;
    }

li li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

li li:first-child {
            margin-top: 0;
        }

li li:last-child {
            margin-bottom: 0;
        }

li > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }

ul > li {
        /*list-style-type: disc; */
    }

ul ul > li {
            list-style-type: circle;
        }

ul.sns-btn-list {
        display: flex;
        justify-content: center;
    }

ul.sns-btn-list li:not(:first-child) {
            margin-left :10px;
        }

ul.related-event-list {
        margin: -10px;
    }

ul.related-event-list li {
            margin: 10px;
            font-size: 10px;
        }

ul.related-event-list li a {
                height: 100%;
                color: #222;
                display: flex;
                position: relative;
                /* 日付を下に揃えるための余白 */
                padding-bottom: calc(1em + 10px);
                box-sizing: border-box;
            }

ul.related-event-list li a .thumbnail {
                    flex: 0 0 130px;
                    width: 130px;
                    height: 130px;
                    margin-right: 10px;
                }

ul.related-event-list li a .detail {
                    flex: 1 1 auto;
                }

ul.related-event-list li a .detail .event-term {
                        position: absolute;
                        bottom: 20px;
                    }

ul.related-event-list li a:hover {
                    color: #76cef9;
                }

@media only screen and (min-width: 770px) {

ul.related-event-list {
            display: flex
    }
            ul.related-event-list li {
                width: 130px;
            }
                ul.related-event-list li a {
                    display: block;
                }
                    ul.related-event-list li a .thumbnail {
                        margin-bottom: 15px;
                        width: auto;
                        height: 135px;
                        margin-right: 0;
                    }
                        ul.related-event-list li a .detail .event-term {
                            bottom: 0;
                        }
        }

ul.image-list {
        margin-bottom: 20px;
        display: flex;
        margin-left: -10px;
    }

ul.image-list li {
            width: 20%;
            margin-left: 10px;
        }

ul.image-list li a {
                background-color: #eee;
                width: 100%;
                height: 100px;
                overflow: hidden;
            }

@media only screen and (min-width: 1000px) {

            ul ul > li {
                list-style-type: circle;
            }

            ul ul.related-event-list {

                display: flex;
            }
                ul ul.related-event-list li {
                    font-size: 10px;
                }

                ul ul.related-event-list li:not(:first-child) {
                    margin-left: 20px;
                }

                    ul ul.image-list li a {
                        width: 106px;
                        height: 106px;
                    }
    }
ol > li {
        list-style-type: decimal;
    }
ol ol {
        padding-left: 0;
    }
ol ol > li {
            /* list-style-type: decimal; */
            list-style-type: none;
            counter-increment: cnt;
            display: flex;
        }
ol ol > li::before {
                display: marker;
                content: "(" counter(cnt) ") ";
                margin-right: 0.5em;
            }
ol.ol-01 {
        margin-top: 10px;
    }
ol.ol-01 > li {
            list-style-type: none;
            text-indent: -1em;
            padding-left: 1em;
        }

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;
}
.list-note > li {
        list-style-type: none;
    }
.list-note > li::before {
        display: marker;
        content: "※";
        margin-left: -1.5em;
        margin-right: 0.5em;
        width: 1em;
    }

.slash-list {
    margin-top: -5px;
}

.slash-list li {
        display: inline-block;
        margin-top: 5px;
    }

.slash-list li::before {
            content: "/";
            display: inline-block;
            margin: 0 10px;
        }

.slash-list li:first-child::before {
            content: "";
            margin: 0;
        }


.hiragana-list {
    margin-bottom: 40px;
    background-color: #faf6e9;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
}


.hiragana-list ul {
        flex-basis: calc(100% / 2);
        display: flex;
        align-items: center;
        margin-top: 20px;
    }


.hiragana-list ul li {
            font-size: 16px;
            margin-right: 10px;
        }


.hiragana-list ul li:first-child {
                font-size: 20px;
                font-weight: bold;
            }


.hiragana-list ul li a {
                color: #222;
            }


.hiragana-list ul li a:hover {
                    color: #03a9f4;
                }


@media only screen and (min-width: 770px) {
        .hiragana-list ul {
            flex-basis: calc(100% / 3);
        }
    }


@media only screen and (min-width: 1000px) {


.hiragana-list {
        padding: 20px 40px 40px;
        margin-bottom: 40px
}
    }



.municipality-list {
    background-color: #faf6e9;
    padding: 20px;
    margin-bottom: 40px;
}



.municipality-list ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: -15px;
    }



.municipality-list ul li {
            flex-basis: calc(100% / 2);
            font-size: 14px;
            font-weight: bold;
            margin-top: 15px;
            display: flex;
            align-items: center;
        }



.municipality-list ul li::before {
                display: inline-block;
                font-family: "original-icon-font";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: baseline;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\EA10";
                color: #03a9f4;
                font-size: 14px;
                margin-right: 5px;
            }



.municipality-list ul li a {
                color: #222;
            }



.municipality-list ul li a:hover {
                    color: #03a9f4;
                }



@media only screen and (min-width: 770px) {
            .municipality-list ul li {
                flex-basis: calc(100% / 4);
                font-size: 18px;
            }
    }



@media only screen and (min-width: 1000px) {



.municipality-list {
        padding: 40px;
        margin-bottom: 40px
}
    }

/* テーブル全般 */
/*----------------------------------------------------------------------------*/
.page-template table tbody th:first-child {
            width: 190px;
        }
table {
    /* margin-top: 3em; */
    /* margin-bottom: 3em; */
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    line-height: calc(1em + 10px);
}
table th,
    table td {
        padding: 10px;
        font-size: 12px;
    }
table th img,
        table th iframe,
        table td img,
        table td iframe {
            vertical-align: top;
        }
table th {
        text-align: left;
        vertical-align: middle;
        font-weight: bold;
        white-space: nowrap;
        padding-bottom: 0 !important;
    }
table td {
        padding-top: 0 !important;
        /*padding-bottom: 0 !important; */
    }
table,
    table thead,
    table tbody,
    table tfoot,
    table tr,
    table th,
    table td {
        border: 1px none #000000;
        border: 1px none rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
    }
table caption {
        text-align: left;
        font-weight: bold;
        margin-bottom: 0.75em;
    }
@media only screen and (min-width: 480px) {
            table tbody th:first-child {
                width: 90px;
            }
    }
@media only screen and (min-width: 660px) {
        table th,
        table td {
            padding: 15px !important;
        }
    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {
        table th,
        table td {
            padding: 15px 10px;
        }
            table th img,
            table th iframe,
            table td img,
            table td iframe {
                vertical-align: top;
            }

    }

.table-sm {
    font-size: 0.85em;
}

.table-sm th,
    .table-sm td {
        padding: 0.5em;
    }
.table-lg {
    font-size: 1.2em;
}
.table-lg th,
    .table-lg td {
        padding: 10px 15px;
    }
.table-fixed {
    table-layout: fixed;
}


/* テーブル (横線) */
/*----------------------------------------------------------------------------*/
table,
    table thead,
    table tbody,
    table tfoot,
    table tr,
    table th,
    table td,
    table.table-border-horizontal,
    table.table-border-horizontal thead,
    table.table-border-horizontal tbody,
    table.table-border-horizontal tfoot,
    table.table-border-horizontal tr,
    table.table-border-horizontal th,
    table.table-border-horizontal td {
        border-top-style: solid;
        border-bottom-style: solid;
        word-break : break-all;
    }
@media only screen and (min-width: 0px) and (max-width: 769px) {

    }
@media only screen and (min-width: 770px) {
                table:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) th:first-child, table:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) td:first-child, table.table-border-horizontal:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) th:first-child, table.table-border-horizontal:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) td:first-child {
                    padding-left: 10px;
                }
                table:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) th:last-child, table:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) td:last-child, table.table-border-horizontal:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) th:last-child, table.table-border-horizontal:not(.table-calendar):not(.table-bordered):not(.ui-datepicker) td:last-child {
                    /* padding-right: 4px; */
                }
    }


/* テーブル (枠線) */
/*----------------------------------------------------------------------------*/
table.table-bordered th {
        background: #e6e6e6;
    }
table.table-bordered,
    table.table-bordered thead,
    table.table-bordered tbody,
    table.table-bordered tfoot,
    table.table-bordered tr,
    table.table-bordered th,
    table.table-bordered td {
        border-style: solid;
    }

/* テーブル (枠線なし) */
/*----------------------------------------------------------------------------*/
table.table-border-none,
    table.table-border-none thead,
    table.table-border-none tbody,
    table.table-border-none tfoot,
    table.table-border-none tr,
    table.table-border-none th,
    table.table-border-none td {
        background: none;
        border-width: 0;
        border-style: none;
        border-color: transparent;
    }


/* テーブル (オリジナル_ドット) */
/*----------------------------------------------------------------------------*/
table.table-dot {
        background-image: url(../img/article/dot-line.png);
        background-position: top;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;
    }
table.table-dot tr {
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;

    }

/* テンプレート標準の記述のキャンセル */
table:not(.not-responsive):not(.ui-datepicker-calendar).table-dot tr {
        border-style: none;
    }
table:not(.not-responsive):not(.ui-datepicker-calendar).table-dot tr:last-child {
        border-bottom-style: none;
    }
table:not(.not-responsive):not(.ui-datepicker-calendar):not(.table-border-horizontal).table-dot tbody {
        border-right-style: none;
        border-left-style: none;
    }

/* テーブル (オリジナル_背景色あり) */
/*----------------------------------------------------------------------------*/
table.table-bg tr:nth-child(odd){
    background: #faf6e9;
    }
table.table-bg a {
        color: #03a9f4;
    }
table.table-bg a:hover {
        color: #76cef9;
    }
@media only screen and (min-width: 1000px) {
        table.table-bg th {
            padding-left: 25px !important;
        }
        table.table-bg td {
            padding-right: 20px !important;
            word-break: break-all;
        }
    }

/* レスポンシブ */
/*----------------------------------------------------------------------------*/
.table-reverse {}
.table-scroll {}
.table-smart {}
.not-responsive {}

@media only screen and (min-width: 0px) and (max-width: 479px) {
        table:not(.not-responsive):not(.ui-datepicker-calendar),
        table:not(.not-responsive):not(.ui-datepicker-calendar) caption,
        table:not(.not-responsive):not(.ui-datepicker-calendar) thead,
        table:not(.not-responsive):not(.ui-datepicker-calendar) tbody,
        table:not(.not-responsive):not(.ui-datepicker-calendar) tfoot,
        table:not(.not-responsive):not(.ui-datepicker-calendar) tr,
        table:not(.not-responsive):not(.ui-datepicker-calendar) th,
        table:not(.not-responsive):not(.ui-datepicker-calendar) td {
            display: block;
            border-style: none;
        }
            table:not(.not-responsive):not(.ui-datepicker-calendar):not(.table-border-horizontal) thead,
            table:not(.not-responsive):not(.ui-datepicker-calendar):not(.table-border-horizontal) tbody,
            table:not(.not-responsive):not(.ui-datepicker-calendar):not(.table-border-horizontal) tfoot {
            }
            table:not(.not-responsive):not(.ui-datepicker-calendar) thead:not(:last-child), table:not(.not-responsive):not(.ui-datepicker-calendar) tbody:not(:last-child), table:not(.not-responsive):not(.ui-datepicker-calendar) tfoot:not(:last-child) {
                margin-bottom: 0.5em;
            }
        table:not(.not-responsive):not(.ui-datepicker-calendar) tr {
            margin-bottom: 0.5em;
            border-top-style: solid;
        }
        table:not(.not-responsive):not(.ui-datepicker-calendar) tr:last-child {
            margin-bottom: 0;
            border-bottom-style: solid;
        }
        table:not(.not-responsive):not(.ui-datepicker-calendar) th,
        table:not(.not-responsive):not(.ui-datepicker-calendar) td {
            border-bottom-style: dotted;
        }
        table:not(.not-responsive):not(.ui-datepicker-calendar) td:last-child,
        table:not(.not-responsive):not(.ui-datepicker-calendar) th:last-child {
            border-bottom-style: none;
        }

        table:not(.not-responsive):not(.ui-datepicker-calendar) th:empty {
            display: none;
        }

            table:not(.not-responsive):not(.ui-datepicker-calendar) th:empty::before {
                content: "-";
            }
        table:not(.not-responsive):not(.ui-datepicker-calendar) th {
            /* padding-top: 0.5em; */
            /* padding-bottom: 0.5em; */
        }
    }


.fc table,
.not-responsive {
    display: table;
}


.fc table thead, .not-responsive thead {
        display: table-header-group;
    }


.fc table tbody, .not-responsive tbody {
        display: table-row-group;
    }


.fc table tfoot, .not-responsive tfoot {
        display: table-footer-group;
    }


.fc table tr, .not-responsive tr {
        display: table-row;
    }


.fc table td, .fc table th, .not-responsive td, .not-responsive th {
        display: table-cell;
    }



/* スマホとPCとで縦横が入れ替わるテーブル。折り返しが発生すると崩れるのでいまいち */
@media only screen and (min-width: 1300px) {
table.not-responsive.table-reverse {
        display: block
}

        table.not-responsive.table-reverse tbody {
            display: flex;
        }

        table.not-responsive.table-reverse tr {
            display: block;
        }
        table.not-responsive.table-reverse td,
        table.not-responsive.table-reverse th {
            display: block;
        }
            table.not-responsive.table-reverse tr:first-child td {
                white-space: nowrap;
            }
            table.not-responsive.table-reverse tr:not(:last-child) th,
            table.not-responsive.table-reverse tr:not(:last-child) td {
                border-right: none;
            }
            table.not-responsive.table-reverse tr th:not(:last-child),
            table.not-responsive.table-reverse tr td:not(:last-child) {
                border-bottom: none;
            }
    }


/* レスポンシブ対応のテーブル */
@media only screen and (min-width: 0px) and (max-width: 479px) {
    table:not(.not-responsive).table-scroll {
        display: flex;
    }

        table:not(.not-responsive).table-scroll thead {
            display: flex;
        }
        table:not(.not-responsive).table-scroll tbody,
        table:not(.not-responsive).table-scroll tfoot {
            display: flex;
            overflow-x: auto;
        }
        table:not(.not-responsive).table-scroll tr {
            height: 100%;
            display: flex;

            flex-direction: column;
            justify-content: center;
        }
        table:not(.not-responsive).table-scroll th {
            height: 100%;
            font-size: 0.85em;
        }
        table:not(.not-responsive).table-scroll thead th:not(:first-child),
        table:not(.not-responsive).table-scroll thead td:not(:first-child) {
            height: 100%;
        }
        table:not(.not-responsive).table-scroll thead {
            flex-grow: 1;
        }
        table:not(.not-responsive).table-scroll tbody td {
            height: 100%;
            display: block;
            white-space: nowrap;
        }
    table:not(.not-responsive):not(.no-border).table-scroll tr + tr {
        margin-top: 0;
    }
    table:not(.not-responsive):not(.no-border).table-scroll thead {
        margin-bottom: 0;
    }











    table.table-smart {
        border: 0;
    }

        table.table-smart thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        table.table-smart tr {
            display: block;
            margin-bottom: 0.625em;
        }
        table.table-smart td {
            border-bottom: 1px solid #ddd;
            display: block;
            text-align: right;
        }
        table.table-smart td:before {
            margin-bottom: 0.5em;
            content: attr(data-label);
            float: left;
            font-size: 0.85em;
            font-weight: bold;
        }
        table.table-smart td:last-child {
            border-bottom: 0;
        }

        table.table-smart ul,
        table.table-smart ol {
            margin-top: 10px;
            clear: both;
            text-align: left;
        }
}

a:hover img {
        opacity: 0.7;
    }
img {
    max-width: 100%;
}

figure figcaption {
        margin-top: 1em;
        text-align: center;
        line-height: calc(1em + 10px);
    }

figure img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

/* 将来的にCSS「object-fit」で実装できるようになる */
/* https://developer.mozilla.org/ja/docs/Web/CSS/object-fit */

/* 新thumbnail */
/*----------------------------------------------------------------------------*/
/*
.thumbnail {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.thumbnail > img {
    max-width: initial;
    max-height: initial;
    width: auto;
    height: auto;
    opacity: 0;
    transition: opacity 0.2s ease 0.0s;
    display: block;
    position: absolute;
    margin: auto;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1;
}

.thumbnail:not(.contain).landscape > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
.thumbnail:not(.contain).portrait > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.landscape > img {
    max-width: 100%;
    // max-height: none;
    width: 100%;
}
.thumbnail.contain.portrait > img {
    // max-width: none;
    max-height: 100%;
    height: 100%;
}
*/


/* 旧thumbnail */
/*----------------------------------------------------------------------------*/
.thumbnail {
    display: block;
    position: relative;
    /* min-height: 200px; */

    box-sizing: border-box;
    overflow: hidden;
}
.thumbnail > * {
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.thumbnail > img {
    max-width: none;
    max-width: initial;
    max-height: none;
    max-height: initial;
    transition: opacity 0.2s ease 0.2s;
}
.thumbnail:not(.landscape):not(.portrait) > img {
    opacity: 0.0;
}
.thumbnail.landscape > img,
.thumbnail.portrait > img {
    opacity: 1.0;
}
.thumbnail.landscape > img {
    max-width: none;
    max-width: initial;
    max-height: 100%;

    height: 100%;
    left: 50%;
    right: auto;
}
.thumbnail.portrait > img {
    max-width: 100%;
    max-height: auto;
    max-height: initial;

    width: 100%;
    top: 50%;
    bottom: auto;
}
.thumbnail.contain > img,
.thumbnail.contain.landscape > img,
.thumbnail.contain.portrait > img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
/* ↓.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */
.thumbnail.contain.landscape > img {
    width: 100%;
    height: auto;
}
.thumbnail.contain.portrait > img {
    width: auto;
    height: 100%;
}
/* ↑.containのとき、画像の横幅(縦幅)のpx数が小さいときに無理やり横幅100%にする */

iframe {
    max-width: 100%;
    display: block;
}

/*
アイコンのsvgファイルを以下のディレクトリに保存するとアイコンフォントとして使用可能。
1. /src/font/svg/ に.svgファイルを保存
2. gulpを実行
3. 保存したファイル名が「icon-arrow.svg」の場合、
　 「<i class="icon icon-arrow"></i>」でアイコンが表示される

上記、処理が「03-mixin-pre-consolidate.css」内に記述。
*/

/*
.icon-arrow::before {
    @mixin icon;
}
*/

/* パンくず homeアイコン */
/*----------------------------------------------------------------------------*/
.icon_home::before {
    content: url(../img/common/icon_home.svg);
    display: inline-block;
    width: 1em;
    height: auto;
    padding-right: 3px;
}
.icon_home:hover {
    opacity: 0.7;
  }

/* カテゴリーアイコン */
/*----------------------------------------------------------------------------*/
.icon-all::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA01";
    font-size: 34px;
}
.icon-art::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA04";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-event::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA12";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-lecture-course::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA13";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-media::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA16";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-movie::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA18";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-musics::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA19";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-sport::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA20";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-tea-flower::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA21";
    font-size: 34px;
    display: flex;
    justify-content: center;
}
.icon-theater::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    display: flex;
    justify-content: center;
}

.icon-traditional-culture::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA26";
    font-size: 34px;
    display: flex;
    justify-content: center;
}

/* 表示切り替えアイコン */
/*----------------------------------------------------------------------------*/
.icon-list::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA14";
    margin-right: 7px;
}
.icon-map::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA15";
    margin-right: 7px;
}

/* もっとみるアイコン */
/*----------------------------------------------------------------------------*/

.icon-more::after {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA17";
    font-size: 34px;
}

/*検索ボックス虫めがねアイコン */
/*----------------------------------------------------------------------------*/
.icon-search::before {
    display: inline-block;
    font-family: "original-icon-font";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\EA1F";
    font-size: 18px;
}

/* 以下、フォント以外のアイコン */
/*----------------------------------------------------------------------------*/
.icon-arrow-animation {
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    display: block;
    position: relative;
}
.icon-arrow-animation span {
	    margin: auto;
	    width: 24px;
	    height: 2px;
	    background: #000000;
	    display: block;
	    position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    transition: all 0.15s ease-in-out 0s;
	}
.icon-arrow-animation span:nth-child(1),
	.icon-arrow-animation span:nth-child(2) {
	    margin-right: 3px;
	    width: 12px;
	    -webkit-transform-origin: 100% 50%;
	            transform-origin: 100% 50%;
	}
.icon-arrow-animation span:nth-child(1) {
	    -webkit-transform: rotate(45deg);
	            transform: rotate(45deg);
	}
.icon-arrow-animation span:nth-child(2) {
	    -webkit-transform: rotate(-45deg);
	            transform: rotate(-45deg);
	}
.icon-arrow-animation:hover span:nth-child(1),
	.icon-arrow-animation:hover span:nth-child(2) {
	    margin-right: -7px;
	    width: 12px;
	    -webkit-transform-origin: 100% 50%;
	            transform-origin: 100% 50%;
	}
.icon-arrow-animation:hover span:nth-child(3) {
	    box-shadow: 10px 0 0 0 #111111;
	}

html .loading {
	position: relative;
	color: transparent !important;
    pointer-events: none;
}

html .loading *,
    html .loading *::before,
    html .loading *::after {
        color: transparent !important;
        pointer-events: none;
    }

html .loading > * {
        opacity: 0.5 !important;
    }

html .loading.field input:not([type="radio"]):not([type="checkbox"]):not([type="range"]),
        html .loading.field textarea,
        html .loading.field select {
            background: #c4c4c4 !important;
            border: none !important;
            box-shadow: none !important;
        }

html .loading.field-radio label .icon, html .loading.field-checkbox label .icon {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-radio label .icon::before,
                html .loading.field-radio label .icon::after,
                html .loading.field-checkbox label .icon::before,
                html .loading.field-checkbox label .icon::after {
                    display: none;
                }

html .loading.field-range input[type="range"] {
            background: #c4c4c4 !important;
            border: none !important;
            box-shadow: none !important;
        }

/*
            &::-webkit-slider-runnable-track {
                background: $color-gray !important;
                border: none !important;
                box-shadow: none !important;
            }
            */

html .loading.field-range input[type="range"]::-moz-range-track {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

/*横線 */

html .loading.field-range input[type="range"]::-ms-track {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

/* つまみ */

html .loading.field-range input[type="range"]::-webkit-slider-thumb {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-range input[type="range"]::-moz-range-thumb {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading.field-range input[type="range"]::-ms-thumb {
                background: #c4c4c4 !important;
                border: none !important;
                box-shadow: none !important;
            }

html .loading::before,
	html .loading::after {
		content: "";
		margin: auto;
		width: 12px;
		width: 1rem;
		height: 12px;
		height: 1rem;
		border-radius: 0.5rem;
		display: block;
		/* background: #f00; */

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		box-sizing: border-box;
	}

html .loading::before {
		border: 2px solid #000000;
		border: 2px solid rgba(0, 0, 0, 0.5);
		z-index: 900;
	}

html .loading::after {
		border: 2px solid #ffffff;
		border: 2px solid rgba(255, 255, 255, 1.0);
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
		z-index: 1000;

		-webkit-animation: icon-load-animation 1.0s linear 0s infinite;

		        animation: icon-load-animation 1.0s linear 0s infinite;
	}

html .loading ::-webkit-input-placeholder {
        color:#c4c4c4;
    }

html .loading ::-moz-placeholder {
        color:#c4c4c4;
        opacity: 1;
    }

html .loading :-ms-input-placeholder {
        color:#c4c4c4;
    }

html .loading :input-placeholder {
        color:#c4c4c4;
    }

@-webkit-keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}

@keyframes icon-load-animation {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    40% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    100% { -webkit-transform: rotate(720deg); transform: rotate(720deg); }
}

/* フォームUIを囲う要素 */
/*----------------------------------------------------------------------------*/
/* タイトルとフォームUIを囲う要素 */
fieldset,
.fieldset {
    margin: 2em 0;
}
fieldset:first-child, .fieldset:first-child {
        margin-top: 0;
    }
fieldset:last-child, .fieldset:last-child {
        margin-bottom: 0;
    }
fieldset .title, .fieldset .title {
        margin-bottom: 0.5em;
        /* font-weight: bold; */
        font-size: 0.8em;
    }
fieldset > .field, .fieldset > .field {
        margin-top: 0;
        margin-bottom: 0;
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {
fieldset,
.fieldset {
        margin: 3em 0
}

        fieldset:not(.fieldset-block), .fieldset:not(.fieldset-block) {
            display: flex;
            align-items: baseline;
        }

            fieldset:not(.fieldset-block) > .title, .fieldset:not(.fieldset-block) > .title {
                margin-right: 1em;
                margin-bottom: 0;
                width: 200px;
            }
            fieldset:not(.fieldset-block) > .field,
            fieldset:not(.fieldset-block) > .field-group,
            .fieldset:not(.fieldset-block) > .field,
            .fieldset:not(.fieldset-block) > .field-group {
                flex: 1 1 0%;
            }
    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }

/* フォームUIを囲う要素の親要素 */
.field-group {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.field-group .field {
        /* display: block; */
        flex-wrap: wrap;
        /* & > * { */
    }
.field-group .field label.title {
            margin-right: 0;
            margin-bottom: 0.25em;
            width: 100%;
            font-size: 0.85em;

            display: block;
        }
/* } */
.field-group .field > *:not(:first-child) {
            margin-left: 0;
        }
/*     margin-right: 0; */
.field-group .field:first-child {
            margin-top: 0;
        }
/*     margin-left: 0; */
.field-group .field:last-child {
            margin-bottom: 0;
        }
.field-group:first-child {
        margin-top: 0;
    }
.field-group:last-child {
        margin-bottom: 0;
    }

/* フォームUIを囲う要素 */
.field {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;

    /*
    select,
    textarea {
        margin: 0;
    }
    */
}
.field.field-xs {
        width: 162px;
    }
.field > * {
        flex: 1 1 0%;
    }
.field > label,
    .field > .unit,
    .field > .btn  {
        flex: initial;
    }
/* margin-right: 0.5em; */
.field > *:first-child {
            margin-left: 0;
        }
/* margin-left: 0.5em; */
.field > *:last-child {
            margin-right: 0;
        }
.field > .unit {
        margin: 0 5px;
    }
.field > .unit:first-child {
            margin-left: 0;
        }
.field > .unit:last-child {
            margin-right: 0;
        }
/* .field内で[type="hidden"]の次の要素は余白をキャンセル */
.field > [type="hidden"]:first-child + *,
    .field > [type="hidden"] + * {
        margin-left: 0;
    }
/* .field内の先頭に[type="hidden"]の次の要素は余白をキャンセル */
.field .field {
        margin-top: 0;
        margin-bottom: 0;

        /* 病歴モーダル内のradio、checkboxで問題が出るためコメントアウト */
        /*
        & > *:not(:last-child) {
            margin-right: 0.25em;
        }
        */
    }

#periodField {
    margin-top: 0;
    margin-bottom: 0;
}


/* 共通 */
/*----------------------------------------------------------------------------*/
input,
select,
option,
textarea {
    font-size:16px;

    /* firefox対策、.field内のUIの最小値を修正 */
    min-width: 1em;
}
label {
    white-space: nowrap;

    /*
    &,
    & > * {
        vertical-align: middle;
        line-height: 1;
    }
    */
}


/* テキスト、セレクト */
/*----------------------------------------------------------------------------*/
/* 自動入力時の見た目をリセット */
:-webkit-autofill {
    /*
    box-shadow: 0 0 0 1000px #eee inset !important;
    // background-color: transparent !important;
    // background-color: rgba(255, 255, 0, 1.0) !important;
    */
    box-shadow: inherit !important;
    background-color: inherit !important;
}
:-webkit-autofill:active,
    :-webkit-autofill:focus {
        /*
        box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
        */
        box-shadow: inherit !important;
        background-color: inherit !important;
    }

/* placeholder */
::-webkit-input-placeholder {
    color: #c4c4c4;
}
::-moz-placeholder {
    color: #c4c4c4;
    opacity: 1;
}
:-ms-input-placeholder {
    color: #c4c4c4;
}
:input-placeholder {
    color: #c4c4c4;
}
:focus::-webkit-input-placeholder {
    color: #c4c4c4;
}
:focus::-moz-placeholder {
    color: #c4c4c4;
    opacity: 1;
}
:focus:-ms-input-placeholder {
    color: #c4c4c4;
}
:focus:input-placeholder {
    color: #c4c4c4;
}


/* テキスト系のinput、textarea、select 共通 */
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
    /* サイズ */
    padding: 10px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;

    /* 線と背景 */
    /*border-radius: 0; */
    border: none;
    background-color: #fff;
}
input[type="text"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="url"]:hover, input[type="email"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="week"]:hover, input[type="datetime-local"]:hover, input[type="number"]:hover, input[type="file"]:hover, textarea:hover, select:hover, option:hover {

    }
input[type="text"]:active,
    input[type="text"]:focus,
    input[type="search"]:active,
    input[type="search"]:focus,
    input[type="tel"]:active,
    input[type="tel"]:focus,
    input[type="url"]:active,
    input[type="url"]:focus,
    input[type="email"]:active,
    input[type="email"]:focus,
    input[type="password"]:active,
    input[type="password"]:focus,
    input[type="datetime"]:active,
    input[type="datetime"]:focus,
    input[type="date"]:active,
    input[type="date"]:focus,
    input[type="month"]:active,
    input[type="month"]:focus,
    input[type="week"]:active,
    input[type="week"]:focus,
    input[type="datetime-local"]:active,
    input[type="datetime-local"]:focus,
    input[type="number"]:active,
    input[type="number"]:focus,
    input[type="file"]:active,
    input[type="file"]:focus,
    textarea:active,
    textarea:focus,
    select:active,
    select:focus,
    option:active,
    option:focus {
        outline: none;
        /* -webkit- */
        /* box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05) inset; */
        /*border-color: ; */
        /*box-shadow: $shadow-size-focus $color-link; */
        background-color: #fff;
    }
input[type="text"]:disabled,
    input[type="text"][readonly],
    input[type="search"]:disabled,
    input[type="search"][readonly],
    input[type="tel"]:disabled,
    input[type="tel"][readonly],
    input[type="url"]:disabled,
    input[type="url"][readonly],
    input[type="email"]:disabled,
    input[type="email"][readonly],
    input[type="password"]:disabled,
    input[type="password"][readonly],
    input[type="datetime"]:disabled,
    input[type="datetime"][readonly],
    input[type="date"]:disabled,
    input[type="date"][readonly],
    input[type="month"]:disabled,
    input[type="month"][readonly],
    input[type="week"]:disabled,
    input[type="week"][readonly],
    input[type="datetime-local"]:disabled,
    input[type="datetime-local"][readonly],
    input[type="number"]:disabled,
    input[type="number"][readonly],
    input[type="file"]:disabled,
    input[type="file"][readonly],
    textarea:disabled,
    textarea[readonly],
    select:disabled,
    select[readonly],
    option:disabled,
    option[readonly] {
        color: #000000 !important;
        color: rgba(0, 0, 0, 0.5) !important;
        background-color: #ddd !important;
        border-color: #000000 !important;
        border-color: rgba(0, 0, 0, 0.2) !important;
        box-shadow: none !important;
        cursor: default !important;
    }

/* テキスト系のinput */

input[type="number"] {
    width: 6em;
    min-width: 6em; /* IE対策 */
    flex: 0 0 6em;
}
input[type="file"] {
    /* padding: calc(0.75em - 1.5px) 0.5em; */
    cursor: pointer;
    background : none;
}


/* textarea */
textarea {
    width: 100%;
    height: 8em;
    min-height: 3em;
    resize: vertical;
    display: block;
    line-height: 1.5;
    font-family: 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic ProN','メイリオ',Meiryo,Verdana,Droid Sans,sans-serif;
}
@media only screen and (min-width: 480px) {
textarea {
        height: 12em
}
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }


/* select (1行) */
select {
    /* padding-right: 32px; */
    padding-right: 24px;

    background-repeat: no-repeat;
    background-image: url(../img/common/icon-select-margin.svg);
    background-position: right center;
    background-size: 32px 16px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    line-height: 1.5;

    position: relative;
}
/* IEで矢印を非表示 */
select::-ms-expand {
        display: none;
    }
select option {
        outline: none;
    }
select[value=""] {
    color: #f00;
}

/* select (複数行) */
select[size]:not([size="1"]),
select[multiple] {
    padding: 0;
    background-image: none;
    overflow: auto;
}
select[size]:not([size="1"]) option, select[multiple] option {
        /* padding: 0.75em 1em; */
        border: none;
    }
select[size]:not([size="1"]) optgroup, select[multiple] optgroup {
        padding: 0.5em 0;
        color: #ccc;
        font-size: 0.85em;
        font-weight: normal;
        text-indent: 5px;
    }
select[size]:not([size="1"]) optgroup > option, select[multiple] optgroup > option {
            padding-left: 0;
            padding-right: 0;
            color: #222;
            font-size: 12px;
            font-size: 1rem;
            text-indent: 0;
        }
select[size]:not([size="1"]) optgroup > option::before, select[multiple] optgroup > option::before {
            content: "";
            width: 1em;
            display: inline-block;
        }
select[size]:not([size="1"]) optgroup > option:first-child, select[multiple] optgroup > option:first-child {
            margin-top: 5px;
            border-top: 1px dotted #ccc;
        }
select[size]:not([size="1"]) optgroup:not(:first-child), select[multiple] optgroup:not(:first-child) {
        border-top: 1px dotted #ccc;
    }
select[size]:not([size="1"]) optgroup:last-child, select[multiple] optgroup:last-child {
        padding-bottom: 0;
    }


/* バリデーション */
/* 失敗、エラー */
input[type="text"].is-invalid, input[type="search"].is-invalid, input[type="tel"].is-invalid, input[type="url"].is-invalid, input[type="email"].is-invalid, input[type="password"].is-invalid, input[type="datetime"].is-invalid, input[type="date"].is-invalid, input[type="month"].is-invalid, input[type="week"].is-invalid, input[type="datetime-local"].is-invalid, input[type="number"].is-invalid, input[type="file"].is-invalid, textarea.is-invalid, select.is-invalid {
        color: #ec5858;
        border-color: #ec5858;
        background-color: #fffafa;
    }
input[type="text"].is-invalid:hover, input[type="search"].is-invalid:hover, input[type="tel"].is-invalid:hover, input[type="url"].is-invalid:hover, input[type="email"].is-invalid:hover, input[type="password"].is-invalid:hover, input[type="datetime"].is-invalid:hover, input[type="date"].is-invalid:hover, input[type="month"].is-invalid:hover, input[type="week"].is-invalid:hover, input[type="datetime-local"].is-invalid:hover, input[type="number"].is-invalid:hover, input[type="file"].is-invalid:hover, textarea.is-invalid:hover, select.is-invalid:hover {

        }
input[type="text"].is-invalid:active,
        input[type="text"].is-invalid:focus,
        input[type="search"].is-invalid:active,
        input[type="search"].is-invalid:focus,
        input[type="tel"].is-invalid:active,
        input[type="tel"].is-invalid:focus,
        input[type="url"].is-invalid:active,
        input[type="url"].is-invalid:focus,
        input[type="email"].is-invalid:active,
        input[type="email"].is-invalid:focus,
        input[type="password"].is-invalid:active,
        input[type="password"].is-invalid:focus,
        input[type="datetime"].is-invalid:active,
        input[type="datetime"].is-invalid:focus,
        input[type="date"].is-invalid:active,
        input[type="date"].is-invalid:focus,
        input[type="month"].is-invalid:active,
        input[type="month"].is-invalid:focus,
        input[type="week"].is-invalid:active,
        input[type="week"].is-invalid:focus,
        input[type="datetime-local"].is-invalid:active,
        input[type="datetime-local"].is-invalid:focus,
        input[type="number"].is-invalid:active,
        input[type="number"].is-invalid:focus,
        input[type="file"].is-invalid:active,
        input[type="file"].is-invalid:focus,
        textarea.is-invalid:active,
        textarea.is-invalid:focus,
        select.is-invalid:active,
        select.is-invalid:focus {
            background-color: #fff;
            box-shadow: 0 0 0 1px inset #ec5858;
        }
input[type="text"].is-invalid:disabled,
        input[type="text"].is-invalid[readonly],
        input[type="search"].is-invalid:disabled,
        input[type="search"].is-invalid[readonly],
        input[type="tel"].is-invalid:disabled,
        input[type="tel"].is-invalid[readonly],
        input[type="url"].is-invalid:disabled,
        input[type="url"].is-invalid[readonly],
        input[type="email"].is-invalid:disabled,
        input[type="email"].is-invalid[readonly],
        input[type="password"].is-invalid:disabled,
        input[type="password"].is-invalid[readonly],
        input[type="datetime"].is-invalid:disabled,
        input[type="datetime"].is-invalid[readonly],
        input[type="date"].is-invalid:disabled,
        input[type="date"].is-invalid[readonly],
        input[type="month"].is-invalid:disabled,
        input[type="month"].is-invalid[readonly],
        input[type="week"].is-invalid:disabled,
        input[type="week"].is-invalid[readonly],
        input[type="datetime-local"].is-invalid:disabled,
        input[type="datetime-local"].is-invalid[readonly],
        input[type="number"].is-invalid:disabled,
        input[type="number"].is-invalid[readonly],
        input[type="file"].is-invalid:disabled,
        input[type="file"].is-invalid[readonly],
        textarea.is-invalid:disabled,
        textarea.is-invalid[readonly],
        select.is-invalid:disabled,
        select.is-invalid[readonly] {

        }
/* placeholder */
input[type="text"].is-invalid::-webkit-input-placeholder, input[type="search"].is-invalid::-webkit-input-placeholder, input[type="tel"].is-invalid::-webkit-input-placeholder, input[type="url"].is-invalid::-webkit-input-placeholder, input[type="email"].is-invalid::-webkit-input-placeholder, input[type="password"].is-invalid::-webkit-input-placeholder, input[type="datetime"].is-invalid::-webkit-input-placeholder, input[type="date"].is-invalid::-webkit-input-placeholder, input[type="month"].is-invalid::-webkit-input-placeholder, input[type="week"].is-invalid::-webkit-input-placeholder, input[type="datetime-local"].is-invalid::-webkit-input-placeholder, input[type="number"].is-invalid::-webkit-input-placeholder, input[type="file"].is-invalid::-webkit-input-placeholder, textarea.is-invalid::-webkit-input-placeholder, select.is-invalid::-webkit-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid::-moz-placeholder, input[type="search"].is-invalid::-moz-placeholder, input[type="tel"].is-invalid::-moz-placeholder, input[type="url"].is-invalid::-moz-placeholder, input[type="email"].is-invalid::-moz-placeholder, input[type="password"].is-invalid::-moz-placeholder, input[type="datetime"].is-invalid::-moz-placeholder, input[type="date"].is-invalid::-moz-placeholder, input[type="month"].is-invalid::-moz-placeholder, input[type="week"].is-invalid::-moz-placeholder, input[type="datetime-local"].is-invalid::-moz-placeholder, input[type="number"].is-invalid::-moz-placeholder, input[type="file"].is-invalid::-moz-placeholder, textarea.is-invalid::-moz-placeholder, select.is-invalid::-moz-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
            opacity: 1;
        }
input[type="text"].is-invalid:-ms-input-placeholder, input[type="search"].is-invalid:-ms-input-placeholder, input[type="tel"].is-invalid:-ms-input-placeholder, input[type="url"].is-invalid:-ms-input-placeholder, input[type="email"].is-invalid:-ms-input-placeholder, input[type="password"].is-invalid:-ms-input-placeholder, input[type="datetime"].is-invalid:-ms-input-placeholder, input[type="date"].is-invalid:-ms-input-placeholder, input[type="month"].is-invalid:-ms-input-placeholder, input[type="week"].is-invalid:-ms-input-placeholder, input[type="datetime-local"].is-invalid:-ms-input-placeholder, input[type="number"].is-invalid:-ms-input-placeholder, input[type="file"].is-invalid:-ms-input-placeholder, textarea.is-invalid:-ms-input-placeholder, select.is-invalid:-ms-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid:input-placeholder, input[type="search"].is-invalid:input-placeholder, input[type="tel"].is-invalid:input-placeholder, input[type="url"].is-invalid:input-placeholder, input[type="email"].is-invalid:input-placeholder, input[type="password"].is-invalid:input-placeholder, input[type="datetime"].is-invalid:input-placeholder, input[type="date"].is-invalid:input-placeholder, input[type="month"].is-invalid:input-placeholder, input[type="week"].is-invalid:input-placeholder, input[type="datetime-local"].is-invalid:input-placeholder, input[type="number"].is-invalid:input-placeholder, input[type="file"].is-invalid:input-placeholder, textarea.is-invalid:input-placeholder, select.is-invalid:input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.35);
        }
input[type="text"].is-invalid:focus::-webkit-input-placeholder, input[type="search"].is-invalid:focus::-webkit-input-placeholder, input[type="tel"].is-invalid:focus::-webkit-input-placeholder, input[type="url"].is-invalid:focus::-webkit-input-placeholder, input[type="email"].is-invalid:focus::-webkit-input-placeholder, input[type="password"].is-invalid:focus::-webkit-input-placeholder, input[type="datetime"].is-invalid:focus::-webkit-input-placeholder, input[type="date"].is-invalid:focus::-webkit-input-placeholder, input[type="month"].is-invalid:focus::-webkit-input-placeholder, input[type="week"].is-invalid:focus::-webkit-input-placeholder, input[type="datetime-local"].is-invalid:focus::-webkit-input-placeholder, input[type="number"].is-invalid:focus::-webkit-input-placeholder, input[type="file"].is-invalid:focus::-webkit-input-placeholder, textarea.is-invalid:focus::-webkit-input-placeholder, select.is-invalid:focus::-webkit-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
input[type="text"].is-invalid:focus::-moz-placeholder, input[type="search"].is-invalid:focus::-moz-placeholder, input[type="tel"].is-invalid:focus::-moz-placeholder, input[type="url"].is-invalid:focus::-moz-placeholder, input[type="email"].is-invalid:focus::-moz-placeholder, input[type="password"].is-invalid:focus::-moz-placeholder, input[type="datetime"].is-invalid:focus::-moz-placeholder, input[type="date"].is-invalid:focus::-moz-placeholder, input[type="month"].is-invalid:focus::-moz-placeholder, input[type="week"].is-invalid:focus::-moz-placeholder, input[type="datetime-local"].is-invalid:focus::-moz-placeholder, input[type="number"].is-invalid:focus::-moz-placeholder, input[type="file"].is-invalid:focus::-moz-placeholder, textarea.is-invalid:focus::-moz-placeholder, select.is-invalid:focus::-moz-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
            opacity: 1;
        }
input[type="text"].is-invalid:focus:-ms-input-placeholder, input[type="search"].is-invalid:focus:-ms-input-placeholder, input[type="tel"].is-invalid:focus:-ms-input-placeholder, input[type="url"].is-invalid:focus:-ms-input-placeholder, input[type="email"].is-invalid:focus:-ms-input-placeholder, input[type="password"].is-invalid:focus:-ms-input-placeholder, input[type="datetime"].is-invalid:focus:-ms-input-placeholder, input[type="date"].is-invalid:focus:-ms-input-placeholder, input[type="month"].is-invalid:focus:-ms-input-placeholder, input[type="week"].is-invalid:focus:-ms-input-placeholder, input[type="datetime-local"].is-invalid:focus:-ms-input-placeholder, input[type="number"].is-invalid:focus:-ms-input-placeholder, input[type="file"].is-invalid:focus:-ms-input-placeholder, textarea.is-invalid:focus:-ms-input-placeholder, select.is-invalid:focus:-ms-input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
input[type="text"].is-invalid:focus:input-placeholder, input[type="search"].is-invalid:focus:input-placeholder, input[type="tel"].is-invalid:focus:input-placeholder, input[type="url"].is-invalid:focus:input-placeholder, input[type="email"].is-invalid:focus:input-placeholder, input[type="password"].is-invalid:focus:input-placeholder, input[type="datetime"].is-invalid:focus:input-placeholder, input[type="date"].is-invalid:focus:input-placeholder, input[type="month"].is-invalid:focus:input-placeholder, input[type="week"].is-invalid:focus:input-placeholder, input[type="datetime-local"].is-invalid:focus:input-placeholder, input[type="number"].is-invalid:focus:input-placeholder, input[type="file"].is-invalid:focus:input-placeholder, textarea.is-invalid:focus:input-placeholder, select.is-invalid:focus:input-placeholder {
            color: #ec5858;
            color: rgba(236,88,88, 0.65);
        }
/* 成功 */
input[type="text"].is-valid, input[type="search"].is-valid, input[type="tel"].is-valid, input[type="url"].is-valid, input[type="email"].is-valid, input[type="password"].is-valid, input[type="datetime"].is-valid, input[type="date"].is-valid, input[type="month"].is-valid, input[type="week"].is-valid, input[type="datetime-local"].is-valid, input[type="number"].is-valid, input[type="file"].is-valid, textarea.is-valid, select.is-valid {
        color: #6bc55d;
        border-color: #6bc55d;
        background-color: #fafff9;
    }
input[type="text"].is-valid:hover, input[type="search"].is-valid:hover, input[type="tel"].is-valid:hover, input[type="url"].is-valid:hover, input[type="email"].is-valid:hover, input[type="password"].is-valid:hover, input[type="datetime"].is-valid:hover, input[type="date"].is-valid:hover, input[type="month"].is-valid:hover, input[type="week"].is-valid:hover, input[type="datetime-local"].is-valid:hover, input[type="number"].is-valid:hover, input[type="file"].is-valid:hover, textarea.is-valid:hover, select.is-valid:hover {

        }
input[type="text"].is-valid:active,
        input[type="text"].is-valid:focus,
        input[type="search"].is-valid:active,
        input[type="search"].is-valid:focus,
        input[type="tel"].is-valid:active,
        input[type="tel"].is-valid:focus,
        input[type="url"].is-valid:active,
        input[type="url"].is-valid:focus,
        input[type="email"].is-valid:active,
        input[type="email"].is-valid:focus,
        input[type="password"].is-valid:active,
        input[type="password"].is-valid:focus,
        input[type="datetime"].is-valid:active,
        input[type="datetime"].is-valid:focus,
        input[type="date"].is-valid:active,
        input[type="date"].is-valid:focus,
        input[type="month"].is-valid:active,
        input[type="month"].is-valid:focus,
        input[type="week"].is-valid:active,
        input[type="week"].is-valid:focus,
        input[type="datetime-local"].is-valid:active,
        input[type="datetime-local"].is-valid:focus,
        input[type="number"].is-valid:active,
        input[type="number"].is-valid:focus,
        input[type="file"].is-valid:active,
        input[type="file"].is-valid:focus,
        textarea.is-valid:active,
        textarea.is-valid:focus,
        select.is-valid:active,
        select.is-valid:focus {
            background-color: #fff;
            box-shadow: 0 0 0 1px inset #6bc55d;
        }
input[type="text"].is-valid:disabled,
        input[type="text"].is-valid[readonly],
        input[type="search"].is-valid:disabled,
        input[type="search"].is-valid[readonly],
        input[type="tel"].is-valid:disabled,
        input[type="tel"].is-valid[readonly],
        input[type="url"].is-valid:disabled,
        input[type="url"].is-valid[readonly],
        input[type="email"].is-valid:disabled,
        input[type="email"].is-valid[readonly],
        input[type="password"].is-valid:disabled,
        input[type="password"].is-valid[readonly],
        input[type="datetime"].is-valid:disabled,
        input[type="datetime"].is-valid[readonly],
        input[type="date"].is-valid:disabled,
        input[type="date"].is-valid[readonly],
        input[type="month"].is-valid:disabled,
        input[type="month"].is-valid[readonly],
        input[type="week"].is-valid:disabled,
        input[type="week"].is-valid[readonly],
        input[type="datetime-local"].is-valid:disabled,
        input[type="datetime-local"].is-valid[readonly],
        input[type="number"].is-valid:disabled,
        input[type="number"].is-valid[readonly],
        input[type="file"].is-valid:disabled,
        input[type="file"].is-valid[readonly],
        textarea.is-valid:disabled,
        textarea.is-valid[readonly],
        select.is-valid:disabled,
        select.is-valid[readonly] {

        }
/* placeholder */
input[type="text"].is-valid::-webkit-input-placeholder, input[type="search"].is-valid::-webkit-input-placeholder, input[type="tel"].is-valid::-webkit-input-placeholder, input[type="url"].is-valid::-webkit-input-placeholder, input[type="email"].is-valid::-webkit-input-placeholder, input[type="password"].is-valid::-webkit-input-placeholder, input[type="datetime"].is-valid::-webkit-input-placeholder, input[type="date"].is-valid::-webkit-input-placeholder, input[type="month"].is-valid::-webkit-input-placeholder, input[type="week"].is-valid::-webkit-input-placeholder, input[type="datetime-local"].is-valid::-webkit-input-placeholder, input[type="number"].is-valid::-webkit-input-placeholder, input[type="file"].is-valid::-webkit-input-placeholder, textarea.is-valid::-webkit-input-placeholder, select.is-valid::-webkit-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid::-moz-placeholder, input[type="search"].is-valid::-moz-placeholder, input[type="tel"].is-valid::-moz-placeholder, input[type="url"].is-valid::-moz-placeholder, input[type="email"].is-valid::-moz-placeholder, input[type="password"].is-valid::-moz-placeholder, input[type="datetime"].is-valid::-moz-placeholder, input[type="date"].is-valid::-moz-placeholder, input[type="month"].is-valid::-moz-placeholder, input[type="week"].is-valid::-moz-placeholder, input[type="datetime-local"].is-valid::-moz-placeholder, input[type="number"].is-valid::-moz-placeholder, input[type="file"].is-valid::-moz-placeholder, textarea.is-valid::-moz-placeholder, select.is-valid::-moz-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
            opacity: 1;
        }
input[type="text"].is-valid:-ms-input-placeholder, input[type="search"].is-valid:-ms-input-placeholder, input[type="tel"].is-valid:-ms-input-placeholder, input[type="url"].is-valid:-ms-input-placeholder, input[type="email"].is-valid:-ms-input-placeholder, input[type="password"].is-valid:-ms-input-placeholder, input[type="datetime"].is-valid:-ms-input-placeholder, input[type="date"].is-valid:-ms-input-placeholder, input[type="month"].is-valid:-ms-input-placeholder, input[type="week"].is-valid:-ms-input-placeholder, input[type="datetime-local"].is-valid:-ms-input-placeholder, input[type="number"].is-valid:-ms-input-placeholder, input[type="file"].is-valid:-ms-input-placeholder, textarea.is-valid:-ms-input-placeholder, select.is-valid:-ms-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid:input-placeholder, input[type="search"].is-valid:input-placeholder, input[type="tel"].is-valid:input-placeholder, input[type="url"].is-valid:input-placeholder, input[type="email"].is-valid:input-placeholder, input[type="password"].is-valid:input-placeholder, input[type="datetime"].is-valid:input-placeholder, input[type="date"].is-valid:input-placeholder, input[type="month"].is-valid:input-placeholder, input[type="week"].is-valid:input-placeholder, input[type="datetime-local"].is-valid:input-placeholder, input[type="number"].is-valid:input-placeholder, input[type="file"].is-valid:input-placeholder, textarea.is-valid:input-placeholder, select.is-valid:input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.35);
        }
input[type="text"].is-valid:focus::-webkit-input-placeholder, input[type="search"].is-valid:focus::-webkit-input-placeholder, input[type="tel"].is-valid:focus::-webkit-input-placeholder, input[type="url"].is-valid:focus::-webkit-input-placeholder, input[type="email"].is-valid:focus::-webkit-input-placeholder, input[type="password"].is-valid:focus::-webkit-input-placeholder, input[type="datetime"].is-valid:focus::-webkit-input-placeholder, input[type="date"].is-valid:focus::-webkit-input-placeholder, input[type="month"].is-valid:focus::-webkit-input-placeholder, input[type="week"].is-valid:focus::-webkit-input-placeholder, input[type="datetime-local"].is-valid:focus::-webkit-input-placeholder, input[type="number"].is-valid:focus::-webkit-input-placeholder, input[type="file"].is-valid:focus::-webkit-input-placeholder, textarea.is-valid:focus::-webkit-input-placeholder, select.is-valid:focus::-webkit-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }
input[type="text"].is-valid:focus::-moz-placeholder, input[type="search"].is-valid:focus::-moz-placeholder, input[type="tel"].is-valid:focus::-moz-placeholder, input[type="url"].is-valid:focus::-moz-placeholder, input[type="email"].is-valid:focus::-moz-placeholder, input[type="password"].is-valid:focus::-moz-placeholder, input[type="datetime"].is-valid:focus::-moz-placeholder, input[type="date"].is-valid:focus::-moz-placeholder, input[type="month"].is-valid:focus::-moz-placeholder, input[type="week"].is-valid:focus::-moz-placeholder, input[type="datetime-local"].is-valid:focus::-moz-placeholder, input[type="number"].is-valid:focus::-moz-placeholder, input[type="file"].is-valid:focus::-moz-placeholder, textarea.is-valid:focus::-moz-placeholder, select.is-valid:focus::-moz-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
            opacity: 1;
        }
input[type="text"].is-valid:focus:-ms-input-placeholder, input[type="search"].is-valid:focus:-ms-input-placeholder, input[type="tel"].is-valid:focus:-ms-input-placeholder, input[type="url"].is-valid:focus:-ms-input-placeholder, input[type="email"].is-valid:focus:-ms-input-placeholder, input[type="password"].is-valid:focus:-ms-input-placeholder, input[type="datetime"].is-valid:focus:-ms-input-placeholder, input[type="date"].is-valid:focus:-ms-input-placeholder, input[type="month"].is-valid:focus:-ms-input-placeholder, input[type="week"].is-valid:focus:-ms-input-placeholder, input[type="datetime-local"].is-valid:focus:-ms-input-placeholder, input[type="number"].is-valid:focus:-ms-input-placeholder, input[type="file"].is-valid:focus:-ms-input-placeholder, textarea.is-valid:focus:-ms-input-placeholder, select.is-valid:focus:-ms-input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }
input[type="text"].is-valid:focus:input-placeholder, input[type="search"].is-valid:focus:input-placeholder, input[type="tel"].is-valid:focus:input-placeholder, input[type="url"].is-valid:focus:input-placeholder, input[type="email"].is-valid:focus:input-placeholder, input[type="password"].is-valid:focus:input-placeholder, input[type="datetime"].is-valid:focus:input-placeholder, input[type="date"].is-valid:focus:input-placeholder, input[type="month"].is-valid:focus:input-placeholder, input[type="week"].is-valid:focus:input-placeholder, input[type="datetime-local"].is-valid:focus:input-placeholder, input[type="number"].is-valid:focus:input-placeholder, input[type="file"].is-valid:focus:input-placeholder, textarea.is-valid:focus:input-placeholder, select.is-valid:focus:input-placeholder {
            color: #6bc55d;
            color: rgba(107,197,93, 0.65);
        }



/* ラジオボタン、チェックボックス */
/*----------------------------------------------------------------------------*/

/* ラジオボタン、チェックボックスを囲んでいるlabel */
.field-radio,
.field-checkbox {
    flex-wrap: wrap;
    margin: 0;

    /* 「.field > *」のmargin、paddingをキャンセル */
    /* 将来的にはjQueryを使用せず */
    /* :focus-withinで対応可能。 */
    /* & > label:active, */
    /* & > label:focus-within, */
}
/*
    & > label,
    & > label:not(:first-child),
    & > label:not(:last-child) {
        margin: 0;
        padding: 0.5em;
    }
    */
.field-radio > *,
    .field-radio > *:not(:first-child),
    .field-radio > *:not(:last-child),
    .field-checkbox > *,
    .field-checkbox > *:not(:first-child),
    .field-checkbox > *:not(:last-child) {
        margin: 0;
        flex: 0 0 auto;
    }
/* WordPress MW WP Form */
.field-radio > .mwform-radio-field,
    .field-radio > .mwform-checkbox-field,
    .field-checkbox > .mwform-radio-field,
    .field-checkbox > .mwform-checkbox-field {
        margin: 0 !important;
    }
.field-radio > .mwform-radio-field.vertical-item, .field-radio > .mwform-checkbox-field.vertical-item, .field-checkbox > .mwform-radio-field.vertical-item, .field-checkbox > .mwform-checkbox-field.vertical-item {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 0.5em - 0.5em);
        }
/* & > label { */
.field-radio label, .field-checkbox label {
        /* MW WP Formではみ出るのでコメントアウト */
        /* max-width: calc(100% - 0.5em - 0.5em); */
        color: #222;
        display: flex;
        align-items: center;
        /* IEでラジオボタンが横方向に潰れる */
        /* flex: 0 1 7em; */
        /* flex: 0 0 auto; */
        cursor: pointer;
        white-space: nowrap;
    }
.field-radio label .icon, .field-checkbox label .icon {
            /* flex: 0 0 1.5rem; */
        }
.field-radio label span, .field-checkbox label span {
            flex: 1 1 calc(100% - 1.75rem);
        }
/* & > label:hover { */
.field-radio label:hover, .field-checkbox label:hover {

    }
/* & > label.focus { */
.field-radio label:active,
    .field-radio label:focus-within,
    .field-radio label.focus,
    .field-checkbox label:active,
    .field-checkbox label:focus-within,
    .field-checkbox label.focus {

    }
/* & > label.disabled { */
.field-radio label.disabled,
        .field-radio label.disabled *,
        .field-radio label.disabled input[type="radio"],
        .field-radio label.disabled input[type="checkbox"],
        .field-checkbox label.disabled,
        .field-checkbox label.disabled *,
        .field-checkbox label.disabled input[type="radio"],
        .field-checkbox label.disabled input[type="checkbox"] {
            color: #ddd;
            cursor: default;
        }

/* CSSで実装 */
/* radio、checkbox共通 */
input[type="radio"],
input[type="checkbox"] {
    margin-right: 12px;
    margin-right: 1rem;
    cursor: pointer;
}
input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        margin: 0;
        opacity: 0;
        width: 12px;
        width: 1rem;
        height: 12px;
        height: 1rem;
        font-size: 12px;
        font-size: 1rem;
    }
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
            margin: 0 6px 0 -12px;
            margin: 0 0.5rem 0 -1rem;
            padding: 0;
            flex: 0 0 18px;
            flex: 0 0 1.5rem;
            font-size: 18px;
            font-size: 1.5rem;
            width: 18px;
            width: 1.5rem;
            height: 18px;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }
input[type="radio"].before-icon + .icon::before, input[type="checkbox"].before-icon + .icon::before {
            -webkit-transform: none;
                    transform: none;
            display: block;
        }

/* radio */
input[type="radio"].before-icon + .icon {
        border-radius: 50%;

        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
        background-color: #fafafa;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
input[type="radio"].before-icon + .icon::before {
            content: "";
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
        }
input[type="radio"].before-icon:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px #222 inset;
    }
input[type="radio"].before-icon:checked + .icon::before {
            background-color: #222222;
            background-color: rgba(34,34,34, 0.75);
        }
/* focus */
input[type="radio"].before-icon:focus {
        outline: none;
    }
input[type="radio"].before-icon:active + .icon, input[type="radio"].before-icon:focus + .icon {
            box-shadow: 0 0 0 2px #03a9f4 inset;
        }
input[type="radio"].before-icon:active + .icon::before, input[type="radio"].before-icon:focus + .icon::before {

            }
input[type="radio"].before-icon:active:checked + .icon::before, input[type="radio"].before-icon:focus:checked + .icon::before {
                background-color: #03a9f4;
                background-color: rgba(3,169,244, 0.75);
            }
/* disabled */
input[type="radio"].before-icon:disabled + .icon, input[type="radio"].before-icon[readonly] + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: #ddd;
            cursor: default;
        }
input[type="radio"].before-icon:disabled + .icon::before, input[type="radio"].before-icon[readonly] + .icon::before {

            }
input[type="radio"].before-icon:disabled:checked + .icon::before, input[type="radio"].before-icon[readonly]:checked + .icon::before {
                background-color: #000000;
                background-color: rgba(0, 0, 0, 0.2);
            }

/* checkbox */
input[type="checkbox"].before-icon + .icon {
        border-radius: 10%;

        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
        background-color: #fafafa;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
input[type="checkbox"].before-icon + .icon::before {
            content: "";
            width: 90%;
            height: 40%;
            /* background: #f00; */
            border-left: 4px solid transparent;
            border-bottom: 4px solid transparent;
            display: block;
            box-sizing: border-box;
            -webkit-transform: translate(10%, -40%) rotate(-45deg);
                    transform: translate(10%, -40%) rotate(-45deg);
        }
input[type="checkbox"].before-icon:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px #222 inset;
    }
input[type="checkbox"].before-icon:checked + .icon::before {
            border-color: #222222;
            border-color: rgba(34,34,34, 0.75);
        }
/* focus */
input[type="checkbox"].before-icon:focus {
        outline: none;
    }
input[type="checkbox"].before-icon:active + .icon, input[type="checkbox"].before-icon:focus + .icon {
            box-shadow: 0 0 0 2px #03a9f4 inset;
        }
input[type="checkbox"].before-icon:active + .icon::before, input[type="checkbox"].before-icon:focus + .icon::before {

            }
input[type="checkbox"].before-icon:active:checked + .icon::before, input[type="checkbox"].before-icon:focus:checked + .icon::before {
                border-color: #03a9f4;
                border-color: rgba(3,169,244, 0.75);
            }
/* disabled */
input[type="checkbox"].before-icon:disabled + .icon, input[type="checkbox"].before-icon[readonly] + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: #ddd;
            cursor: default;
        }
input[type="checkbox"].before-icon:disabled + .icon::before, input[type="checkbox"].before-icon[readonly] + .icon::before {

            }
input[type="checkbox"].before-icon:disabled:checked + .icon::before, input[type="checkbox"].before-icon[readonly]:checked + .icon::before {
                border-color: #000000;
                border-color: rgba(0, 0, 0, 0.2);
            }


/* バリデーション */
/* 失敗、エラー */
input[type="radio"].before-icon.is-invalid + .icon {
            box-shadow: 0 0 0 2px rgba(236,88,88, 0.25) inset;
            background-color: #fffafa;
        }
input[type="radio"].before-icon.is-invalid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #ec5858 inset;
        }
input[type="radio"].before-icon.is-invalid:checked + .icon::before {
                background-color: #ec5858;
            }
/* focus */
input[type="radio"].before-icon.is-invalid:active + .icon, input[type="radio"].before-icon.is-invalid:focus + .icon {
                background: #ec5858;
                background: rgba(236,88,88, 0.2);
            }
input[type="radio"].before-icon.is-invalid:active + .icon::before, input[type="radio"].before-icon.is-invalid:focus + .icon::before {

                }
input[type="radio"].before-icon.is-invalid:active:checked + .icon::before, input[type="radio"].before-icon.is-invalid:focus:checked + .icon::before {

                }
/* 成功 */
input[type="radio"].before-icon.is-valid + .icon {
            box-shadow: 0 0 0 2px rgba(107,197,93, 0.25) inset;
        }
input[type="radio"].before-icon.is-valid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #6bc55d inset;
        }
input[type="radio"].before-icon.is-valid:checked + .icon::before {
                background-color: #6bc55d;
            }
/* focus */
input[type="radio"].before-icon.is-valid:active + .icon, input[type="radio"].before-icon.is-valid:focus + .icon {
                background: #6bc55d;
                background: rgba(107,197,93, 0.2);
            }
input[type="radio"].before-icon.is-valid:active + .icon::before, input[type="radio"].before-icon.is-valid:focus + .icon::before {

                }
input[type="radio"].before-icon.is-valid:active:checked + .icon::before, input[type="radio"].before-icon.is-valid:focus:checked + .icon::before {

                }
/* 失敗、エラー */
input[type="checkbox"].before-icon.is-invalid + .icon {
            box-shadow: 0 0 0 2px rgba(236,88,88, 0.25) inset;
            background-color: #fffafa;
        }
input[type="checkbox"].before-icon.is-invalid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #ec5858 inset;
        }
input[type="checkbox"].before-icon.is-invalid:checked + .icon::before {
                border-color: #ec5858;
            }
/* focus */
input[type="checkbox"].before-icon.is-invalid:active + .icon, input[type="checkbox"].before-icon.is-invalid:focus + .icon {
                background: #ec5858;
                background: rgba(236,88,88, 0.2);
            }
input[type="checkbox"].before-icon.is-invalid:active + .icon::before, input[type="checkbox"].before-icon.is-invalid:focus + .icon::before {

                }
input[type="checkbox"].before-icon.is-invalid:active:checked + .icon::before, input[type="checkbox"].before-icon.is-invalid:focus:checked + .icon::before {

                }
/* 成功 */
input[type="checkbox"].before-icon.is-valid + .icon {
            box-shadow: 0 0 0 2px rgba(107,197,93, 0.25) inset;
            background-color: #fafff9;
        }
input[type="checkbox"].before-icon.is-valid:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #6bc55d inset;
        }
input[type="checkbox"].before-icon.is-valid:checked + .icon::before {
                border-color: #6bc55d;
            }
/* focus */
input[type="checkbox"].before-icon.is-valid:active + .icon, input[type="checkbox"].before-icon.is-valid:focus + .icon {
                background: #6bc55d;
                background: rgba(107,197,93, 0.2);
            }
input[type="checkbox"].before-icon.is-valid:active + .icon::before, input[type="checkbox"].before-icon.is-valid:focus + .icon::before {

                }
input[type="checkbox"].before-icon.is-valid:active:checked + .icon::before, input[type="checkbox"].before-icon.is-valid:focus:checked + .icon::before {

                }


/* range */
/*----------------------------------------------------------------------------*/
.field-range {
    padding: 0.75em 0;
}

/* 横線 */

/* つまみ */

input[type="range"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.2);
    /* border-radius: 6px; */
    cursor: pointer;
    position: relative;
}

/*
    &::-webkit-slider-runnable-track {
        @mixin range-track;
    }
    */

input[type="range"]::-moz-range-track {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        margin: auto 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;

    }

/* 横線 */

input[type="range"]::-ms-track {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        margin: auto 0;
        width: 100%;
        height: 2px;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
    }

/* つまみ */

input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-webkit-slider-thumb:hover,
        input[type="range"]::-webkit-slider-thumb:active {
            -webkit-transform: scale(1.25);
                    transform: scale(1.25);
        }

input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-moz-range-thumb:hover,
        input[type="range"]::-moz-range-thumb:active {
            transform: scale(1.25);
        }

input[type="range"]::-ms-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        border: 2px solid #000000;
        border: 2px solid rgba(0, 0, 0, 0.2);
        background-color: #fafafa;
        border-radius: 50%;
    }

input[type="range"]::-ms-thumb:hover,
        input[type="range"]::-ms-thumb:active {
            transform: scale(1.25);
        }

input[type="range"]:focus {
        outline: none;
        background-color: #03a9f4;
        background-color: rgba(3,169,244, 0.75);
    }

/*
        &::-webkit-slider-runnable-track {
            outline: none;
            background-color: rgba($color-link, 0.75);
        }
        */

input[type="range"]:focus::-moz-range-track {
            outline: none;
            background-color: #03a9f4;
            background-color: rgba(3,169,244, 0.75);
        }

input[type="range"]:focus::-ms-track {
            outline: none;
            background-color: #03a9f4;
            background-color: rgba(3,169,244, 0.75);
        }

input[type="range"]:focus::-webkit-slider-thumb {
            outline: none;
            border-color: #03a9f4;
            -webkit-transform: scale(1.25);
                    transform: scale(1.25);
        }

input[type="range"]:focus::-moz-range-thumb {
            outline: none;
            border-color: #03a9f4;
            transform: scale(1.25);
        }

input[type="range"]:focus::-ms-thumb {
            outline: none;
            border-color: #03a9f4;
            transform: scale(1.25);
        }

/* disabled */

input[type="range"]:disabled,
    input[type="range"][readonly] {
        cursor: default !important;
    }

/*
        &::-webkit-slider-runnable-track {

        }
        */

input[type="range"]:disabled::-moz-range-track, input[type="range"][readonly]::-moz-range-track {

        }

/* 横線 */

input[type="range"]:disabled::-ms-track, input[type="range"][readonly]::-ms-track {

        }

/* つまみ */

input[type="range"]:disabled::-webkit-slider-thumb, input[type="range"][readonly]::-webkit-slider-thumb {
            -webkit-transform: none !important;
                    transform: none !important;
            cursor: default !important;
        }

input[type="range"]:disabled::-moz-range-thumb, input[type="range"][readonly]::-moz-range-thumb {
            transform: none !important;
            cursor: default !important;
        }

input[type="range"]:disabled::-ms-thumb, input[type="range"][readonly]::-ms-thumb {
            transform: none !important;
            cursor: default !important;
        }

/* 失敗、エラー */

input[type="range"].is-invalid {
        background-color: #ec5858;
    }

/*
        &::-webkit-slider-runnable-track {
            background-color: $color-danger;
        }
        */

input[type="range"].is-invalid::-moz-range-track {
            background-color: #ec5858;

        }

/* 横線 */

input[type="range"].is-invalid::-ms-track {
            background-color: #ec5858;
        }

/* つまみ */

input[type="range"].is-invalid::-webkit-slider-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

input[type="range"].is-invalid::-moz-range-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

input[type="range"].is-invalid::-ms-thumb {
            border-color: #ec5858;
            background-color: #fffafa;
        }

/* 成功 */

input[type="range"].is-valid {
        background-color: #6bc55d;
    }

/*
        &::-webkit-slider-runnable-track {
            background-color: $color-success;
        }
        */

input[type="range"].is-valid::-moz-range-track {
            background-color: #6bc55d;
        }

/* 横線 */

input[type="range"].is-valid::-ms-track {
            background-color: #6bc55d;
        }

/* つまみ */

input[type="range"].is-valid::-webkit-slider-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }

input[type="range"].is-valid::-moz-range-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }

input[type="range"].is-valid::-ms-thumb {
            border-color: #6bc55d;
            background-color: #fafff9;
        }


/* ファイル */
/*----------------------------------------------------------------------------*/
/* MW WP Formだと右側に余白ができる */
.field.field-file input[type="file"] {
        margin-right: 0;
        display: block;
    }
/* MW WP Formだと「×」ボタンが表示されるので消す */
.field.field-file .mwform-file-delete {
        margin-left: -0.5em;
        flex: 0 0 0%;
        display: none;
    }


/* 検索 */
/*----------------------------------------------------------------------------*/
.field.field-search {
    position: relative;
}
@media only screen and (min-width: 770px) {
.field.field-search {
        width: 100%
}

    }
.field.field-search input {
        border-radius: 6px;
        width: 45px;
    }
.field.field-search .icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #03a9f4;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
@media all and (-ms-high-contrast: none) {
.field.field-search .icon {
            top: 12px
    }
        }


/* 年月日 */
/*----------------------------------------------------------------------------*/
.field-date > span {
        display: inline-block;
        vertical-align: middle;
    }
.field-date > span.unit {
            margin: 0 10px;
        }
.field-date > span input {
            width: 125px;
        }
.field-date + p {
        margin-top: 0 !important;
    }


/* 同意チェックボックス */
/*----------------------------------------------------------------------------*/
.field.field-agree {
    display: flex;
    align-items: center;
    justify-content: center;
}
.field.field-agree > * {
        margin: 0;
        flex: 0 0 auto;
    }
.field.field-agree > label {
        margin-right: 0.5em;
        display: flex;
        align-items: center;
    }
.field.field-agree > label input[type="checkbox"].before-icon {
            position: absolute;
        }
.field.field-agree > label input[type="checkbox"].before-icon + .icon {
            margin: 0;
        }
.field.field-agree > span {
        margin-left: 0;
    }


/* 画像radio */
/*----------------------------------------------------------------------------*/
.field-img-radio {
    position: relative;
}
.field-img-radio > label {
        flex: 1 1 auto;
    }
.field-img-radio > label img {
            width: 100%;
        }
.field-img-radio input,
    .field-img-radio .icon {
        opacity: 0;
        position: absolute;
    }
.field-img-radio input ~ img {
        opacity: 0.5;
    }
.field-img-radio input:checked ~ img {
        opacity: 1.0;
        box-shadow: 0 0 0 4px #fff inset;
    }


/* バリデーション */
/*----------------------------------------------------------------------------*/
.field + .invalid-feedback,
.field + .valid-feedback {
    margin-top: 0;
    margin-bottom: 20px;
}
.field + .invalid-feedback:last-child, .field + .valid-feedback:last-child {
        margin-bottom: 0;
    }

/* oお問合わせフォーム
地図取得表示を隠す為の表示 */
/*----------------------------------------------------------------------------*/
.form-hidden {
    position: fixed;
    top: -5000px;
}

/* oお問合わせフォーム */
/*----------------------------------------------------------------------------*/

section.form-area {
    padding: 40px 20px 40px;
}

section.form-area h3.h3-02{
        margin-bottom: 20px;
    }

section.form-area p {
        font-size: 12px;
    }

section.form-area p.title {
        font-size: 14px;
        margin-bottom: 20px;
    }

section.form-area .text {
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

section.form-area .text p {
            letter-spacing: normal;
            margin-top: 0;
        }

section.form-area table,
    section.form-area table tbody,
    section.form-area table td,
    section.form-area table tfoot,
    section.form-area table th,
    section.form-area table thead,
    section.form-area table tr {
        border: 2px solid #fff;
    }

section.form-area table tr {
        padding: 10px 0;
    }

section.form-area table tr:last-child {
            padding-bottom: 20px;
        }

section.form-area table tbody td > * + * {
        margin-top: 5px;
    }

.confirm section.form-area .text-note {
        display: none;
    }

@media only screen and (min-width: 0px) and (max-width: 660px) {
        section.form-area table,
        section.form-area table tbody,
        section.form-area table td,
        section.form-area table tfoot,
        section.form-area table th,
        section.form-area table thead,
        section.form-area table tr {
            border-left-style: none;
            border-right-style: none;
            border-bottom-style: none !important;
        }
    }

@media only screen and (min-width: 480px) {
    }

@media only screen and (min-width: 660px) {
    }

@media only screen and (min-width: 770px) {
    }

@media only screen and (min-width: 1000px) {

section.form-area {
        padding: 50px 40px 50px
}
    }

/* フォーム用カレンダー*/
/*----------------------------------------------------------------------------*/
table.ui-datepicker-calendar tr th,
        table.ui-datepicker-calendar tr td {
            padding: 0 !important;
        }

/* ※印 CSS */
.require-mark {
    color: #03a9f4;
}
th span.require-mark  {
    margin-left: 10px;
}

/* 関連画像_選択されていません表示 */
.field span.mwform-file-delete {
    flex: 0 0 20px;
}

/* 利用規約同意ボタン CSS */
.request-agree {
    display: flex;
    flex-direction: column;
    line-height: 1.7;
}
.request-agree div {
        display: flex;
        justify-content: center;
    }
.mwform-checkbox-field label {
    display: flex;
}


/* 送信・確認・戻るボタン CSS */
/* コンテンツ背景 */
.published-provider table {
    background-color: #faf6e9;
}
.published-provider table th {
        border-right: 2px solid #fff;
    }


/* 確認ボタン */
.contact-footer {
    display: flex;
}
.contact-footer .btn-arrow-left {
        background-image: url(../img/common/icon-arrow-simple-left.svg);
        background-repeat : no-repeat;
        background-position : left 1.5em center;
        background-size: 0.7em;
    }
.contact-footer .btn-arrow-right {
        background-image: url(../img/common/icon-arrow-simple.svg);
        background-repeat : no-repeat;
        background-position : right 1.5em center;
        background-size: 0.7em;
    }
.contact-footer .btn-fixed {
        background-color: #777777;
        color: #fff;
        box-shadow: none;
    }
.contact-footer .btn.btn-article {
        margin-bottom: 0;
    }
.confirm .contact-footer .btn.btn-article,
        .complete .contact-footer .btn.btn-article {
            min-width: 0;
            width: 130px;
        }
@media only screen and (min-width: 660px) {
.contact-footer .btn.btn-article {
            min-width: 200px;
            width: 245px !important
    }
        }
.contact-footer input[type="submit"] {
        cursor: pointer;
    }



/* 送信完了ページ
--------------------------------------*/
.back-home {
    margin-top: 50px;
    text-align: center;
}
.back-home a {
        margin-bottom: 0 !important;
    }



/* IE対応
--------------------------------------*/
input::-ms-clear {
    visibility:hidden
}
/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    padding: .75em 1em;
    line-height: 1.5;
    border-radius: 4px;
    background-color: #aaa;
    color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
}
.alert .btn {
        margin-left: auto;
        padding: 0;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        flex: 0 0 2em;
        font-size: .5em;
        line-height: 1;
        border: none;
        border-radius: 50%;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.alert .btn:hover {
        color: #fff;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.5);
    }
.alert.alert-danger {
        background-color: #ec5858;
    }
.alert.alert-warning {
        background-color: #e6bd41;
    }
.alert.alert-success {
        background-color: #6bc55d;
    }

.alert-group {
    position: fixed;
    top: calc(50px + 5px);
    left: 5px;
    right: 5px;
    z-index: 10000;
}

.alert-group .alert:not(:last-child) {
        margin-bottom: 5px;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

.alert-group {
        top: calc(100px + 5px)
}
    }

.alert-group.alert-group-static {
        position: static;
    }

.alert-group.alert-group-static .alert {
            position: static;
        }

/* タグ、バッジ、ラベル(Semantic UI) */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* https://semantic-ui.com/elements/label.html */
/*----------------------------------------------------------------------------*/

.badge-group {
    display: flex;
    align-items: center;
}

.badge-group > *:not(:last-child) {
        margin-right: 0.5em;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

.badge {
    border: none;
    font-size: 12px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* ボタンのサイズ */

.badge.badge-lg {
        font-size: 12px;
        font-size: 1rem;
    }

.badge.badge-sm {
        font-size: 10px;
    }

/* ボタンの形 */

.badge {
        padding: 0.5em 1.0em;
    }

.badge.badge-rect {
        padding: 0.5em 1.0em;
        border-radius: 4px;
    }

.badge.badge-pill {
        padding: 0.5em 1.0em;
        border-radius: calc(1em + 1em + 1em);
    }

.badge.badge-circle {
        padding: 0;
        width: 2em;
        height: 2em;
        border-radius: 2em;
    }

/* ボタンの枠線、背景色 */

.badge,
    .badge.badge-fill {
        background-color: #222;
        color: #fff;

    }

.badge.badge-outline {
        background-color: transparent;
        color: #222;
    }

.badge-danger {
    box-shadow: 0 0 0 1px #ec5858 inset;
}

.badge-danger,
    .badge-danger.badge-fill {
        background-color: #ec5858;
        color: #fff;

    }

.badge-danger.badge-outline {
        background-color: transparent;
        color: #ec5858;
    }
.badge-warning {
    box-shadow: 0 0 0 1px #e6bd41 inset;
}
.badge-warning,
    .badge-warning.badge-fill {
        background-color: #e6bd41;
        color: #fff;

    }
.badge-warning.badge-outline {
        background-color: transparent;
        color: #e6bd41;
    }
.badge-success {
    box-shadow: 0 0 0 1px #6bc55d inset;
}
.badge-success,
    .badge-success.badge-fill {
        background-color: #6bc55d;
        color: #fff;

    }
.badge-success.badge-outline {
        background-color: transparent;
        color: #6bc55d;
    }

/* 記事リスト・ランキング用カテゴリータグのサイズ設定
---------------------------------------------------- */
.category {
    color: #fff;
    vertical-align: middle;
    text-align: center;
    line-height: 1;
}
.category.category-tag-sm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 20px;
    }
.category.category-tag-md {
        position: relative;
        display: inline-block;
        width: 83px;
        flex: 0 0 83px;
        padding: 5px 0;
        margin-right: 8px;
        font-size: 12px;
    }
@media only screen and (min-width: 1000px) {
.category.category-tag-md {
            width: 120px;
            padding-top: 8px;
            padding-bottom: 8px;
            margin-right: 15px;
            font-size: 14px;
            flex: 0 0 120px
    }
        }
.category.badge {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        padding: 2px;
    }
.category.badge span {
            font-size: 10px;
            font-weight: bold;
        }
.category.badge span.icon::before {
                margin-bottom: 5px !important;
            }
@media only screen and (min-width: 1000px) {
.category.badge {
            font-size: 12px;
            width: 78px;
            height: 78px
    }
            .category.badge.icon::before {
                margin-bottom: 5px !important;
            }
        }

/* カテゴリーの色分け
---------------------------------------------------- */
.tea-flower {
    background-color: #cddc39;
}
.event {
    background-color: #ed3223;
}
.musics {
    background-color: #00bcd4;
}
.traditional-culture {
    background-color: #673ab7;
}
.theater {
    background-color: #ffc107;
}
.art {
    background-color: #9c27b0;
}
.sport {
    background-color: #4caf50;
}
.lecture-course {
    background-color: #795548;
}
.movie {
    background-color: #ff5722;
}
.media {
    background-color: #3f51b5;
}
/* マップ */
/*----------------------------------------------------------------------------*/
.map-view-area {
    margin-bottom: 30px;
}
.map-view-area .bg-white-02 {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        box-sizing: border-box;
    }
@media only screen and (min-width: 660px) {
.map-view-area .bg-white-02 {
            padding-left: 40px;
            padding-right: 40px
    }
        }
.map-view-area .map {
        width:100%;
        height: 600px;
    }

/* アーティスト一覧、会場一覧リスト以外の記述 */
/*----------------------------------------------------------------------------*/
.artist-area-content div, .place-area-content div {
        margin-bottom: 40px;
    }
.artist-area-content div:last-child, .place-area-content div:last-child {
            margin-bottom: 0 !important;
        }

/* 404ページ*/
/*----------------------------------------------------------------------------*/
.error404 .main-content-area {
        margin-right: 0;
    }
.single article section {
            margin-top: 20px;
            position: relative;

        }
.single article section .update-information {
                top: -65px;
                left: 0;
                right: 0;

            }
@media only screen and (min-width: 1000px) {
.single article section .update-information {
                    width: auto;
                    position: absolute;
                    display: flex;
                    top: -75px;
                    left: 0px;
                    right: 0;
                    height: 30px;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    border-radius: 6px 6px 0 0

            }

                }
.single article section .update-information + p {
                    padding-bottom: 10px;
                    border-bottom:1px solid #c4c4c4;
                    margin-bottom: 20px;
                }
@media only screen and (min-width: 1000px) {
.single article section .update-information + p {
                        padding-bottom: 20px
                }
                    }
.single article section p {
                font-size: 12px;
                line-height: 1.7;
            }
@media only screen and (min-width: 1000px) {
.single article section p {
                    margin-top: 0
            }
                }
.single article section p.update_information {
                    color: #e91e63;
                }
.single article section p + p {
                    margin-top: 20px;
                }
.single article section .event-title {
                display: flex;
            }
.single article section .event-title .title {
                    flex: 1;
                }
.single article section .event-introduction {
                margin-bottom: 40px;
            }
@media only screen and (min-width: 1000px) {
.single article section .event-introduction {
                    margin-bottom: 50px
            }
                }
.single article section .event-map {
                margin-bottom: 40px;
            }
@media only screen and (min-width: 1000px) {
.single article section .event-map {
                    margin-bottom: 50px
            }
                }
/* [課題] 複数ボタンのときのクラスを用意 */
/* https://getbootstrap.com/docs/4.1/components/buttons/*/
/* https://semantic-ui.com/elements/button.html */
/*----------------------------------------------------------------------------*/

.btn-group > *:not(:last-child) {
        margin-bottom: 0.5em;
    }

@media only screen and (min-width: 480px) {

.btn-group {
        display: flex;
        align-items: center
}

        .btn-group > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 0.5em;
        }
    }

input.btn,
button.btn {
    outline: none;
}

.btn {
    min-width: 200px;
    border: none;
    font-size: 1em;
    text-align: center;
    line-height: 1;
    box-shadow: 0 0 0 2px #03a9f4 inset;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    box-sizing: border-box;
}

/* ボタンのサイズ */

.btn.btn-lg {
        font-size: 1.1em;
    }

@media only screen and (min-width: 480px) {

.btn.btn-lg {
            font-size: 1.2em;
            width: 320px
    }
        }

.btn.btn-sm {
        min-width: auto;
        font-size: 0.85em;
    }

.btn.btn-block {
        display: block;
        max-width: 320px;
    }

.btn.btn-fluid {
        display: block;
        width: 100%;
        max-width: 100%;
        text-align: left;
    }

@media only screen and (min-width: 480px) {

.btn.btn-fluid {
            text-align: center
    }
        }

/* ボタンの形 */

.btn.btn-article {
        width: 245px;
        height: 50px;
        border-radius: 27px;
        display: flex;
        margin: 0 auto 40px auto;
    }

.btn.btn-article a {
            color: #fff;
        }

.btn.btn-rect {
        padding: 1.25em 2em;
        border-radius: 4px;
    }

.btn.btn-rect.btn-lg {
            padding: 1.25em 2em;
        }

.btn.btn-rect.btn-sm {
            padding: 0.75em 1.5em;
        }

.btn,
    .btn.btn-pill {
        padding: 12px;
        border-radius: 6px;

    }

.btn.btn-lg, .btn.btn-pill.btn-lg {
            padding: 1.25em 3em;
            box-sizing: border-box;
        }

.btn.btn-sm, .btn.btn-pill.btn-sm {
            padding: 0.75em 1.5em;
        }

.btn.btn-circle {
        padding: 0;
        width: 2.5em;
        min-width: auto;
        height: 2.5em;
        min-height: auto;
        border-radius: 2.5em;

        line-height: 1;
        letter-spacing: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }

.btn.btn-circle .icon {
            width: 1em;
            height: 1em;
            line-height: 1;
            letter-spacing: 0;

            display: flex;
            align-items: center;
            justify-content: center;
        }

.btn.btn-circle .icon::before {
                line-height: 1;
                letter-spacing: 0;
                display: block;
            }

/* 矢印 */

.btn {
        position: relative;
    }

.btn::after {
        content: "\EA02";
        display: inline-block;
        font-family: "original-icon-font";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 0.75em;
        text-align: right;
        margin: auto 0 auto auto;
        width: 1em;
        height: 1em;
        display: block;
        position: absolute;
        top: 0;
        right: 1.0em;
        bottom: 0;

        font-size: 1.25em;

        transition: -webkit-transform 0.2s ease 0.0s;

        transition: transform 0.2s ease 0.0s;

        transition: transform 0.2s ease 0.0s, -webkit-transform 0.2s ease 0.0s;
        -webkit-transform: translate(0, 0) scale(1, 1);
                transform: translate(0, 0) scale(1, 1);
    }

.btn.btn-search {
        width: calc(100% - 20px);
        font-size: 16px;
        font-weight: bold;
    }

.btn.btn-search span {
            display: inline-block;
            margin-left: 40px;
        }

.btn.btn-search::after {
        content: "\EA06";
        display: inline-block;
        font-family: "original-icon-font";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        margin: auto 0 auto auto;
        width: 1em;
        height: 1em;
        display: block;
        position: absolute;
        top: 0;
        left: 2.0em;
        right: auto;
        bottom: 0;

        font-size: 26px;
    }

.btn:hover::after {
        /* transform: translate(0.5em, 0) scale(1, 1); */
    }

.btn.btn-prev::after {
        content: "\EA03";

        margin: auto auto auto 0;
        right: initial;
        left: 1.0em;
        -webkit-transform: translate(0, 0) scale(-1, 1);
                transform: translate(0, 0) scale(-1, 1);
    }

.btn.btn-prev:hover::after {
        -webkit-transform: translate(-0.5em, 0) scale(-1, 1);
                transform: translate(-0.5em, 0) scale(-1, 1);
    }

.alert .btn::after, .btn.btn-sm::after, .btn.btn-noicon::after, .btn.btn-circle::after {
            display: none;
        }

/* ボタンの文字色、背景色、枠線の色(box-shadow) */

.btn,
    .btn.btn-fill {
        background-color: #03a9f4;
        color: #fff;
    }

.btn:hover, .btn.btn-fill:hover {
            /* background-color: transparent; */
            /* color: $color-btn; */
            color: #fff;
            opacity: 0.7;
        }

.btn.btn-outline {
        background-color: transparent;
        color: #03a9f4;
    }

.btn.btn-outline:hover {
            background-color: #03a9f4;
            color: #fff;
        }

.btn.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #222;
    }

.btn.btn-skeleton:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #222;
        }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

.btn.disabled,
        .btn.disabled:hover,
        .btn:disabled,
        .btn:disabled:hover,
        .btn[readonly],
        .btn[readonly]:hover {
            background-color: #777;
            box-shadow: 0 0 0 2px #777 inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;
        }

.btn.disabled::after, .btn.disabled:hover::after, .btn:disabled::after, .btn:disabled:hover::after, .btn[readonly]::after, .btn[readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }

.btn-white {
    box-shadow: 0 0 0 2px #fff inset;
}

/* ボタンの文字色、背景色、枠線の色(box-shadow) */

.btn-white,
    .btn-white.btn-fill {
        background-color: #fff;
        color: #03a9f4;
    }

.btn-white:hover, .btn-white.btn-fill:hover {
            /* background-color: transparent; */
            /* color: #fff; */
            opacity: 0.7;
        }

.btn-white.btn-outline {
        background-color: transparent;
        color: #fff;
    }

.btn-white.btn-outline:hover {
            background-color: #fff;
            color: #03a9f4;
        }

.btn-white.btn-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #fff;
    }

.btn-white.btn-skeleton:hover {
            background-color: #ffffff;
            background-color: rgba(255, 255, 255, 0.2);
            color: #fff;
        }

.pagination {
    display: flex;
    align-items: center;
}

/*
    box-shadow: 0 0 0 2px #777 inset;
    border-radius: 4px;
    */

.pagination > * {
        padding: 0.75em;
        min-width: calc(1em + 0.75em + 0.75em);
        min-height: calc(1em + 0.75em + 0.75em);

        /* .btn { } からコピペ */
        border: none;
        font-size: 1em;
        text-align: center;
        line-height: 1;
        box-shadow: 0 0 0 2px #777 inset;
        display: inline-block;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        box-sizing: border-box;

        /* &.btn-skeleton { } からコピペ */
        background-color: transparent;
        box-shadow: none;
        color: #222;
    }

.pagination > *:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #222;

            position: relative;
            z-index: 1000;
        }

.pagination > *:not(:first-child) {
        margin-left: -2px;
    }

.pagination > *.current,
    .pagination > *.current:hover {
        box-shadow: 0 0 0 2px #03a9f4 inset;
        background-color: #03a9f4;
        position: relative;
        z-index: 1000;
    }

.pagination > span,
        .pagination > span:hover,
        .pagination > *.disabled,
        .pagination > *.disabled:hover,
        .pagination > *:disabled,
        .pagination > *:disabled:hover,
        .pagination > *[readonly],
        .pagination > *[readonly]:hover {
            background-color: #eee;
            box-shadow: 0 0 0 2px #eee inset;
            color: #aaa;
            cursor: default;
            opacity: 1.0;
        }

.pagination > span::after, .pagination > span:hover::after, .pagination > *.disabled::after, .pagination > *.disabled:hover::after, .pagination > *:disabled::after, .pagination > *:disabled:hover::after, .pagination > *[readonly]::after, .pagination > *[readonly]:hover::after {
                -webkit-transform: none;
                        transform: none;
            }

.pagination > *:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

.pagination > *:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }


html .fc .fc-view-container .fc-view .fc-widget-header {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }


html .fc .fc-view-container .fc-view .fc-widget-header th.fc-day-header {
                        background: none;
                        border-color: transparent;
                        font-weight: bold;
                    }


html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sat {
                        color: #3c83af;
                    }


html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sun {
                        color: #a23737;
                    }


html .fc .fc-day-grid-event {
            margin: 0;
        }


html .fc .fc-day-top {
            text-align: center;
        }


html .fc .fc-day-top .fc-day-number {
                float: none;
            }


html .fc .fc-day-top.fc-past {
            opacity: 0.25;
        }


/* 文字 */


html .fc .fc-content-skeleton {
            padding: 0;
        }


html .fc .fc-content-skeleton table,
            html .fc .fc-content-skeleton thead,
            html .fc .fc-content-skeleton tbody,
            html .fc .fc-content-skeleton tfoot,
            html .fc .fc-content-skeleton tr,
            html .fc .fc-content-skeleton th,
            html .fc .fc-content-skeleton td {
                border: none;
            }


html .fc .fc-content-skeleton .fc-event-container a.fc-event,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event-dot {
                    background-color: transparent;
                    border: none;
                }


html .fc .fc-content-skeleton .fc-event-container a.fc-event {
                    text-align: center;
                    font-size: 16px;
                }


/* 青 */


html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible:hover {
                    color: #3c83af;
                }


/* 赤 */


html .fc .fc-content-skeleton .fc-event-container a.fc-event.few,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.few:hover {
                    color: #a23737;
                }


html .fc .fc-content-skeleton .fc-event-container a.fc-event.few .fc-title::before {
                        content: "残";
                        margin-right: 0.5em;
                        color: #222;
                        font-size: 0.8em;
                        display: inline-block;
                    }


html .fc .fc-content-skeleton .fc-event-container a.fc-event.past {
                    color: transparent !important;
                }


/* グレー */


html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible:hover,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed:hover {
                    color: #999;
                }


html .fc .fc-bgevent-skeleton td.fc-bgevent {
                background: none;
            }


/* 背景色 */


/* 青 */


html .fc .fc-bg td.bg-possible {
                background: #e7f1f7;
            }


/*td.bg-possible:hover,*/


html .fc .fc-bg td.bg-possible.active {
                background: #cde5f3;
            }


/* 赤 */


html .fc .fc-bg td.bg-few {
                background: #f8e6e6;
            }


/*td.bg-impossible:hover,
            td.bg-few:hover,*/


html .fc .fc-bg td.bg-impossible.active,
            html .fc .fc-bg td.bg-few.active {
                background: #fdd2d2;
            }


/* 黄 */


html .fc .fc-bg td.fc-today {
                background: #fffacc;
            }


/*td.fc-today:hover,*/


html .fc .fc-bg td.fc-today.active {
                background: #fff8b9;
            }


/* グレー */


html .fc .fc-bg td.fc-past,
            html .fc .fc-bg td.bg-impossible,
            html .fc .fc-bg td.bg-closed {
                background: #f2f2f2 !important;
            }


html .fc .fc-bg td.fc-disabled-day {
                background: #ddd;
                opacity: 1.0;
            }


html .fc .fc-toolbar.fc-header-toolbar .fc-left,
            html .fc .fc-toolbar.fc-header-toolbar .fc-right,
            html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                margin-bottom: 1em;
                display: block;
            }


html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    float: none;
                }


html .fc .fc-toolbar.fc-header-toolbar .fc-center > *:not(:last-child) {
                    margin-right: 1em;
                }


@media only screen and (min-width: 480px) {


html .fc .fc-toolbar.fc-header-toolbar {
                display: flex;
                align-items: center
        }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                    margin-bottom: 0;
                }

                    html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                        margin: 0;
                        text-align: initial;
                    }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left {
                    margin-right: auto;
                }
            }


@media only screen and (min-width: 660px) {

            }


@media only screen and (min-width: 770px) {

            }


@media only screen and (min-width: 1000px) {

            }


html .fc .fc-toolbar h2 {
            font-size: 18px;
            font-size: 1.5rem;
        }


html .fc .fc-state-default,
        html .fc .fc-state-default.fc-corner-left,
        html .fc .fc-state-default.fc-corner-right {
            outline: none;

            border: none;
            font-size: 0.85em;
            text-align: center;
            line-height: 1;
            box-shadow: 0 0 0 2px #03a9f4 inset;
            display: inline-block;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            /*  */
            padding: 0.75em 1.5em;
            border-radius: calc(1em + 1em + 1em);

            /*  */
            background-color: #03a9f4;
            color: #fff;

            /* 不要記述削除 */
            float: none;
            height: auto;
            text-shadow: none;
            background-image: none;

            transition: opacity 0.15s ease-in-out 0s,
                        background 0.15s ease-in-out 0s,
                        background-color 0.15s ease-in-out 0s,
                        border-radius 0.15s ease-in-out 0s,
                        border-color 0.15s ease-in-out 0s,
                        color 0.15s ease-in-out 0s,
                        box-shadow 0.15s ease-in-out 0s,
                        text-shadow 0.15s ease-in-out 0s,
                        outline 0.15s ease-in-out 0s;
        }


html .fc .fc-state-default:hover, html .fc .fc-state-default.fc-corner-left:hover, html .fc .fc-state-default.fc-corner-right:hover {
                background-color: transparent;
                color: #03a9f4;
            }


html .fc .fc-state-disabled,
        html .fc .fc-state-disabled:hover,
        html .fc .fc-state-disabled.fc-state-hover,
        html .fc .fc-state-disabled.fc-state-hover:hover {
            background-color: #fff;
            color: #fff;
        }


html .fc .fc-state-hover {
            background-position: initial;
        }


html .fc .fc-scroller {
            overflow-x: initial !important;
            overflow-y: initial !important;
            height: initial !important;
        }


html .fc.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            float: none;
        }


html .fc.fc .fc-row .fc-content-skeleton table,
        html .fc.fc .fc-row .fc-content-skeleton td,
        html .fc.fc .fc-row .fc-helper-skeleton td {
            text-align: center;
            vertical-align: middle;
        }


/* 日付 カスタマイズ */
/*----------------------------------------------------------------------------*/
body {
    box-sizing: border-box;
}
body .ui-corner-all,
    body .ui-corner-bottom,
    body .ui-corner-right,
    body .ui-corner-br {
        border-radius: 0;
    }
body .hasDatepicker .ui-corner-all {
            box-sizing: border-box;
        }
body .hasDatepicker .ui-datepicker{
            width: 100%;
            color: #222;
            font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
            font-size: 16px;
            padding: 15px;
            border-style: none;
        }
body .hasDatepicker .ui-datepicker .ui-widget-header {
                border-style: none;
                background: #fff;
            }
body .hasDatepicker .ui-datepicker .ui-datepicker-header{
                padding: 0;
            }
body .hasDatepicker .ui-datepicker .ui-datepicker-header select.ui-datepicker-month,
                body .hasDatepicker .ui-datepicker .ui-datepicker-header select.ui-datepicker-year {
                padding: 10px 15px;
                background-image: none;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-icon {
                    text-indent: 0;
                    background-image: none !important;
                    font-size: 0;
                    height: auto;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-icon::after {
                    display: inline-block;
                    font-family: "original-icon-font";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    vertical-align: baseline;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: "\EA02";
                    color: #222;
                    font-size: 19px;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-icon-circle-triangle-w.ui-icon::after {
                    -webkit-transform:rotate(180deg);
                            transform:rotate(180deg);
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-state-hover {
                    background-color: #fff;
                    border-style: none;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
                body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
                    top: 0px;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-next span, body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev span {
                        margin-left: 0;
                        margin-right: 0;
                    }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev  {
                    left: -10px;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover {
                    right: 2px;
                    opacity: 0.7;
                    cursor: pointer;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
                    left: -10px;
                    opacity: 0.7;
                    cursor: pointer;
                }
body .hasDatepicker .ui-datepicker .ui-datepicker-calendar {
                margin-top: 10px;
                border-style: none;
            }
body .hasDatepicker .ui-datepicker .ui-datepicker-calendar tr th,
                    body .hasDatepicker .ui-datepicker .ui-datepicker-calendar tr td {
                        padding: 0 !important;
                        border-right: 4px solid #fff;
                    }
body .hasDatepicker .ui-datepicker table {
                margin: 0;
            }
body .hasDatepicker .ui-datepicker table tr,
                body .hasDatepicker .ui-datepicker table thead,
                body .hasDatepicker .ui-datepicker table tbody {
                border-style: none;
                }
body .hasDatepicker .ui-datepicker table thead:first-child {
                    margin-top: 20px;
                }
body .hasDatepicker .ui-datepicker table th{
                    
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    width: 32px;
                }
body .hasDatepicker .ui-datepicker table th span {
                        font-size: 14px;
                        margin-bottom: 5px;
                        display: block;
                        background-color: #faf6e9;
                        padding: 2px 0.25px !important;
                    }
body .hasDatepicker .ui-datepicker table td {
                    padding-top: 0 !important;
                }
body .hasDatepicker .ui-datepicker table th:first-child {
                    color: #e91e63;
                }
body .hasDatepicker .ui-datepicker table th:last-child {
                    color: #03a9f4;
                }
body .hasDatepicker .ui-datepicker table th:not(:first-child) {
                    /* border-left: solid 5px #fff; */
                }
body .hasDatepicker .ui-datepicker table a {
                    text-align: center;
                }
body .hasDatepicker .ui-datepicker table tbody tr a {
                        width: 32px;
                        height: 32px;
                        padding: 0;
                    }
body .hasDatepicker .ui-datepicker table tbody tr td.ui-datepicker-today {
                        text-align: center;
                        line-height: 32px;
                        vertical-align: middle;
                    }
body .hasDatepicker .ui-datepicker table tbody tr td.ui-datepicker-today a {
                            background-color: #d2effc;
                            border-radius: 100%;
                        }
body .hasDatepicker .ui-datepicker table tbody tr td.ui-datepicker-current-day {
                        line-height: 32px;
                    }
body .hasDatepicker .ui-datepicker table tbody tr td.ui-datepicker-current-day a {
                            display: flex;
                            justify-content: center;
                            background-color: #03a9f4;
                            border-radius: 100%;
                            max-width: 0 auto;
                            color: #fff !important;
                        }
body .hasDatepicker .ui-datepicker table tbody tr td:first-child:not(.ui-datepicker-unselectable) a {
                        color: #e91e63;
                    }
body .hasDatepicker .ui-datepicker table tbody tr td:last-child:not(.ui-datepicker-unselectable) a {
                        color: #03a9f4;
                    }
body .hasDatepicker .ui-datepicker .ui-state-default {
                line-height: 2.4;
            }
body .hasDatepicker .ui-datepicker .ui-state-default,
            body .hasDatepicker .ui-datepicker .ui-widget-content .ui-state-default,
            body .hasDatepicker .ui-datepicker .ui-widget-header .ui-state-default,
            body .hasDatepicker .ui-datepicker .ui-widget.ui-widget-content {
                border-style: none;
                background: #fff;
                font-size: 14px;
                font-weight: bold;
            }
body .hasDatepicker .ui-datepicker .ui-state-highlight,
            body .hasDatepicker .ui-datepicker .ui-widget-content .ui-state-highlight,
            body .hasDatepicker .ui-datepicker .ui-widget-header .ui-state-highlight {
                border-color: #03a9f4;
                color: #222;
            }
body .datetimepicker-date {
        padding-right: 24px;
        background-image: url(../img/common/icon-calender-margin.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 32px 16px;
        cursor: pointer;
    }
body .xdsoft_datetimepicker table {
            display: table;
        }
body .xdsoft_datetimepicker table thead {
                display: table-header-group;
            }
body .xdsoft_datetimepicker table tbody {
                display: table-header-group;
            }
body .xdsoft_datetimepicker table tfoot {
                display: table-footer-group;
            }
body .xdsoft_datetimepicker table tr {
                display: table-row;
            }
body .xdsoft_datetimepicker table td,
            body .xdsoft_datetimepicker table th {
                display: table-cell;
            }
@media only screen and (min-width: 0px) and (max-width: 479px) {
                body .xdsoft_datetimepicker table,
                body .xdsoft_datetimepicker table thead,
                body .xdsoft_datetimepicker table tbody,
                body .xdsoft_datetimepicker table tfoot,
                body .xdsoft_datetimepicker table tr,
                body .xdsoft_datetimepicker table td,
                body .xdsoft_datetimepicker table th,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal),
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) thead,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tbody,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tfoot,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) tr,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) td,
                body .xdsoft_datetimepicker table:not(.not-responsive):not(.table-border-horizontal) th {
                    border-right-style: none;
                    border-left-style: none;
                }
        }

.module-group .content > *,
        .module-group .content > ul > li,
        .module .content > *,
        .module .content > ul > li,
        .border-group .content > *,
        .border-group .content > ul > li,
        .border .content > *,
        .border .content > ul > li,
        .card-group .content > *,
        .card-group .content > ul > li,
        .card .content > *,
        .card .content > ul > li,
        .banner-group .content > *,
        .banner-group .content > ul > li,
        .banner .content > *,
        .banner .content > ul > li {
            display: block;
        }

.module-group .content > *:not(:last-child), .module .content > *:not(:last-child), .border-group .content > *:not(:last-child), .border .content > *:not(:last-child), .card-group .content > *:not(:last-child), .card .content > *:not(:last-child), .banner-group .content > *:not(:last-child), .banner .content > *:not(:last-child) {
            margin-bottom: 10px;
        }

.module-group .text, .module .text, .border-group .text, .border .text, .card-group .text, .card .text, .banner-group .text, .banner .text {
        line-height: calc(1em + 10px);
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }


.module-group,
.border-group,
.card-group {
    margin: -10px;
}


.module-group > *,
    .module-group > ul > li,
    .border-group > *,
    .border-group > ul > li,
    .card-group > *,
    .card-group > ul > li {
        margin: 10px;
        box-sizing: border-box;
    }


@media only screen and (min-width: 480px) {


.module-group,
.border-group,
.card-group {
        display: flex;
        flex-wrap: wrap
}

        .module-group > *,
        .module-group > ul > li,
        .border-group > *,
        .border-group > ul > li,
        .card-group > *,
        .card-group > ul > li {
            flex: 1 0 40%;
        }
    }


@media only screen and (min-width: 660px) {

    }


@media only screen and (min-width: 770px) {
        .module-group > *,
        .module-group > ul > li,
        .border-group > *,
        .border-group > ul > li,
        .card-group > *,
        .card-group > ul > li{
            flex: 1 0 20%;
        }
    }


@media only screen and (min-width: 1000px) {

    }

.module-group > * > *,
    .module-group > * > ul > li,
    .module > *,
    .module > ul > li {
        display: block;
    }

.module-group > * > *:not(:last-child), .module > *:not(:last-child) {
        margin-bottom: 10px;
    }

.border-group > *,
.border {
    padding: 15px;
    border: 2px solid #000000;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.border-group > * > *,
    .border-group > * > ul > li,
    .border > *,
    .border > ul > li {
        display: block;
    }

.border-group > * > *:not(:last-child), .border > *:not(:last-child) {
        margin-bottom: 10px;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.border-group > *,
.border {
        /* padding: 30px; */
}
    }

@media only screen and (min-width: 1000px) {

    }


/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.card-group > *,
.card {
    padding: 15px;
    background: #fff;
    color: #222;
}
.card-group > * > *,
    .card-group > * > ul > li,
    .card > *,
    .card > ul > li
     {
        display: block;
    }
.card-group > * > *:not(:last-child), .card > *:not(:last-child) {
        margin-bottom: 10px;
    }
.card-group > * .thumbnail:first-child, .card .thumbnail:first-child {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
.card-group > * .thumbnail:last-child, .card .thumbnail:last-child {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.card-group > *,
.card {
        /* padding: 30px; */
}
    }
@media only screen and (min-width: 1000px) {

    }


/* 枠線のエリア */
/*----------------------------------------------------------------------------*/
.banner-group > *:not(:last-child) {
        margin-bottom: 20px;
    }
.banner-group > *,
.banner {
    display: flex;
}
.banner-group > * > *,
    .banner-group > * > ul > li,
    .banner > *,
    .banner > ul > li {
        flex: 1 1 0%;
    }
.banner-group > * > .content, .banner > .content {
        padding: 15px;
        background: #fff;
    }
.banner-group > * > .content > *,
        .banner-group > * > .content > ul > li,
        .banner > .content > *,
        .banner > .content > ul > li {
            display: block;
        }
.banner-group > * > .content > *:not(:last-child), .banner > .content > *:not(:last-child) {
            margin-bottom: 10px;
        }


/* 横並びのナビゲーション */
/*----------------------------------------------------------------------------*/
.nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.nav ul {
        padding-left: 0;
        display: block;
    }
.nav ul li {
            list-style-type: none;
            display: block;
        }

/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    display: flex;
    padding: 0 10px;
    max-width: 650px;
    margin-bottom: 20px;
    align-items: center;
    box-sizing: border-box;
}
.single-post .breadcrumb {
        margin-bottom: 30px;
    }
.page-template .breadcrumb {
        margin-bottom: 20px;
    }
.breadcrumb ol {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
.breadcrumb ol li {
            margin: 0;
            display: block;
        }
.breadcrumb ol li + li::before {
            content: ">";
            padding: 0 0.5em;
            display: inline-block;
        }
@media only screen and (min-width: 480px) {
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {
.breadcrumb {
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0
}
    }
@media only screen and (min-width: 1300px) {

    }



/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: #dcdcdc;
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    /* border-radius: 4px; */
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.progress .progress-bar {
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 10px;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/
.step-group {
    display: flex;

    position: relative;
}
.step-group .step {
        flex: 1 1 0%;
        text-align: center;
        position: relative;
        cursor: default;
    }
.step-group .step .badge {
            margin: 0 auto 1em auto;
            padding: 0;
            width: 40px;
            height: 40px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: #000000;
            color: rgba(0, 0, 0, 0.2);
            font-size: 1.0em;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            z-index: 1000;
        }
.step-group .step .content {
            color: #222;
            font-size: 12px;
        }
.step-group .step.active .badge {
                background-color: #fff;
                box-shadow: 0 0 0 1px #fff inset;
                color: #fff;
            }
.step-group .step.active .content {
                color: #fff;
            }
.step-group .step:hover {
            opacity: 1.0;
        }
.step-group .step:not(:first-child)::before,
        .step-group .step:not(:first-child)::after {
            content: "";
            height: 2px;
            display: block;
            position: absolute;
            top: calc(40px / 2 - 2px / 2);
            left: -50%;
            z-index: 0;
        }
.step-group .step:not(:first-child)::before {
            width: 100%;
            background: #000000;
            background: rgba(0, 0, 0, 0.2);
        }
.step-group .step:not(:first-child)::after {
            width: 0%;
            background: #fff;
            transition: width 0.2s ease-in-out 0.0s;
        }
/*
        &::before {
            background: $color-base;
        }
        */
.step-group .step.active:not(:first-child)::after {
            width: 100%;
        }

/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;
}

section.box {
        display: block;
    }

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: #fff;
    color: #fff;
}

.box:not(.box-white) table,
        .box:not(.box-white) table thead,
        .box:not(.box-white) table tbody,
        .box:not(.box-white) table tfoot,
        .box:not(.box-white) table tr,
        .box:not(.box-white) table th,
        .box:not(.box-white) table td {
            border-color: #fff;
        }
.box.box-white {
    background-color: #fff;
    color: #fff;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;
    }
    .box-md {
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

.box-text {
    min-height: 200px;
    height: auto;
}

.box-text .title {
        margin-bottom: 15px;
        line-height: 1;
    }

@media only screen and (min-width: 660px) {

.box-text {
        max-width: 400px
}
    }

@media only screen and (min-width: 770px) {
        .box-text .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }


.box .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px #fff inset;
    box-sizing: border-box;
}
@media only screen and (min-width: 770px) {
.circle {
        width: 160px;
        height: 160px
}
    }
/* aタグの装飾をキャンセル */
html body .circle {
        border: none !important;
    }
/* ボタンのサイズ */
.circle.circle-lg {

    }
.circle.circle-sm {
        width: 70px;
        height: 70px;
    }
.circle,
    .circle.circle-fill {
        background-color: #03a9f4;
        color: #fff;
    }
a.circle:hover, a.circle.circle-fill:hover {
            background-color: transparent;
            color: #03a9f4;
        }
.circle.circle-outline {
        background-color: transparent;
        color: #03a9f4;
    }
a.circle.circle-outline:hover {
            background-color: #03a9f4;
            color: #fff;
        }
.circle.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #222;
    }
a.circle.circle-skeleton:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #222;
        }
.circle > span {
        display: block;
        text-align: center;
        line-height: 1.2;
    }
@media only screen and (min-width: 770px) {
.circle > span {
            line-height: 1.5
    }
        }
.circle > span span {
            display: inline-block;
        }

/* お知らせ */
/*----------------------------------------------------------------------------*/
.home-header {
    max-width: 980px;
    margin: 0 auto;
}
.home-header .home-header-info {
        display: flex;
        margin-top: 40px;
        background-color: #fff;
    }
.home-header .home-header-info .title{
            background-color: #03a9f4;
            color: #fff;
            padding: 10px 0;
            min-width: 130px;
            text-align: center;
        }
.home-header .home-header-info dl {
            background-color: #fff;
            display: flex;
            align-items: center;
        }
.home-header .home-header-info dl dt {
                min-width: 160px;
                padding: 0 20px;
                font-weight: bold;
            }
.home-header .home-header-info dl dd a {
                    color :#03a9f4;
                }
.home-header .home-header-info dl dd a:hover {
                    color: #76cef9;
                }

main {
    margin-top: 0;
    padding-bottom: 165px;
}


/* 記事一覧 < 前日 翌日 >表示
---------------------------------------------------------- */
.view-header .mode-nav,.view-footer .mode-nav{
        font-size: 12px;
        font-weight: bold;
        height: 58px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
@media only screen and (min-width: 770px) {
.view-header .mode-nav,.view-footer .mode-nav{
            font-size: 16px
    }
        }
.view-header .mode-nav a, .view-footer .mode-nav a {
            font-weight: bold;
            color: #03a9f4;
        }
.view-header .mode-nav a:hover, .view-footer .mode-nav a:hover {
                color: #76cef9;
            }
.view-header .mode-nav a.yesterday.icon::before, .view-footer .mode-nav a.yesterday.icon::before {
                position: relative;
                top: 2px;
                -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                margin-right: 10px;
            }
.view-header .mode-nav a.tomorrow.icon::before, .view-footer .mode-nav a.tomorrow.icon::before {
                content: "";
            }
.view-header .mode-nav a.tomorrow.icon::after, .view-footer .mode-nav a.tomorrow.icon::after {
                display: inline-block;
                font-family: "original-icon-font";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: baseline;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\EA02";
                position: relative;
                top: 2px;
                margin-left: 10px;
            }

.view-header {
    margin-bottom: 40px;
}

@media only screen and (min-width: 1000px) {

.view-header {
        margin-bottom: 20px
}
    }

.view-header .mode-nav {
        margin-bottom: 15px;
        background-image: url(../img/article/dot-line.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 6px 2px;
        border: none !important;
    }

.view-header .mode-nav div {
            display: flex;
            justify-content: center;
            align-items: center;
        }

.view-header .mode-nav div .weekly-event {
                position: relative;
                left: 20px;
                right: 20px;
            }

.view-header .mode-nav div .sp-weekly {
                font-size: 12px;
            }

@media only screen and (min-width: 660px) {

.view-header .mode-nav div {
                font-size: 16px
        }
                .view-header .mode-nav div .weekly-event {
                    font-size: 20px;
                }
                    .view-header .mode-nav div .weekly-event .sp-weekly {
                        font-size: 20px;
                    }
            }

.view-footer {
    margin: 30px 0 0;
}

.view-footer div.mode-nav {
        padding: 0 20px 0;
    }

/* マップ・開催日時切り替え / リスト(一覧)
---------------------------------------------------- */
.switch-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* マップ・開催日時切り替え
    ---------------------------------------------------- */
.switch-nav .switch-nav-left {
        display: flex;
    }
.switch-nav .switch-nav-left label {
            position: relative;
            cursor: pointer;
        }
.switch-nav .switch-nav-left label input {
                position: absolute;
                opacity: 0;
            }
.switch-nav .switch-nav-left label span.switch-nav-list{
                display: block;
                background-color: #fff;
                color: #03a9f4;
                font-weight: bold;
                width: 100px;
                height: 37px;
                border-radius: 18.5px 0 0 18.5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
.switch-nav .switch-nav-left label span.switch-nav-map{
                display: block;
                background-color: #fff;
                color: #03a9f4;
                font-weight: bold;
                width: 100px;
                height: 37px;
                border-radius: 0 18.5px 18.5px 0 ;
                display: flex;
                justify-content: center;
                align-items: center;
            }
.switch-nav .switch-nav-left label input:checked + span {
            background-color: #03a9f4;
            color: #fff;
        }
/* 開催日時部分
    ---------------------------------------------------- */
.switch-nav .switch-nav-right {
        font-size: 16px;
    }
.switch-nav .switch-nav-right a.icon.icon-asc {
            color: #222;
        }
.switch-nav .switch-nav-right a.icon.icon-asc::after {
                display: inline-block;
                font-family: "original-icon-font";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: baseline;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\EA05";
                font-size: 18px;
                color: #03a9f4;
                position: relative;
                top: 3px;
                margin-left: 5px;
            }
.switch-nav .switch-nav-right a.icon.icon-asc::before {
                content: "";
            }
.switch-nav .switch-nav-right a.icon.icon-desc::after {
                display: inline-block;
                font-family: "original-icon-font";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                vertical-align: baseline;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\EA10";
                font-size: 18px;
                color: #03a9f4;
                position: relative;
                top: 3px;
                margin-left: 5px;
            }
.switch-nav .switch-nav-right a.icon.icon-desc::before {
                content: "";
            }

/* イベント表示件数表示用の吹き出しデザイン
---------------------------------------------------- */
.balloon {
    z-index: 3;
    letter-spacing: normal;
    position: relative;
    top: -30px;
    left: 0;
    display: inline-block;
    padding: 8px 6px;
    min-width: 40px;
    height: 40px;
    line-height: 15px;
    vertical-align: middle;
    text-align: center;
    color: #fcf38a;
    font-size: 14px;
    font-weight: bold;
    background: #e91e63;
    border-radius: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    margin-left: 20px;
}
.balloon .unit {
        font-size: 10px;
        color: #fff;
    }
.balloon span {
        display: block;
    }
.balloon::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: -12px;
        margin-top: -35px;
        border: 10px solid transparent;
        border-left: 20px solid #e91e63;
        z-index: -1;
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
    }
@media only screen and (min-width: 660px) {
        .balloon {
            top: -20px;
            left: 10px;
            font-size: 14px;
            margin-left: 15px;
        }
            .balloon .unit {
                font-size: 12px;
            }
    }

/* 記事一覧 - リスト用 中止・延期・変更のタグ */
/*--------------------------------------------------*/
.update-information {
    position: absolute;
    top: -15px;
    left: 10px;
    right: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #d1effc;
    border-radius: 6px 6px 0 0;
    line-height: 1;
}
@media only screen and (min-width: 1000px) {
.update-information {
        font-size: 14px !important;
        top: 10px;
        bottom: 10px;
        left: -10px;
        width: 25px;
        height: auto;
        border-radius: 6px 0 0 6px;
        line-height: 1.2
}
    }
.update-information.change {
        background-color: #fcf38a;
        color: #ff5722;
        font-weight: bold;
    }
.update-information.postponement {
        background-color: #c4f7c6;
        color: #4caf50;
        font-weight: bold;
    }
.update-information.stop {
        background-color: #f8c2c2;
        color: #d32f2f;
        font-weight: bold;
    }

ul.event-area {
    margin-bottom: 30px;
}

@media only screen and (min-width: 1000px) {

ul.event-area {

        margin-left: 0;
        margin-right: 0;
        padding: 0;
        padding-left: 10px
}
    }

ul.event-area li {
        margin-bottom: 35px;
        list-style: none;
        box-sizing: border-box;
    }

@media only screen and (min-width: 1000px) {

ul.event-area li {
            width: 100%;
            margin-bottom: 20px
    }

            ul.event-area li:last-child {
                margin-bottom: 0;
            }
        }

ul.event-area li:first-child {
            margin-top: 0;
        }

ul.event-area li a {
            padding: 30px 10px 15px 10px;
            position: relative;
            display: flex;
            font-size: 12px;
            flex: 1 1 auto;
            color: #222;
        }

@media only screen and (min-width: 1000px) {

ul.event-area li a {
                padding: 30px 20px 30px 35px
        }
            }

ul.event-area li a .thumbnail {
                background-color: #eee;
                width: 85px;
                height: 85px;
                margin-right: 10px;
                overflow: hidden;
            }

@media only screen and (min-width: 1000px) {

ul.event-area li a .thumbnail {
                    width: 120px;
                    height: 120px;
                    margin-right: 20px
            }
                }

ul.event-area li a .detail {
                flex-basis: calc(100% - 95px);
            }

@media only screen and (min-width: 1000px) {

ul.event-area li a .detail {
                    flex-basis: calc(100% - 140px)
            }
                }

ul.event-area li a .event-area-header {
                margin-bottom: 15px;
                line-height: 1;
            }

ul.event-area li a .event-area-header .event-term {
                    font-size: 12px;
                    padding: 10px 0 6px;
                    display: block;
                }

ul.event-area li a .event-area-header .event-dayoff {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.3;
                    display: block;
                }

@media only screen and (min-width: 1000px) {
                    ul.event-area li a .event-area-header {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 15px;
                    }

                        ul.event-area li a .event-area-header .event-term {
                            margin-bottom: 5px;
                            padding: 0;
                            font-size: 14px;
                        }

                        ul.event-area li a .event-area-header .event-dayoff {
                        }
                }

ul.event-area li a .event-area-title {
                padding-bottom: 15px;
                margin-bottom: 10px;
                border-bottom: solid 1px #7f7f7f;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: normal;
                line-height: 1.3;
            }

@media only screen and (min-width: 1000px) {

ul.event-area li a .event-area-title {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    font-size: 18px;
                    line-height: 1.3
            }
                }

ul.event-area li a .event-area-sbtitle {
                font-size: 12px;
                line-height: 1.3;
                margin-bottom: 10px;
            }

@media only screen and (min-width: 1000px) {

ul.event-area li a .event-area-sbtitle {
                    font-size: 14px
            }
                }

ul.event-area li a .event-area-footer {
                line-height: 1.2;

            }

@media only screen and (min-width: 770px) {

ul.event-area li a .event-area-footer {
                    display: flex

            }
                }

ul.event-area li a .event-area-footer dl {
                    font-size: 10px;
                    display: flex;
                }

@media only screen and (min-width: 770px) {

ul.event-area li a .event-area-footer dl {
                        font-size: 12px;
                        flex-basis: calc(100% / 2)
                }
                    }

ul.event-area li a .event-area-footer dl:not(last-child) {
                        margin-bottom: 5px;
                    }

@media only screen and (min-width: 770px) {

ul.event-area li a .event-area-footer dl:not(last-child) {
                            margin-bottom: 0;
                            margin-right: 15px
                    }
                        }

ul.event-area li a .event-area-footer dl dt {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 33px;
                        height: 18px;
                        font-size: 10px;
                        background-color: #777777;
                        color: #fff;
                        margin-right: 5px;
                    }

@media only screen and (min-width: 770px) {

ul.event-area li a .event-area-footer dl dt {
                            width: 40px;
                            font-size: 12px
                    }
                        }

ul.event-area li a .event-area-footer dl dd {
                        width: 100%;
                        margin-top: 3px;
                        line-height: 1.2;
                        word-wrap: break-word;
                    }

@media only screen and (min-width: 770px) {

ul.event-area li a .event-area-footer dl dd {
                            margin-top: 2px
                    }
                        }

ul.event-area li a .event-area-footer dl .time {
                        margin-bottom: 5px;
                    }

ul.event-area li a:hover {
                color: #03a9f4;
            }

ul.event-area li a:hover span.thumbnail {
                    opacity: 0.5;
                    transition: 0.5s;
                    -webkit-transform: scale(2);
                    transform: scale(2);
                }



/* お知らせ 表示 */
/*----------------------------------------------------------------------------*/

.information-area {
    margin-top: 20px;
     margin-bottom: 15px;
}

@media only screen and (min-width: 1000px) {
        .information-area {
            margin-bottom: 30px;
        }
    }

.information-area .information-content {
        display: flex;
        background-color: #fff;
    }

.information-area .information-content .title{
            background-color: #03a9f4;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
        }

@media only screen and (min-width: 1000px) {
                .information-area .information-content .information-content .title{
                    font-size: 16px;;
                    min-width: 130px;
                }
        }

.information-area .information-content dl {
            background-color: #fff;
            align-items: center;
            padding: 15px 10px;
            width: 100%;
        }

@media only screen and (min-width: 1000px) {

.information-area .information-content dl {
                display: flex;
                align-items: center;
                padding: 15px 20px
        }
            }

.information-area .information-content dl dt {
                font-size: 12px;
                margin-bottom: 10px;
                min-width: 160px;
                font-weight: bold;
            }

@media only screen and (min-width: 1000px) {

.information-area .information-content dl dt {
                    margin-bottom: 0;
                    font-size: 14px
            }
                }

.information-area .information-content dl dd a {
                    line-height: 1.2;
                    color :#03a9f4;
                }

.information-area .information-content dl dd a:hover {
                    color: #76cef9;
                }



/* 下層ページ_記事一覧(サイズ小) */
/*----------------------------------------------------------------------------*/
@media only screen and (min-width: 660px) {
.related-article {
        margin-top: 40px
}
    }
.related-article .view-footer .mode-nav {
            padding: 0;
        }
.related-article .wrap-event-area {
        background-color: #faf6e9;
    }
.related-article .wrap-event-area ul.event-area {
            padding: 30px 10px 20px;
        }
@media only screen and (min-width: 660px) {
.related-article .wrap-event-area ul.event-area {
                padding: 40px 30px 40px;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0 !important
        }

                .related-article .wrap-event-area ul.event-area .event-term,
                .related-article .wrap-event-area ul.event-area .event-dayoff,
                .related-article .wrap-event-area ul.event-area dl dt,
                .related-article .wrap-event-area ul.event-area dl dd {
                    font-size: 10px !important;
                }
            }
@media only screen and (min-width: 1000px) {
.related-article .wrap-event-area ul.event-area {
                padding: 20px 30px 20px
        }
            }


/* マップ バルーン */
/*----------------------------------------------------------------------------*/
.m-map-balloon .e-cate {
        display: inline-block;
        line-height: 1;
        padding: 3px 10px;
        font-size: 10px;
        color: #fff;
    }
.m-map-balloon .e-status {
        display: inline-block;
        margin-left: 5px;
        color: #d32f2f;
        font-weight: 700;
    }
.m-map-balloon .e-title {
        line-height: 1.2;
        font-size: 12px;
        margin-top: 5px;
    }
.m-map-balloon .e-place {
        font-size: 10px;
        margin-top: 2px;
    }

/* no-event(情報なしの場合の表示) */
/*----------------------------------------------------------------------------*/
.no-event {
    text-align: center;
}
/* 背景色 */
body {
    background-color: #fbf8a0;
    background-image: url(../img/common/bg-sp-top.png),
                      url(../img/common/bg-pattern.png);
    background-position: left top,
                         top;
    background-repeat: no-repeat,
                       repeat;
    background-size: 50% auto,
                     300px;
}
@media only screen and (min-width: 770px) {
body {

        background-image: url(../img/common/bg-top.png),
                          url(../img/common/bg-pattern.png);
        background-position: top;
        background-repeat: no-repeat,
                           repeat;
        background-size: 100% auto,
                         300px
}
    }
.main-content-area {
    background-image: url(../img/common/bg-sp-bottom.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
@media only screen and (min-width: 1000px) {
.main-content-area {
        padding-bottom: 100px;
        background-image: url(../img/common/bg-bottom.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size:100% auto
}
    }
/* 白背景パターン */
.bg-white {
    background-color: #fff;
}

/* 白背景パターン1 */
.bg-white-01 {
    background-color: #fff;
    padding: 30px 20px 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
@media only screen and (min-width: 1000px) {
.bg-white-01 {
        padding: 50px 40px 40px
}
    }

/* 白背景パターン2 */
.bg-white-02 {
    background-color: #fff;
    padding: 30px 20px 20px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
@media only screen and (min-width: 770px) {
.bg-white-02 {
        padding-top: 40px
}
    }
@media only screen and (min-width: 1000px) {
.bg-white-02 {
        padding: 40px
}
    }

/* 白背景パターン3 (カード型のときに使用する背景) */
.bg-white-03 {
    background-color: #fff;
    padding: 30px 10px 10px;
    position: relative;

}
@media only screen and (min-width: 770px) {
.bg-white-03 {
        padding-top: 40px

}
    }
@media only screen and (min-width: 1000px) {
.bg-white-03 {
        padding: 40px

}
    }

.bg-white-yellow {
    background-color: #faf6e9;
}
.bg-white-yellow-01 {
    background-color: #faf6e9;
    padding: 40px !important;
}


.bg-gray {
    background-color: #f4f4f4;
}

.bg-black {
    background-color: #222;
    color: #fff;
}

.bg-black .module-group > a,
    .bg-black .border-group > a,
    .bg-black .card-group > a {
        color: #fff;
    }

.bg-black a.module,
    .bg-black a.border,
    .bg-black a.card {
        color: #fff;
    }

.bg-black .border-group > *,
    .bg-black .border {
        border-color: #fff;
    }

/* アニメーション */
/*----------------------------------------------------------------------------*/

a,
input,
select,
textarea,
button,
.btn,
.btn * {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s;
}

.scroll-effect,
.parent-scroll-effect.list > * {
    /* transition: all 0.35s ease-out 0.25s; */
    /* transition: all 0.5s cubic-bezier(.4,0,.4,1) 0.4s; */
    transition: opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;


}
.scroll-effect,
.parent-scroll-effect.list > * {
    -webkit-transform: none;
            transform: none;
    opacity: 1.0;
}
.scroll-effect.inactive,
.parent-scroll-effect.list > *.inactive {
    transition: none;
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0.0;
}

.scroll-effect.customize-body {
    transition-delay: 0.5s;
}

a .thumbnail,
a.thumbnail,
.thumbnail a,

a .thumbnail img,
a.thumbnail img,
.thumbnail a img,

a .thumbnail .mask,
a.thumbnail .mask,
.thumbnail a .mask {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;
}

a:hover .thumbnail img,
a.thumbnail:hover img,
.thumbnail a:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.5;
}

a:hover .thumbnail .mask,
a.thumbnail:hover .mask,
.thumbnail a:hover .mask {

}
/*
.bx-wrapper .bx-viewport li {
    transition: all 0.1s ease 0s;
}
.bx-wrapper .bx-viewport li.active {
    transition: all 0.25s ease 0s;
}
*/
.bx-wrapper .bx-pager.bx-default-pager a {
    /* transition: all 0.15s ease 0.75s; */
}

/* 高さを画面幅に */
.scroll-effect.contain {
    box-sizing: border-box;
    position: relative;
}
body > .scroll-effect.contain:not(.parent-popup-content) {
    transition: all 0.75s ease-in-out;
}

.scroll-effect.contain .scroll-effect {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parent-scroll-effect.parallax {
    position: relative;
}
.parent-scroll-effect.parallax > *:not(.not-move) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



/* カスタマイズ */
/*----------------------------------------------------------------------------*/
.mainvisual .slider li.active img {
    transition: -webkit-transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s, -webkit-transform 12.0s ease-in 0s;
}
.mainvisual .slider li img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.mainvisual .slider li.active img {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.slider li {
    transition: opacity 0.9s ease-in 0s;
}

.back-top {
    transition: margin 0.5s ease-in-out 0s,
                background 0.5s ease-in-out 0s,
                opacity 0.5s ease-in-out 0s,
                top 0.5s ease-in-out 0.25s,
                right 0.5s ease-in-out 0.25s,
                bottom 0.5s ease-in-out 0.25s,
                left 0.5s ease-in-out 0.25s;
}
.back-top.inactive {
    transition: margin 0.25s ease-in-out 0.25s,
                background 0.25s ease-in-out 0.25s,
                opacity 0.25s ease-in-out 0.25s,
                top 0.5s ease-in-out 1.0s,
                right 0.5s ease-in-out 1.0s,
                bottom 0.5s ease-in-out 1.0s,
                left 0.5s ease-in-out 1.0s;
}

/*
*:first-child {
    margin-top: 0;
}
*:last-child {
    margin-bottom: 0;
}
*/
body {
    position: relative;
    box-sizing: border-box;
    margin: 0;
}

@media only screen and (min-width: 1000px) {

article,
.lead {
        max-width: 650px
}
    }

article ~ section {
    margin-top: 40px;
}

main {

    box-sizing: border-box;
}

main .main-header {
        margin-bottom: 40px;
    }

main .main-header .title {
            font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Droid Sans, sans-serif;
        }

@media only screen and (min-width: 660px) {

main .main-header {
            margin-bottom: 80px
    }
        }

@media only screen and (min-width: 1000px) {

main .main-header {
            margin-bottom: 120px
    }
        }

main .main-header + section {
                margin-top: 0;
                padding-top: 0;
            }

main .main-footer {
        margin-top: 120px;

    }

main .main-footer .container {
            display: flex;
            justify-content: center;
        }

main .main-footer .container > *:not(:last-child) {
                margin-right: 1em;
            }

main .main-footer .btn-group {
            width: 100%;
        }

main .main-footer .btn-group > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

main .main-footer .btn-group .btn {
                width: 100%;
            }

@media only screen and (min-width: 480px) {
            main .main-footer .btn-group {
                display: flex;
                justify-content: center;
            }

                main .main-footer .btn-group > *:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 0.5em;
                }

                main .main-footer .btn-group .btn {
                    width: auto;
                    flex: 0 1 320px;
                }
        }

section {
    margin-bottom: 40px;

}

section .section-header {
        margin-bottom: 40px;

    }

section .section-header .title {
            font-size: 24px;
        }

@media only screen and (min-width: 480px) {
            section .section-header .title {

            }
        }

@media only screen and (min-width: 770px) {

section .section-header {
            margin-bottom: 60px

    }

            section .section-header .title {
                font-size: 32px;
            }
        }

section .section-content + .section-content {
        margin-top: 20px;
    }

section .section-footer {
        margin-top: 30px;
    }


/* section 2階層目 */
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
    }
@media only screen and (min-width: 1000px) {

    }
article section .section-header, section section .section-header {
        margin-bottom: 20px;
    }
article section .section-header .title, section section .section-header .title {
            font-size: 18px;
        }
@media only screen and (min-width: 770px) {
article section .section-header, section section .section-header {
            margin-bottom: 30px
    }

            article section .section-header .title, section section .section-header .title {
                font-size: 24px;
            }
        }
@media only screen and (min-width: 1000px) {

        }

/* section 3階層目 */
article section section .section-header, section section section .section-header {
        margin-bottom: 10px;
    }
article section section .section-header .title, section section section .section-header .title {
            font-size: 16px;
        }
@media only screen and (min-width: 770px) {
article section section .section-header, section section section .section-header {
            margin-bottom: 15px
    }

            article section section .section-header .title, section section section .section-header .title {
                font-size: 20px;
            }
        }


/*----------------------------------------------------------------------------*/
.container,
.container-harf,
.container-fluid {
    margin: 0 10px;
}
@media only screen and (min-width: 480px) {
    .container,
    .container-harf,
    .container-fluid {
        margin: 0 10px;
    }
}
@media only screen and (min-width: 660px) {
    .container,
    .container-harf,
    .container-fluid {

    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
}
@media only screen and (min-width: 770px) {
    .container-fluid {
        margin: 0 40px;
    }
    .container {
        margin: 0 40px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 660px;
    }
    .container-lg {
        margin: 0 40px;
        width: auto;
    }
    .container-harf {

    }
}
@media only screen and (min-width: 1000px) {
    .container-fluid {
        margin: 0 80px;
    }
    .container {
        margin: 0 auto;
        width: 980px;
    }
    .container-sm {
        margin: 0 auto;
        width: 480px;
    }
    .container-md {
        margin: 0 auto;
        width: 660px;
    }
    .container-lg {
        margin: 0 80px;
        width: auto;
    }
    .container-harf {
    }
    .container-980 {
        max-width: 980px;
         margin: 0 auto 40px;
     }
}


.layout-group > *:not(:last-child) {
    margin-bottom: 20px;
}

.layout-group > * > *:not(:last-child), .layout > *:not(:last-child) {
        margin-bottom: 20px;
    }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {

        }

@media only screen and (min-width: 1000px) {

        }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.layout-group > *,
.layout {
        display: flex
}

        .layout-group > * > *, .layout > * {
            margin-top: 0;
            margin-bottom: 0;
            flex: 1 1 0%;
            width: auto;
        }
        .layout-group > * > *:not(:last-child), .layout > *:not(:last-child) {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }

/* カテゴリーボタンPC・SP共通 */

/* カテゴリーナビゲーションの設定
----------------------------------------------- */
.category-nav {

    display: none;
}
.category-nav .nav-all {
        height: 55px;
        box-sizing: border-box;
        background-color:  #fff;
    }
.category-nav .nav-all a {
            width: 100%;
            height: 100%;
            color: #03a9f4;
        }
.category-nav .nav-all a:hover,
        .category-nav .nav-all.active a {
            color: #fff;
            background-color: #03a9f4;
        }
.category-nav .nav-tea-flower a {
            color: #cddc39;
        }
.category-nav .nav-tea-flower a:hover,
        .category-nav .nav-tea-flower.active a {
            color: #fff;
            background-color: #cddc39;
        }
.category-nav .nav-event a {
            color: #ed3223;
        }
.category-nav .nav-event a:hover,
        .category-nav .nav-event.active a {
            color: #fff;
            background-color: #ed3223;
        }
.category-nav .nav-musics a {
            color: #00bcd4;
        }
.category-nav .nav-musics a:hover,
        .category-nav .nav-musics.active a {
            color: #fff;
            background-color: #00bcd4;
        }
.category-nav .nav-traditional-culture a {
            color: #673ab7;
        }
.category-nav .nav-traditional-culture a:hover,
        .category-nav .nav-traditional-culture.active a {
            color: #fff;
            background-color: #673ab7;
        }
.category-nav .nav-theater a {
            color: #ffc107;
        }
.category-nav .nav-theater a:hover,
        .category-nav .nav-theater.active a {
            color: #fff;
            background-color: #ffc107;
        }
.category-nav .nav-art a {
            color: #9c27b0;
        }
.category-nav .nav-art a:hover,
        .category-nav .nav-art.active a {
            color: #fff;
            background-color: #9c27b0;
        }
.category-nav .nav-sport a {
            color: #4caf50;
        }
.category-nav .nav-sport a:hover,
        .category-nav .nav-sport.active a {
            color: #fff;
            background-color: #4caf50;
        }
.category-nav .nav-lecture-course a {
            color: #795548;
        }
.category-nav .nav-lecture-course a:hover,
        .category-nav .nav-lecture-course.active a {
            color: #fff;
            background-color: #795548;
        }
.category-nav .nav-movie a {
            color: #ff5722;
        }
.category-nav .nav-movie a:hover,
        .category-nav .nav-movie.active a {
            color: #fff;
            background-color: #ff5722;
        }
.category-nav .nav-media a {
            color: #3f51b5;
        }
.category-nav .nav-media a:hover,
        .category-nav .nav-media.active a {
            color: #fff;
            background-color: #3f51b5;
        }
/* スマホメニュー(カテゴリーボタン)
    ---------------------------------------------------- */
.header-sp .category-nav {
        display: block;
        margin-bottom: 30px;
        border-radius: 6px;
        border: 1px solid #efefef;
        overflow: hidden;
    }
.header-sp .category-nav a {
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
        }
.header-sp .category-nav a.icon::before {
                margin-right: 10px;
            }
.header-sp .category-nav .nav-all a {
                border-bottom: 1px solid #efefef;
                border-radius: 6px 6px 0 0;
            }
.header-sp .category-nav ul {
            border-radius: 0 0 6px 6px;
            display: flex;
            flex-wrap: wrap;
        }
.header-sp .category-nav ul li {
                /* スマホ横幅カテゴリー2分割 */
                flex-basis: calc(100% / 2);
                background-color: #fff;
            }
.header-sp .category-nav ul li a {
                    border-top: 1px solid #efefef;
                    border-left: 1px solid #efefef;
                    margin-left: -1px;
                    padding-left: 20px;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: row;
                }
/* カテゴリーボタンのPC
    ---------------------------------------------*/
@media only screen and (min-width: 1000px) {
        .category-nav {
            border-radius: 6px;
            overflow: hidden;
            height: 147px;
            display: flex;
            margin-bottom: 15px;
        }

            .category-nav .nav-all {
                text-align: center;
                width: 110px;
                height: 147px;
                z-index: 1;
            }

                .category-nav .nav-all a {
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #efefef;
                }

                .category-nav .nav-all a:hover,
                .category-nav .nav-all.active a {
                    border-right: 1px solid #03a9f4;
                }

                .category-nav .nav-all span {
                    display: flex;
                    justify-content: center;
                    padding-top: 5px;
                }

            .category-nav ul {
                background-color:#fff;
                width: 540px;
                border-radius: 0 6px 6px 0;
                margin-top: -1px;
                margin-left: -1px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 0;
            }

                .category-nav ul li {
                    margin: 0;
                    list-style-type: none;
                    display: block;
                    font-weight: bold;
                    letter-spacing: normal;
                    box-sizing: border-box;
                    width: 108px;
                    height: 50%;
                    border-top: 1px solid #efefef;
                    border-left: 1px solid #efefef;
                }

                    .category-nav ul li a {
                        padding: 10px 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                        .category-nav ul li a span {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            line-height: 1;
                            margin-top: 5px;
                        }
    }


html .drawer--right .drawer-nav {
        right: -100vw;
    }


html body {
        padding-top: 60px;
    }


@media only screen and (min-width: 1000px) {


html body {
            padding-top: 20px
    }
            html body .drawer-hamburger,
            html body .drawer-nav {
                display: none;
            }
        }


/* ハンバーガーメニューの設定
        ---------------------------------------------------------- */


html body .drawer-hamburger-icon,
        html body .drawer-hamburger-icon:before,
        html body .drawer-hamburger-icon:after {
            width: 20px;
            height: 4px;
            border-radius: 20px;
            background-color: #fff;
        }


html body.drawer--right .drawer-hamburger,
        html body.drawer--right.drawer-open .drawer-hamburger {
            right: 10px;
        }


html body.drawer--right.drawer-open .drawer-hamburger {
                top: 10px;
                background-color: #03a9f4;
                padding-bottom: 30px;
                padding-left: 21px;
                padding-right: 18px;
                border-radius: 50px;
            }


html body.drawer--right.drawer-open .drawer-hamburger-icon {
                background-color: transparent;
            }


/* ヘッダーSP・PC切り替え
---------------------------------------------------- */
header {
    left: 10px;
    right: 10px;
    background: #03a9f4;
    color: #f5f5f5;
    position: fixed;
    top: 10px;
    z-index: 50000;
    box-sizing: border-box;
    padding: 0 15px;
    border-radius : 60px;
    font-size: 12px;
    line-height: 1.2;
}
header .header-pc {
        display: none;
    }
@media only screen and (min-width: 1000px) {
header {
        position: static;
        margin: 0 20px;
        border-radius: 50px;
        box-sizing: border-box;
        padding: 0 50px
}

        header .header-sp {
            display: none;
        }

        header .header-pc {
            display: flex;
            align-items: center;
            height: 100px;
        }

    }
@media only screen and (min-width: 1300px) {
header {
        padding-left: 90px;
        padding-right: 90px
}

    }
@media only screen and (min-width: 1500px) {
header {
        width: 1460px;
        margin: 0 auto
}
    }


/* スマホメニュー
---------------------------------------------------- */
.header-sp {
    padding: 0 25px;
}
.header-sp .drawer-nav {
        background-color: #03a9f4;

    }
.header-sp .drawer-nav .drawer-menu {
                margin-left: 20px;
                margin-right: 20px;
            }
.header-sp .drawer-nav .drawer-menu .logo-nav {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
.header-sp h2.h2-04 {
        background-color: #81d4fa;
    }
.header-sp .logo-nav {
        height: 60px;
        display: table;
    }
.header-sp .logo-nav > div {
            display: table-cell;
            vertical-align: middle;
        }
.header-sp .logo-nav .logo {
            width: 120px;
        }
.header-sp .logo-nav span {
            width: 120px;
            display: block;
        }
/* スマホメニュー(本日の日付)
    ---------------------------------------------------- */
.header-sp .side-today .side-today-header {
            font-size: 20px;
        }
.header-sp .side-today .side-today-content .today {
                font-size: 29px;
            }
.header-sp .side-today .side-today-content .today .month,
                .header-sp .side-today .side-today-content .today .day {
                    font-size: 40px;
                }
.header-sp .side-today .side-today-content .week {
                height: 100%;
            }
.header-sp .side-today .side-today-footer {
            font-size: 14px;
        }
.header-sp .side-today .side-today-footer .left span + span {
                    font-weight: normal;
                }
/* スマホメニュー(カレンダー)
    ---------------------------------------------------- */
.header-sp .ui-datepicker .ui-state-default {
            font-weight: bold;
            margin: 0 auto !important;
        }
/* スマホメニュー(検索ボックス)
    ---------------------------------------------------- */
.header-sp .search-box {
        margin-bottom: 30px;
    }
/* スマホメニュー(ボタン)
    ---------------------------------------------------- */
@media only screen and (min-width: 660px) {
.header-sp .side-list {
            width: calc(100% / 2);
            float: left;
            box-sizing: border-box;
            margin-right: 20px

    }

        }
.header-sp .side-list ul li a.btn.btn-pill {
                        padding: 14px;
                    }
.header-sp .side-list ul li:not(:last-child) {
                margin-bottom: 10px;
            }
/* スマホメニュー(イベント掲載申し込みバナー)
    ---------------------------------------------------- */
.header-sp .side-request-banner {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #fff;
    }
@media only screen and (min-width: 660px) {
.header-sp .side-request-banner {
            border-bottom-style: none;
            width: calc(50% - 25px);
            float: right;
            box-sizing: border-box;
            padding-bottom: 0
    }
        }
.header-sp .side-request-banner a {
            display: block;
        }
/* スマホメニュー(ナビゲーション)
    ---------------------------------------------------- */
.header-sp .top-nav {
        margin-bottom: 30px;
    }
@media only screen and (min-width: 660px) {
.header-sp .top-nav {
            clear: both;
            padding-top: 30px;
            border-top: 1px solid #fff
    }
        }
.header-sp .top-nav ul li + li {
                    margin-top: 10px;
                }
.header-sp .top-nav ul li a {

                    font-size: 16px;
                    color: #fff;
                    position: relative;
                }
.header-sp .top-nav ul li a .icon-arrow-simple::before {
                        font-size: 13px;
                        font-weight: normal;
                        margin-right: 10px;
                    }
.header-sp .top-nav ul li a:not(:first-child) {
                        margin-bottom: 10px;
                    }

/* PCメニュー(ナビゲーション)
---------------------------------------------------- */
.header-pc .logo-nav {
        display: flex;
        align-items: center;
    }
/* PC用ロゴ
    ---------------------------------------------------- */
.header-pc .logo-nav {
        display: flex;
        align-items: center;
        width: 430px;
    }
@media only screen and (min-width: 1300px) {
.header-pc .logo-nav {
            width: auto
    }
        }
.header-pc .logo-nav .logo {
            margin-right: 15px;
        }
.header-pc .logo-nav .logo a {
                margin-left: auto;
                margin-right: auto;
                height: auto;
            }
.header-pc .logo-nav .logo a img {
                    width: 236px;
                    display: block;
                }
.header-pc .logo-nav span {
            display: flex;
            align-items: center;
        }
/* PC用-検索ボックス・ナビゲーション
    ---------------------------------------------------- */
.header-pc .global-nav-btn {
        display: none;
    }
.header-pc .global-nav {
        margin-left: auto;
    }
.header-pc .global-nav .search-box span.field {
                margin-top: 0;
                margin-bottom: 0;
                margin-left: auto;
                width: 340px;
            }
.header-pc .global-nav nav {
            display: block;
            margin-top: 15px;
            font-size: 14px;
        }
.header-pc .global-nav nav ul {
                margin: 0;
                padding-left: 0;
                width: 100%;
                display: flex;
                align-items: center;
            }
.header-pc .global-nav nav ul > li {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    line-height: 1;
                }
.header-pc .global-nav nav ul > li a {
                        color: #fff;
                        line-height: 1;
                        opacity: 1.0;

                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        position: relative;
                    }
.header-pc .global-nav nav ul > li a .icon::before {
                            position: relative;
                            top: 2px;
                            padding-right: 5px;
                        }
.header-pc .global-nav nav ul > li a:hover {
                        opacity: 0.7;
                    }
.header-pc .global-nav nav ul > li:not(:last-child) {
                    margin-right: 25px;
                }
.header-pc .global-nav nav ul > li.current a::before {
                            content: "";
                            width: 100%;
                            height: 2px;
                            background: #fff;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }


/* ヘッダー下文章
---------------------------------------------------------------*/
.home-header-message {
    margin-top: 15px;
    margin: 20px 10px;
}
.home-header-message p {
        line-height: 1.4;
    }
.home-header-message p a {
            color: #03a9f4;
            line-height: 1;
        }
.home-header-message p a:hover {
            color: #76cef9;
        }
@media only screen and (min-width: 770px) {
.home-header-message {
        margin: 20px 40px
}
    }
@media only screen and (min-width: 1000px) {
.home-header-message {
        margin: 20px auto
}

        .home-header-message p {
            text-align: center;
            font-size: 14px;
        }
    }
/* footer */
/*----------------------------------------------------------------------------*/
footer .footer-nav-area {
        padding: 30px 30px 60px;
        text-align: center;
    }
@media only screen and (min-width: 1000px) {
                footer .footer-nav-area .container ul {
                    display: inline-table;
                }
                    footer .footer-nav-area .container ul:not(:last-child) {
                        margin-right:  80px;
                    }
        }
footer .footer-nav-area .footer-nav {
            display: none;
        }
@media only screen and (min-width: 1000px) {
footer .footer-nav-area .footer-nav {
                display: block;
                margin-bottom: 40px
        }
                footer .footer-nav-area .footer-nav ul {
                    text-align: left;
                }
                        footer .footer-nav-area .footer-nav ul li:not(:last-child) {
                            margin-bottom: 8px;
                        }
                        footer .footer-nav-area .footer-nav ul li span.icon::before {
                            margin-right: 10px;
                            font-weight: bold;
                            font-size: 7px;
                        }
            }
footer .footer-nav-area .footer-nav-banner {
            padding: 30px;
            display: inline-block;
        }
footer .footer-nav-area .footer-nav-banner a {
                display: block;
            }
footer .footer-nav-area .footer-nav-banner a:not(:last-child) {
                    margin-bottom: 20px;
                }
@media only screen and (min-width: 660px) {
footer .footer-nav-area .footer-nav-banner {
                padding: 30px 40px;
                    display: flex
        }
                    footer .footer-nav-area .footer-nav-banner a {
                        flex: 1 1 100%;
                        display: block;
                    }
                        footer .footer-nav-area .footer-nav-banner a:not(:last-child) {
                            margin-right: 20px;
                            margin-bottom: 0;
                        }
            }
footer .footer-nav-copyright-area {
        padding: 25px 0;
        text-align: center;
        font-size: 10px;
        color: #fff;
        background-color: #03a9f4;
    }



/* footer_TOPボタン */
/*----------------------------------------------------------------------------*/

#PageTopBtn {
    position: fixed; /*ボタンの配置場所を固定*/
    bottom: 70px !important; /*下からのボタンの配置場所を指定*/
    right: 0; /*右からのボタンの配置場所を指定*/
    font-size: 13px; /*文字のサイズ*/
    display: none;
}

#PageTopBtn a {
    display: flex; /*配置の調整*/
    text-decoration: none; /*文字の下線を消す*/
    color: #fff; /*文字の色*/
    background: #03a9f4; /*ボタンの背景色*/
    outline: none; /*クリックしたときの黒い枠を消す*/
    width: 60px; /*ボタンの幅*/
    height: 60px; /*ボタンの高さ*/
    align-items: center;
    justify-content: center;
}

#PageTopBtn a:hover {
        opacity: 0.7;
    }
/* PC サイドバー全体の調整
----------------------------------- */
@media only screen and (min-width: 1000px) {
.main-content-area .container{
            display: flex
    }

            .main-content-area .container main {
                flex: 1 1 auto;
                margin-right: 30px;
            }
            .main-content-area .container .side {
                flex: 0 0 300px;
                width: 300px;
            }
        }


.side {
    display: none;
}


@media only screen and (min-width: 1000px) {


.side {
        display: block;
        flex: 0 0 300px;
        box-sizing: border-box;
        margin-left: 0px;
        margin-right: 0px
}
    }

/* PC サイドバー：今日の日付
----------------------------------- */
.side-today {
    padding: 20px;
    margin-bottom: 30px;
    background-color: #fff;
}
.side-today .side-today-header{
        font-size: 18px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
.side-today .side-today-content {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 26px;
        font-weight: bold;
    }
.side-today .side-today-content .month,
        .side-today .side-today-content .day {
            color: #03a9f4;
            font-size: 36px;
            vertical-align: -1px;
        }
.side-today .side-today-content .week {
            font-size: 20px;
            border: solid 2px;
            border-radius: 20px;
            align-items: center;
            line-height: 1;
            padding: 6px 12px;
        }
.side-today .side-today-content .week.weekday {
                color: #222;
            }
.side-today .side-today-content .week.dayoff {
                color: #e91e63;
            }
.side-today .side-today-content .week.saturday {
                color: #03a9f4;
            }
.side-today .side-today-footer {
        margin-top: 20px;
        background-color: #faf6e9;
        font-size: 12px;
        padding: 6px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
.side-today .side-today-footer .left {
            letter-spacing: normal;
            flex: 1 1 auto;
        }
.side-today .side-today-footer .left ul {
                line-height: 1;
            }
.side-today .side-today-footer .left ul li {
                    height: 15px;
                }
.side-today .side-today-footer .left ul li span {
                        font-weight: bold;
                        display: inline-block;
                        height: 15px;
                        line-height: 1;
                    }
.side-today .side-today-footer .left ul li span:not(:last-child) {
                        width: 50px;
                        border-right: solid 1px #222222;
                        margin-right: 5px;
                    }
.side-today .side-today-footer .left ul li:not(:first-child) {
                    margin-top: 5px;
                }
.side-today .side-today-footer .right {
            flex: 0 0 120px;
        }
.side-today .side-today-footer .right .btn {
                padding: 8px 5px;
                font-size: 12px;
                min-width: auto;
                line-height: 1.2;
                width: 100%;
            }
.side-today .side-today-footer .right .btn::after {
                font-size: 6px;
                right: 5px;
            }

/* PC サイドバー：カレンダー
----------------------------------- */
.side-calendar {
    margin-bottom: 30px;
}

/* PC サイドバー：イベント掲載申し込みバナー
----------------------------------- */
.request-banner {
    margin-bottom: 30px;
    background-color: #ffffff;
}
.request-banner img {
        vertical-align: bottom;
    }

/* PC サイドバー：ナビゲーション
----------------------------------- */
.side-list {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 20px;
}
.side-list ul li {
            margin-top: 5px;
        }
a.btn.btn-pill {
display: block !important;
text-align: left;
}

/* PC サイドバー：ランキング
----------------------------------- */
.wpp-list {
    counter-reset: posts-rank;
}
.wpp-list li .badge:before {
    content: counter(posts-rank);
    counter-increment: posts-rank;
}
.wpp-list li:nth-child(n + 6) {
    display: none;
}

.side-item ul {
        padding-left: 0;
        display: block;
    }

.side-item ul li {
            list-style-type: none;
            display: block;
            margin: 0;
        }

.side-item ul li a {
                display: flex;
            }

.side-item ul li a .img {
                    flex: 0 0 90px;
                }

.side-item ul li a .content {
                    flex: 1 1 auto;
                }

.side-ranking {
    margin-top: 30px;
    background-color: #fff;
}

.side-ranking ul {
        padding: 20px;
    }

.side-ranking ul li {
            position: relative;
        }

.side-ranking ul li:not(:last-child) {
                margin-bottom: 10px;
            }

.side-ranking ul li .badge {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 36px;
                font-weight: bold;
                height: 36px;
                background-color: #03a9f4;
                color: #fcf38a;
                font-size: 20px;
                box-shadow: none;
            }

.side-ranking ul li a {
                margin-top: 15px;
                padding-bottom: 25px;
                border-bottom: 1px solid #7f7f7f;
                color: #222;
            }

.side-ranking ul li a .thumbnail {
                    background-color: #eee;
                    box-sizing: border-box;
                    width: 90px;
                    height: 90px;
                    margin-left: 10px;
                    overflow: hidden;
                }

.side-ranking ul li a .content {
                    padding-left: 15px;
                    font-size: 12px;
                }

.side-ranking ul li a .content .event-name {
                        display: block;
                        margin-bottom: 10px;
                        letter-spacing: 0;
                        overflow-wrap: break-word;
                        line-height: 1.3;
                        font-weight: bold;
                    }

.side-ranking ul li a .content .event-term {
                        display: block;
                        font-size: 10px;
                        letter-spacing: 0;
                        padding-left: 10px;
                    }

.side-ranking ul li a .content .category {
                        display: block;
                        width: 100px;
                        text-align: center;
                        margin-bottom: 10px;
                        padding: 5px 0;
                        color: #fff;
                    }

.side-ranking ul li a:hover {
                    color: #76cef9;
                }

.side-ranking ul li a:hover span.thumbnail {
                        opacity: 0.5;
                        -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                    }



/* ローディング */
.load-area {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50000;
}

html.load-active body {
        background-color: #fff;
    }

html.load-active .load-area {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        background-color: rgba(255,255,255, 0.5);
        display: block;
        transition: all 0.35s ease 0.0s;
    }

html.load-active .load-area .load-animation {
            margin: auto;
            width: 150px;
            height: 150px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 50px;
            left: 0;
        }

/* opacityだけだと、アニメーションが正常に動作しない場合がある */
/* 一瞬、サイトの中身が表示されてしまうのを防ぐため */
html.load-active:not(.load-fadeout) body {
        background-color: #fff;
    }
html.load-fadeout .load-area {
        opacity: 0;
    }
html:not(.load-active) body {
        background-color: #fbf8a0;
    }

/*Loadingアイコン
-------------------------------------------------------------*/

@-webkit-keyframes ball-grid-beat {
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
@keyframes ball-grid-beat {
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
.ball-grid-beat {
    width: 57px;
    height: 57px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}
.ball-grid-beat>div:nth-child(1) {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
    -webkit-animation-duration: 1.45s;
    animation-duration: 1.45s;
}
.ball-grid-beat>div:nth-child(2) {
    -webkit-animation-delay: -0.02s;
    animation-delay: -0.02s;
    -webkit-animation-duration: 0.97s;
    animation-duration: 0.97s;
}
.ball-grid-beat>div:nth-child(3) {
    -webkit-animation-delay: 0.66s;
    animation-delay: 0.66s;
    -webkit-animation-duration: 1.23s;
    animation-duration: 1.23s;
}
.ball-grid-beat>div:nth-child(4) {
    -webkit-animation-delay: 0.64s;
    animation-delay: 0.64s;
    -webkit-animation-duration: 1.24s;
    animation-duration: 1.24s;
}
.ball-grid-beat>div:nth-child(5) {
    -webkit-animation-delay: -0.19s;
    animation-delay: -0.19s;
    -webkit-animation-duration: 1.13s;
    animation-duration: 1.13s;
}
.ball-grid-beat>div:nth-child(6) {
    -webkit-animation-delay: 0.69s;
    animation-delay: 0.69s;
    -webkit-animation-duration: 1.42s;
    animation-duration: 1.42s;
}
.ball-grid-beat>div:nth-child(7) {
    -webkit-animation-delay: 0.58s;
    animation-delay: 0.58s;
    -webkit-animation-duration: 1.14s;
    animation-duration: 1.14s;
}
.ball-grid-beat>div:nth-child(8) {
    -webkit-animation-delay: 0.21s;
    animation-delay: 0.21s;
    -webkit-animation-duration: 1.17s;
    animation-duration: 1.17s;
}
.ball-grid-beat>div:nth-child(9) {
    -webkit-animation-delay: -0.18s;
    animation-delay: -0.18s;
    -webkit-animation-duration: 0.65s;
    animation-duration: 0.65s;
}
.ball-grid-beat>div {
    background-color: #03a9f4;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    float: left;
    -webkit-animation-name: ball-grid-beat;
    animation-name: ball-grid-beat;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0;
}
@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

.post-group > a:not(:last-child) {
        margin-bottom: 2em;
    }

.post-group > a {
        color: #222;
        display: block;
    }

.post-group > a > *:not(:last-child) {
            margin-bottom: 10px;
        }

.post-group > a .thumbnail {
            width: 120px;
            height: 120px;

        }

.post-group > a .content > * {
                display: block;
            }

.post-group > a .content > *:not(:last-child) {
                margin-bottom: 0.5em;
            }

.post-group > a .content .date-area {
                display: flex;
                align-items: baseline;
            }

.post-group > a .content .date-area > *:not(:last-child) {
                    margin-right: 0.5em;
                }

.post-group > a .content .title {

            }

.post-group > a .content .text {

            }

@media only screen and (min-width: 480px) {

.post-group > a {
            display: flex;
            align-items: flex-start
    }

            .post-group > a > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 10px;
            }

            .post-group > a .thumbnail {
                width: 150px;
                min-height: 150px;
                flex: 0 0 150px;
            }
            .post-group > a .content {
                flex: 1 1 auto;
            }
        }

@media only screen and (min-width: 660px) {
            .post-group > a > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 15px;
            }

            .post-group > a .thumbnail {
                width: 200px;
                flex: 0 0 200px;
            }
            .post-group > a .content {

            }
        }

@media only screen and (min-width: 770px) {

        }

@media only screen and (min-width: 1000px) {

        }

.post-group > a:hover {
        background: #000000;
        background: rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.05);
    }

.post-area .post-group,
    .post-area .pagination {
        margin-bottom: 40px;
    }

@media only screen and (min-width: 480px) {

        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {

.post-area .post-group,
    .post-area .pagination {
            margin-bottom: 60px
    }
        }

@media only screen and (min-width: 1000px) {

        }

.post-area .post-group:first-child, .post-area .pagination:first-child {
            margin-top: 0;
        }

.post-area .post-group:last-child, .post-area .pagination:last-child {
            margin-bottom: 0;
        }

.post-nav .title {
        margin-bottom: 10px;
        display: block;
    }
.post-nav-area {
    padding: 40px 0;
}
@media only screen and (min-width: 770px) {
.post-nav-area {
        padding: 60px 0
}
    }
.post-nav-area .post-nav:not(.last-child) {
        margin-bottom: 10px;
    }


article .article-header {
        margin-bottom: 40px;
    }


article .article-header .title {

            font-size: 24px;
        }


article .article-header .data-area:not(:last-child) {
            margin-bottom: 40px;
        }


article .article-header .date {
            margin-bottom: 1em;
            display: block;
        }


article .article-header .excerpt-area > * {
                flex: 1 1 0%;
            }


article .article-header .excerpt-area .img img {
                    width: 100%;
                }


@media only screen and (min-width: 480px) {


article .article-header {
            margin-bottom: 40px
    }

            article .article-header .data-area:not(:last-child) {
                margin-bottom: 60px;
            }
                article .article-header .excerpt-area .img {
                    margin: 0 auto;
                    max-width: 80%;
                }
        }


@media only screen and (min-width: 660px) {
            article .article-header .data-area {
                display: flex;
                align-items: center;
            }

                article .article-header .data-area .date {
                    margin-bottom: 0;
                }
                article .article-header .data-area .category-list {
                    margin-left: 20px;
                    padding-left: 20px;
                    border-left: 1px solid #222;
                }
        }


@media only screen and (min-width: 770px) {


article .article-header {
            margin-bottom: 30px
    }

            article .article-header .title {
                font-size: 34px;
            }

            article .article-header .data-area:not(:last-child) {
                margin-bottom: 80px;
            }
                article .article-header .excerpt-area .img {
                    max-width: 60%;
                }
        }


article .article-content img {
            display: block;
        }

.article-content p {
    line-height: 1.7;
}
.article-content p + p {
    margin-top: 20px;
}

.article-nav-area {
    padding-bottom: 60px;
    background: #ffffff;
}

.article-nav-area .article-nav {
        display: flex;
        justify-content: space-around;
    }

.article-nav-area .article-nav .btn:not(:last-child) {
            margin-right: 20px;
        }

@media only screen and (min-width: 480px) {

.article-nav-area {
        padding-bottom: 80px
}
    }

@media only screen and (min-width: 770px) {

.article-nav-area {
        padding-bottom: 100px
}
    }

@media only screen and (min-width: 1000px) {

.article-nav-area {
        padding-bottom: 140px
}
    }

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

body.contact .form-footer, body.confirm .form-footer, body.complete .form-footer {
        margin-top: 40px;
    }

@media only screen and (min-width: 1000px) {

body.contact .form-footer, body.confirm .form-footer, body.complete .form-footer {
            margin-top: 80px
    }
        }

body.contact .form-footer .btn-group, body.confirm .form-footer .btn-group, body.complete .form-footer .btn-group {
            justify-content: center;
        }

@media only screen and (min-width: 480px) {

body.home {
            max-height: 450px
    }
        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {

body.home {
            max-height: 550px
    }
        }

@media only screen and (min-width: 1000px) {

body.home {
            max-height: 100vh
    }
        }

/* IEのための記述 */

body.home > .mbYTP_wrapper {
            left: 0;
            right: 0;
        }

body.home > .mbYTP_wrapper .playerBox {
                /* initialは、IE11で使用不可 */
                /* max-width: initial; */
                max-width: none;
            }

body.home .mainvisual-content {
            text-align: center;
            position: relative;
            z-index: 1000;
        }

body.home .mainvisual-content .title {
                margin-top: 0;
                font-size: 18px;
                color: #fff;
            }

body.home .mainvisual-content .title span {
                    display: block;

                }

body.home .mainvisual-content .subtitle {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid #fff;
                font-size: 14px;
                color: #fff;
                display: inline-block;
            }

body.home .btn-scroll-down {
            color: #fff;
            display: none;
            position: absolute;
            bottom: 50px;
            z-index: 1000;


        }

body.home .btn-scroll-down .text {
                margin-bottom: 14px;
                display: block;

                /* IE対応のための記述 */
                text-align: center;
            }

body.home .btn-scroll-down .arrow {
                margin: 0 auto 18px auto;
                width: 2px;
                height: 18px;
                background: #fff;
                display: block;
                position: relative;
            }

body.home .btn-scroll-down .arrow::before {
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: #fff;
                    -webkit-transform: rotate(-45deg) translate(0, 1px);
                            transform: rotate(-45deg) translate(0, 1px);
                    -webkit-transform-origin: 50% 100%;
                            transform-origin: 50% 100%;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }

body.home .btn-scroll-down .arrow::after {
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: #fff;
                    -webkit-transform: rotate(45deg) translate(0, 1px);
                            transform: rotate(45deg) translate(0, 1px);
                    -webkit-transform-origin: 50% 100%;
                            transform-origin: 50% 100%;
                    display: block;
                    position: absolute;
                    bottom: 0;
                }

body.home .btn-scroll-down .logo {
                margin: 0 auto;
                width: 45px;
                height: 60px;
                background-image: url(../img/common/logo-mark-hiragana-white.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                display: block;
            }

@media only screen and (min-width: 480px) {
                body.home .mainvisual-content .title {
                    font-size: 24px;
                }
                        body.home .mainvisual-content .title span span {
                            display: inline-block;
                        }
                body.home .mainvisual-content .subtitle {
                    font-size: 16px;
                }
        }

@media only screen and (min-width: 660px) {

        }

@media only screen and (min-width: 770px) {
            body.home .btn-scroll-down {
                display: block;
            }
        }

@media only screen and (min-width: 1000px) {
                body.home .mainvisual-content .title {
                    font-size: 44px;
                }
                body.home .mainvisual-content .subtitle {
                    font-size: 24px;
                }
        }

    /* アニメーション (スマホ以外のとき) */
    .mainvisual:not(.mainvisual-sp) .subtitle {
            transition: opacity 0.35s ease-out 3.0s,
                        -webkit-transform 0.35s ease-out 3.0s;
            transition: opacity 0.35s ease-out 3.0s,
                        transform 0.35s ease-out 3.0s;
            transition: opacity 0.35s ease-out 3.0s,
                        transform 0.35s ease-out 3.0s,
                        -webkit-transform 0.35s ease-out 3.0s;
        }
    .mainvisual:not(.mainvisual-sp) .title > span:nth-child(1) {
                transition: opacity 0.35s ease-out 3.3s,
                            -webkit-transform 0.35s ease-out 3.3s;
                transition: opacity 0.35s ease-out 3.3s,
                            transform 0.35s ease-out 3.3s;
                transition: opacity 0.35s ease-out 3.3s,
                            transform 0.35s ease-out 3.3s,
                            -webkit-transform 0.35s ease-out 3.3s;
            }
    .mainvisual:not(.mainvisual-sp) .title > span:nth-child(2) {
                transition: opacity 0.35s ease-out 3.6s,
                            -webkit-transform 0.35s ease-out 3.6s;
                transition: opacity 0.35s ease-out 3.6s,
                            transform 0.35s ease-out 3.6s;
                transition: opacity 0.35s ease-out 3.6s,
                            transform 0.35s ease-out 3.6s,
                            -webkit-transform 0.35s ease-out 3.6s;
            }
    .mainvisual:not(.mainvisual-sp) .btn-scroll-down {
            transition: opacity 0.35s ease-out 4.0s,
                        -webkit-transform 0.35s ease-out 4.0s;
            transition: opacity 0.35s ease-out 4.0s,
                        transform 0.35s ease-out 4.0s;
            transition: opacity 0.35s ease-out 4.0s,
                        transform 0.35s ease-out 4.0s,
                        -webkit-transform 0.35s ease-out 4.0s;
        }
    .mainvisual:not(.mainvisual-sp) .btn-scroll-down .text,
            .mainvisual:not(.mainvisual-sp) .btn-scroll-down .arrow {
                transition: opacity 0.5s ease-out 5.5s;
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .subtitle {
                opacity: 0;
                -webkit-transform: translate3d(0, 30px, 0);
                        transform: translate3d(0, 30px, 0);
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .title > span:nth-child(1),
                html.load-active .mainvisual:not(.mainvisual-sp) .title > span:nth-child(2) {
                    opacity: 0;
                    -webkit-transform: translate3d(0, 30px, 0);
                            transform: translate3d(0, 30px, 0);
                }
    html.load-active .mainvisual:not(.mainvisual-sp) .btn-scroll-down {
                opacity: 0;
                -webkit-transform: translate3d(0, 30px, 0);
                        transform: translate3d(0, 30px, 0);
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .btn-scroll-down .text,
                html.load-active .mainvisual:not(.mainvisual-sp) .btn-scroll-down .arrow {
                    opacity: 0;
                }
    html:not(.load-active) .mainvisual:not(.mainvisual-sp) {

        }
    /* アニメーション (スマホのとき) */
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp {
        background-image: url(../img/home/home-mainvisual-bg-sp.jpg);
    }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp::before {
            background-color: #00223e;
            background-color: rgba(0, 34, 62, 0.2);
            opacity: 0.5;
        }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp::after {
            /* opacity: 0.35; */
        }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .subtitle {
            transition: opacity 0.35s ease-out 0.4s,
                        -webkit-transform 0.35s ease-out 0.4s;
            transition: opacity 0.35s ease-out 0.4s,
                        transform 0.35s ease-out 0.4s;
            transition: opacity 0.35s ease-out 0.4s,
                        transform 0.35s ease-out 0.4s,
                        -webkit-transform 0.35s ease-out 0.4s;
        }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(1) {
                transition: opacity 0.35s ease-out 0.6s,
                            -webkit-transform 0.35s ease-out 0.6s;
                transition: opacity 0.35s ease-out 0.6s,
                            transform 0.35s ease-out 0.6s;
                transition: opacity 0.35s ease-out 0.6s,
                            transform 0.35s ease-out 0.6s,
                            -webkit-transform 0.35s ease-out 0.6s;
            }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(2) > span:nth-child(1) {
                transition: opacity 0.35s ease-out 0.8s,
                            -webkit-transform 0.35s ease-out 0.8s;
                transition: opacity 0.35s ease-out 0.8s,
                            transform 0.35s ease-out 0.8s;
                transition: opacity 0.35s ease-out 0.8s,
                            transform 0.35s ease-out 0.8s,
                            -webkit-transform 0.35s ease-out 0.8s;
            }
    .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(2) > span:nth-child(2) {
                transition: opacity 0.35s ease-out 1.0s,
                            -webkit-transform 0.35s ease-out 1.0s;
                transition: opacity 0.35s ease-out 1.0s,
                            transform 0.35s ease-out 1.0s;
                transition: opacity 0.35s ease-out 1.0s,
                            transform 0.35s ease-out 1.0s,
                            -webkit-transform 0.35s ease-out 1.0s;
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .subtitle {
                opacity: 0;
                -webkit-transform: translate3d(0, 30px, 0);
                        transform: translate3d(0, 30px, 0);
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(1),
                html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(2) > span:nth-child(1),
                html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .title > span:nth-child(2) > span:nth-child(2) {
                    opacity: 0;
                    -webkit-transform: translate3d(0, 30px, 0);
                            transform: translate3d(0, 30px, 0);
                }
    html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .btn-scroll-down {
                opacity: 0;
                -webkit-transform: translate3d(0, 30px, 0);
                        transform: translate3d(0, 30px, 0);
            }
    html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .btn-scroll-down .text,
                html.load-active .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp .btn-scroll-down .arrow {
                    opacity: 0;
                }
    html:not(.load-active) .mainvisual:not(.mainvisual-sp) .mainvisual.mainvisual-sp {

        }
    .mainvisual:not(.mainvisual-sp) .mainvisual:not(.mainvisual-sp) {

    }
    .mainvisual:not(.mainvisual-sp) .home-service-area .img {
            position: relative;
            z-index: 1000;
        }
    .mainvisual:not(.mainvisual-sp) .home-service-area .content {
            padding: 30px;
            /* max-width: 60%; */
            box-sizing: border-box;
            position: relative;
        }
    .mainvisual:not(.mainvisual-sp) .home-service-area .content .logo {
                margin-left: -15px;
                width: 200px;
                display: block;
            }
    .mainvisual:not(.mainvisual-sp) .home-service-area .content .btn {
                margin-top: 40px;
                display: inline-block;
            }
    @media only screen and (min-width: 480px) {
            .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) {
                display: block;
            }

                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) > :not(:last-child) {
                    margin-bottom: 30px;
                    margin-right: 0;
                }
            .mainvisual:not(.mainvisual-sp) .home-service-area .img {
                /* margin-right: -20px; */
            }
        }
    @media only screen and (min-width: 660px) {
            .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) {
                display: flex;
            }

                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) > :not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 30px;
                }
        }
    @media only screen and (min-width: 770px) {
                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .img {
                    margin-right: -20px;
                    flex: 1 1 50%;
                }
                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .content {
                    margin-top: 50px;
                    margin-left: -20px;
                    padding: 40px 60px;
                    flex: 1 1 50%;
                }

                    .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .content .btn {
                        margin-top: 40px;
                        display: inline-block;
                    }

        }
    @media only screen and (min-width: 1000px) {
                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .img {
                    margin-right: -40px;
                }

                .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .content {
                    margin-left: -40px;
                    padding: 50px 100px;
                }

                    .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .content .logo {
                        margin-left: -25px;
                        width: 300px;
                    }

                    .mainvisual:not(.mainvisual-sp) .home-service-area .layout:not(.layout-reverse) .content .btn {
                        margin-top: 40px;
                        display: inline-block;
                    }
        }
    @media only screen and (min-width: 1300px) {
            .mainvisual:not(.mainvisual-sp) .home-service-area .content {
                padding: 70px 130px;
            }

                .mainvisual:not(.mainvisual-sp) .home-service-area .content .btn {
                    margin-top: 80px;
                    display: inline-block;
                }
        }

.mainvisual .btn-scroll-down .arrow {
            -webkit-animation: mainvisual-btn-scroll-arrow 2s linear 0.0s infinite;
                    animation: mainvisual-btn-scroll-arrow 2s linear 0.0s infinite;
        }

@-webkit-keyframes mainvisual-btn-scroll-arrow {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    20% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px);
    }
    30% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px);
    }
    90% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}

@keyframes mainvisual-btn-scroll-arrow {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    20% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px);
    }
    30% {
        -webkit-transform: translate(0, 10px);
                transform: translate(0, 10px);
    }
    90% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}

/*アーティスト詳細・会場詳細共通
----------------------------------------------------*/
@media only screen and (min-width: 660px) {
        .article-content table {
            margin-bottom: 0;
        }
                .article-content table td .postal-code {
                    line-height: 1;
                }
                .article-content table td .address {
                    line-height: 1.5;
                }
    }
.article-content .article-header {
        padding-bottom: 15px;
        background-image: url(../img/article/dot-line-blue.png);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 10px 4px;
        border: none !important;
        font-size: 12px;
    }
@media only screen and (min-width: 1000px) {
.article-content .article-header {
            display: flex
    }

            .article-content .article-header .text {
                display: flex;
                align-items: flex-end;
                line-height: 2;
                margin-left: 20px;
            }
        }
.article-content input.blog-parts {
        border: 1px solid #c4c4c4;
        padding: 6px;
        font-size: 12px;
    }
/*アーティスト詳細
    ----------------------------------------------------*/
.article-content .artist-commentary img {
            width: 190px;
            margin: 0 auto 20px;
        }
@media only screen and (min-width: 1000px) {
.article-content .artist-commentary img {
                width: 200px;
                height: 200px;
                float: left;
                margin-right: 20px;
                margin-bottom: 60px
        }
            }
@media only screen and (min-width: 1000px) {
.article-content .artist-commentary {
            margin-top: 30px

    }

            .article-content .artist-commentary p {
                line-height: 1.7;
            }
            .article-content .artist-commentary p + p {
                margin-top: 20px;
            }
        }

