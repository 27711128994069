/* UIのカスタマイズ */
/*----------------------------------------------------------------------------------*/
.bx-wrapper .bx-loading {
    background: url(../img/common/loading.png) center center no-repeat;
}
.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
    background: none;
    display: block;
    position: relative;
}
.bx-wrapper .bx-prev::before,
.bx-wrapper .bx-next::before {
    content: "\e200";
    margin: auto;
    width: 1em;
    height: 1em;
    font-size: 1rem;
    font-family: 'icon','icon-sns' !important;
    color: #fff;
    text-indent: 0;
    background: none;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
}
.bx-wrapper .bx-prev::before {
    transform: rotate(-90deg);
}
.bx-wrapper .bx-next::before {
    transform: rotate(90deg);
}
/*
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
*/

/* .bxsliderを読み込むまでは表示しない */
/*----------------------------------------------------------------------------------*/
.bxslider {
    list-style: none;
    display: block;

    & > li {
        list-style: none;
        display: block;
    }
}
.bx-wrapper .bxslider {
    display: block;
}

/* 余白や装飾の初期化 */
/*----------------------------------------------------------------------------------*/
.bxslider,
.bx-wrapper .bx-viewport {
    li {
        margin: 0;
    }
}
.bx-wrapper {
    // initialはIEでは使用不可
    // border: none;
    // box-shadow: none;
    // background: none;
}
.bxslider *:not(.thumbnail) > img {
    min-width: 100%;
    min-height: 100%;
}

/* PCで画面を触ると都合が悪いので、マスクを表示 */
/*----------------------------------------------------------------------------------*/

/* マスク */
.bx-wrapper::before {
    z-index: 2000;
}
.bx-viewport {
    z-index: 1000;
}
.bx-controls {
    z-index: 8000;
    position: relative;
}

@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {
    /* マスク */
    .bx-wrapper::before {
        content: "";
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
@media only screen and (min-width: 1000px) {

}
@media only screen and (min-width: 1300px) {

}
