.single {
    article {
        section {
            margin-top: 20px;
            position: relative;

            .update-information {
                top: -65px;
                left: 0;
                right: 0;

                @media only screen and (min-width: 1000px) {
                    width: auto;
                    position: absolute;
                    display: flex;
                    top: -75px;
                    left: 0px;
                    right: 0;
                    height: 30px;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    border-radius: 6px 6px 0 0;

                }

                + p {
                    padding-bottom: 10px;
                    border-bottom:1px solid $color-gray;
                    margin-bottom: 20px;

                    @media only screen and (min-width: 1000px) {
                        padding-bottom: 20px;
                    }
                }

            }

            p {
                font-size: 12px;
                line-height: 1.7;

                @media only screen and (min-width: 1000px) {
                    margin-top: 0;
                }

                &.update_information {
                    color: #e91e63;
                }

                + p {
                    margin-top: 20px;
                }
            }

            .event-title {
                display: flex;

                .title {
                    -webkit-flex: 1;
                    flex: 1;
                }
            }

            .event-introduction {
                margin-bottom: 40px;

                @media only screen and (min-width: 1000px) {
                    margin-bottom: 50px;
                }
            }

            .event-map {
                margin-bottom: 40px;

                @media only screen and (min-width: 1000px) {
                    margin-bottom: 50px;
                }
            }

        }
    }
}