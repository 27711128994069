@media only screen and (min-width: 480px) {

}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {

}

.post-group {
    & > a:not(:last-child) {
        margin-bottom: 2em;
    }
    & > a {
        color: $color-text;
        display: block;

        & > *:not(:last-child) {
            margin-bottom: 10px;
        }
        .thumbnail {
            width: 120px;
            height: 120px;

        }
        .content {
            & > * {
                display: block;
            }
            & > *:not(:last-child) {
                margin-bottom: 0.5em;
            }
            .date-area {
                display: flex;
                align-items: baseline;

                & > *:not(:last-child) {
                    margin-right: 0.5em;
                }
            }
            .title {

            }
            .text {

            }
        }

        @media only screen and (min-width: 480px) {
            display: flex;
            align-items: flex-start;

            & > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 10px;
            }

            .thumbnail {
                width: 150px;
                min-height: 150px;
                flex: 0 0 150px;
            }
            .content {
                flex: 1 1 auto;
            }
        }
        @media only screen and (min-width: 660px) {
            & > *:not(:last-child) {
                margin-bottom: 0;
                margin-right: 15px;
            }

            .thumbnail {
                width: 200px;
                flex: 0 0 200px;
            }
            .content {

            }
        }
        @media only screen and (min-width: 770px) {

        }
        @media only screen and (min-width: 1000px) {

        }
    }
    & > a:hover {
        background: rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.05);
    }
}

.post-area {
    .post-group,
    .pagination {
        margin-bottom: 40px;

        @media only screen and (min-width: 480px) {

        }
        @media only screen and (min-width: 660px) {

        }
        @media only screen and (min-width: 770px) {
            margin-bottom: 60px;
        }
        @media only screen and (min-width: 1000px) {

        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

}

.post-nav {
    .title {
        margin-bottom: 10px;
        display: block;
    }
}
.post-nav-area {
    padding: 40px 0;

    @media only screen and (min-width: 770px) {
        padding: 60px 0;
    }

    .post-nav:not(.last-child) {
        margin-bottom: 10px;
    }
}


article {

    .article-header {
        margin-bottom: 40px;

        .title {

            font-size: 24px;
        }
        .data-area:not(:last-child) {
            margin-bottom: 40px;
        }
        .date {
            margin-bottom: 1em;
            display: block;
        }

        .excerpt-area {

            & > * {
                flex: 1 1 0%;
            }

            .img {
                img {
                    width: 100%;
                }
            }
        }

        @media only screen and (min-width: 480px) {
            margin-bottom: 40px;

            .data-area:not(:last-child) {
                margin-bottom: 60px;
            }

            .excerpt-area {
                .img {
                    margin: 0 auto;
                    max-width: 80%;
                }
            }
        }
        @media only screen and (min-width: 660px) {
            .data-area {
                display: flex;
                align-items: center;

                .date {
                    margin-bottom: 0;
                }
                .category-list {
                    margin-left: 20px;
                    padding-left: 20px;
                    border-left: 1px solid $color-text;
                }
            }
        }
        @media only screen and (min-width: 770px) {
            margin-bottom: 30px;

            .title {
                font-size: 34px;
            }

            .data-area:not(:last-child) {
                margin-bottom: 80px;
            }

            .excerpt-area {
                .img {
                    max-width: 60%;
                }
            }
        }
    }

    .article-content {

        img {
            display: block;
        }
    }
}

.article-content p {
    line-height: 1.7;
}
.article-content p + p {
    margin-top: 20px;
}

.article-nav-area {
    padding-bottom: 60px;
    background: #ffffff;

    .article-nav {
        display: flex;
        justify-content: space-around;

        .btn:not(:last-child) {
            margin-right: 20px;
        }
    }

    @media only screen and (min-width: 480px) {
        padding-bottom: 80px;
    }

    @media only screen and (min-width: 770px) {
        padding-bottom: 100px;
    }

    @media only screen and (min-width: 1000px) {
        padding-bottom: 140px;
    }
}
