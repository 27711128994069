@charset "UTF-8";

/* ページャー */
/*---------------------------------------------------*/
/*
.pager li {
    padding: 0 0.5em;
}
.pager a,
.pager a:hover,
.pager span {
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #111111;
}
.pager .current {
    background: #dddddd;
}
.pager a:hover {
    background: #111111;
    color: #ffffff;
}
*/



/* toggle / tab / popup */
/*---------------------------------------------------*/
/*
.toggle-button,
.parent-tab-button a,
.popup-button a {
    // padding: 15px 20px;
    // border: 1px solid #333333;
    font-weight: normal;
}
.toggle-button,
.popup-button a {
    background-color: transparent;
    color: #333333;
}
.toggle-button.active,
.toggle-button:hover,
.parent-tab-button li a,
.popup-button a:hover {
    // background-color: #333333;
    // color: #ffffff;
    color: #333333;
}
.parent-tab-button li a:hover {
    opacity: 0.7;
}
.toggle-button.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.toggle-content,
.parent-tab-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.parent-tab-button li a {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}
.parent-tab-button li:first-child a {
    border-top-left-radius: 3px;
    border-left-width: 1px;
}
.parent-tab-button li:last-child a {
    border-top-right-radius: 3px;
    border-right-width: 1px;
}
.parent-tab-content {
    border: 1px solid #333333;
    border-top: none;
}
.parent-tab-button li.current a {
    color: #333333;
    background-color: transparent;
}
.parent-popup-content .container {
    // width: 770px;
}
.popup-content > .panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
}
.popup-content {
    opacity: 0.0;
}
.popup-content.active {
    opacity: 1.0;
}
.popup-content {
    transition: opacity 0.25s ease-in-out 0.4s;
}
.popup-button-close {
    width: 30px;
    height: 30px;
    background: url(../img/icon-close-big.png) no-repeat center center;
    background-size: 30px 30px;
    top: 10px;
    right: 10px;
}
.popup-button-close:hover {
    opacity: 0.35;
}
*/




/* thumbnailボタン */
/*---------------------------------------------------*/
a .thumbnail img {
    transform: scale(1.0);
}
a:hover .thumbnail img {
    transform: scale(1.1);
}
/*
@media only screen and (min-width: 480px) {
    // input[type="submit"],
    // input[type="button"],
    // input[type="reset"],
    // button,
    // .button a {
    //     width: auto
    // }
}

@media only screen and (min-width: 770px) {
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="number"],
    input[type="file"],
    input[type="search"],
    select,
    body select[size="1"],
    select[multiple],
    option,
    textarea {
        padding: 20px;
        // border: 2px solid #eeeeee;
    }

    select[size],
    select[multiple],
    select:not([size]) {
        padding: 20px;
    }

    // input[type="button"],
    // input[type="reset"],
    // input[type="submit"],
    // button,
    // .button > a,
    // .button > span:not(.icon),
    // a.button {
    //     padding: 0.5em 1em;
    //     font-size: 16px;
    // }
}
*/


/* pagerボタン */
/*---------------------------------------------------*/
.pager ul,
.pager ol,
ul.pager ,
ol.pager {
    margin: 0 auto;
    padding: 0;
    display: table;
    border-collapse: separate;
    border-spacing: 1px 0;
}
.pager li,
.pager li {
    display: table-cell;
}
.pager li a,
.pager li span {
/*
    display: table-cell;
    text-align: center;
    vertical-align: middle;
*/
}
.pager li a,
.pager li span {
    min-width: 2em;
    min-height: 2em;
    line-height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pager .first a,
.pager .prev a,
.pager .next a,
.pager .last a {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px 15px;
}
