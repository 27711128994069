ul,
ol {
    line-height: $line-height-text;
    list-style-position: outside;

    ul,
    ol {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

    .title + & {
        margin-top: 0;
    }
}

li {

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    & > p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
}

ul {
    & > li {
        //list-style-type: disc;
    }
    ul {
        & > li {
            list-style-type: circle;
        }
    }
    &.sns-btn-list {
        display: flex;
        justify-content: center;
        li:not(:first-child) {
            margin-left :10px;
        }
    }
    &.related-event-list {
        margin: -10px;
        li {
            margin: 10px;
            font-size: 10px;
            a {
                height: 100%;
                color: $color-text;
                display: flex;
                position: relative;
                // 日付を下に揃えるための余白
                padding-bottom: calc(1em + 10px);
                box-sizing: border-box;
                .thumbnail {
                    flex: 0 0 130px;
                    width: 130px;
                    height: 130px;
                    margin-right: 10px;
                }
                .detail {
                    flex: 1 1 auto;
                    .event-term {
                        position: absolute;
                        bottom: 20px;
                    }
                }
                &:hover {
                    color: $color-link-hover;
                }
            }
        }

        @media only screen and (min-width: 770px) {
            display: flex;
            li {
                width: 130px;
                a {
                    display: block;
                    .thumbnail {
                        margin-bottom: 15px;
                        width: auto;
                        height: 135px;
                        margin-right: 0;
                    }
                    .detail {
                        .event-term {
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }


    &.image-list {
        margin-bottom: 20px;
        display: flex;
        margin-left: -10px;

        li {
            width: 20%;
            margin-left: 10px;

            a {
                background-color: #eee;
                width: 100%;
                height: 100px;
                overflow: hidden;
            }
        }
    }

    @media only screen and (min-width: 1000px) {
        ul {

            & > li {
                list-style-type: circle;
            }

            &.related-event-list {

                display: flex;
                li {
                    font-size: 10px;
                }

                li:not(:first-child) {
                    margin-left: 20px;
                }
            }

            &.image-list {

                li {

                    a {
                        width: 106px;
                        height: 106px;
                    }
                }
            }
        }
    }
}
ol {
    & > li {
        list-style-type: decimal;
    }

    ol {
        padding-left: 0;

        & > li {
            // list-style-type: decimal;
            list-style-type: none;
            counter-increment: cnt;
            display: flex;

            &::before {
                display: marker;
                content: "(" counter(cnt) ") ";
                margin-right: 0.5em;
            }
        }
    }

    &.ol-01 {
        margin-top: 10px;
        & > li {
            list-style-type: none;
            text-indent: -1em;
            padding-left: 1em;
        }
    }
}

/* 注釈リスト */
/*----------------------------------------------------------------------------*/
.list-note {
    list-style-position: outside;

    & > li {
        list-style-type: none;
    }
    & > li::before {
        display: marker;
        content: "※";
        margin-left: -1.5em;
        margin-right: 0.5em;
        width: 1em;
    }
}

.slash-list {
    margin-top: -5px;
    li {
        display: inline-block;
        margin-top: 5px;

        &::before {
            content: "/";
            display: inline-block;
            margin: 0 10px;
        }

        &:first-child::before {
            content: "";
            margin: 0;
        }
    }
}


.hiragana-list {
    margin-bottom: 40px;
    background-color: #faf6e9;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;

    ul {
        flex-basis: calc(100% / 2);
        display: flex;
        align-items: center;
        margin-top: 20px;

        li {
            font-size: 16px;
            margin-right: 10px;

            &:first-child {
                font-size: 20px;
                font-weight: bold;
            }

            a {
                color: $color-text;

                &:hover {
                    color: $color-text-other-link;
                }
            }
        }
    }

    @media only screen and (min-width: 770px) {
        ul {
            flex-basis: calc(100% / 3);
        }
    }

    @media only screen and (min-width: 1000px) {
        padding: 20px 40px 40px;
        margin-bottom: 40px;
    }
}



.municipality-list {
    background-color: #faf6e9;
    padding: 20px;
    margin-bottom: 40px;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: -15px;

        li {
            flex-basis: calc(100% / 2);
            font-size: 14px;
            font-weight: bold;
            margin-top: 15px;
            display: flex;
            align-items: center;

            &::before {
                @mixin icon;
                @mixin icon-desc;
                color: $color-text-other-link;
                font-size: 14px;
                margin-right: 5px;
            }

            a {
                color: $color-text;

                &:hover {
                    color: $color-text-other-link;
                }
            }
        }
    }

    @media only screen and (min-width: 770px) {
        ul {
            li {
                flex-basis: calc(100% / 4);
                font-size: 18px;
            }
        }
    }

    @media only screen and (min-width: 1000px) {
        padding: 40px;
        margin-bottom: 40px;
    }
}
