html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    background-repeat: no-repeat;
    border-radius: 0;
}
/*
body {
    color: #333333;
    letter-spacing: 0.075em;
}
*/
main {
    display: block;
}
/*
input,
textarea,
select:not([size]),
option {
    padding: 0.75em 1.0em;
}
td,
th {
    padding: 0.25em 1.0em;
}
ul,
ol {
    padding-left: 1.5em;
    list-style-position: outside;
}
ul li { list-style-type: disc; }
ol li { list-style-type: decimal }
input,
textarea,
select,
td,
th {
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}
select,
option,
textarea,
input,
button {
    font-size: 100%;
    background-repeat: no-repeat;
    border-radius: 0;
}
select,
option,
input,
button {
    line-height: 1;
}
*/
select,
option,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="date"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
/*
a {
    text-decoration: none;
}
td,
optgroup,
address {
    font-weight: normal;
}
a,
select,
input[type="checkbox"],
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="reset"],
button {
    cursor: pointer;
}
textarea {
    vertical-align: top;
    overflow: auto;
    resize: vertical;
}
*/
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input[type="search"] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
input[type="search"]:focus {
    outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
