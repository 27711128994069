h2, h3, h4, h5, h6 {
    font-weight: bold;
    line-height: $line-height-title;
    margin-top: 2em;
}

h2 {
    font-size: 16px;
    @media only screen and (min-width: 1000px) {
        font-size: 24px;
    }
    &.h2-01 {
        margin-bottom: 20px;
        color: $color-main;
        text-align: center;

        @media only screen and (min-width: 1000px) {
            margin-bottom: 40px;
            color: $color-main;
            text-align: center;
        }
    }

    &.h2-02 {

    }

    &.h2-03 {
        margin: 0;
        line-height: 1.3;
        margin-bottom: 10px;
    }

    &.h2-04 {
        background-color: $color-main;
        font-size: 20px;
        margin-bottom: 0;
        padding: 10px 0;
        color: $color-base;
        text-align: center;
        &:before {
            display: none;
        }
    }
}


h3 {
    @media only screen and (min-width: 1000px) {
        font-size: 20px;
    }

    &.h3-01 {
        font-size: 16px;
        margin-bottom:  20px;
        padding: 8px 0 8px 14px;
        position: relative;
        line-height: 1;
        vertical-align: middle;

        @media only screen and (min-width: 1000px) {
            font-size: 20px;
        }

        &:before {
            position: absolute;
            content: "";
            background: #81d4fa;
            left: 0;
            top: 0;
            width: 6px;
            height: 30px;
            border-radius: 2px;
            @media only screen and (min-width: 1000px) {
                height: 36px;
            }
        }
    }
}


h4 {
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.4;
    
    @media only screen and (min-width: 1000px) {
        font-size: 12px;
        margin-top: 10px;
    }
}


h5 { font-size: 16px; }

h6 { font-size: 16px; }

.title {
    font-family: $font-gothic;
    font-weight: bold;
    font-size: 14px;

    span {
        display: inline-block;
    }
}